import React, { forwardRef, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import AnalysisForm from "./AnalysisForm";
import useModal from "../../hooks/useModal";
import Card from "../Card";
import Spinner from "../Spinner";
import AnalysisContext from "../../state/AnalysisContext";

const SaveAsForm = ({ onSave, onCancel }) => {
  const { id } = useParams();
  const { getAnalysisAsCopy, updateCharts } = useContext(AnalysisContext);

  const updatedAnalysis = getAnalysisAsCopy(Number(id));

  if (updatedAnalysis) {
    delete updatedAnalysis.name;
    delete updatedAnalysis.id;
    delete updatedAnalysis.created;
    delete updatedAnalysis.updated;
  }

  return (
    <Card>
      <AnalysisForm
        analysis={updatedAnalysis}
        site_id={updatedAnalysis.site}
        creating={true}
        onSave={(val) => {
          onSave && onSave();
          updateCharts(undefined);
        }}
        onCancel={onCancel}
        disableAddAnother
      ></AnalysisForm>
    </Card>
  );
};

const SaveAsButton = forwardRef(({ onSave, className, ...rest }, ref) => {
  const [Modal, toggleModal] = useModal();
  const [loading, setLoading] = useState(false);

  if (ref) {
    ref.current = { loading, setLoading };
  }

  return (
    <>
      <button
        ref={ref}
        className={`btn btn-blue flex justify-center items-center ${className}`}
        onClick={toggleModal}
      >
        {loading ? (
          <Spinner size={24} />
        ) : (
          <>
            <FormattedMessage id="analysis.save_as" />
          </>
        )}
      </button>
      <Modal onRequestClose={() => setLoading(false)}>
        <SaveAsForm onCancel={toggleModal} onSave={toggleModal} {...rest} />
      </Modal>
    </>
  );
});

export default SaveAsButton;
