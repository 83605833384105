import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../state/AuthContext";
import { FormattedMessage } from "react-intl";
import Loader from "./Loader";

export default function SingleSignOnView() {
  const { loginSSO } = useContext(AuthContext);
  const history = useHistory();
  const [error, setError] = useState();

  const ssoErrorHandler = (err) => {
    if (err && err.status === 403) {
      setError({ message: "login.failure.sso_invalid" });
      return;
    } else {
      setError({ message: "login.failure.unknown" });
    }
  };

  useEffect(() => {
    let query = window.location.search;
    let ssoToken = new URLSearchParams(query).get("token");
    loginSSO(ssoToken)
      .then(() => history.push("/"))
      .catch(ssoErrorHandler);
  }, [setError, loginSSO, history]);

  return error ? (
    <div
      className="text-red-700 border-red-400 bg-red-100 border 
      rounded p-6 text-md text-center w-1/3 m-auto"
    >
      <FormattedMessage id={error.message} values={{ br: <br /> }} />
      <button
        onClick={() => {
          history.push("/login");
        }}
        className="btn btn-blue mx-auto mt-4 mb-2"
      >
        <FormattedMessage id={"sso.button.signin"} />
      </button>
    </div>
  ) : (
    <div className="flex-col h-full w-full justify-center items-center m-auto">
      <Loader messageId="sso.loading.login" />
    </div>
  );
}
