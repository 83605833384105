import { useEffect, useRef } from "react";

// A hook to execute callback function every "delay" ms, that gets 
// initiated when component gets mounted, and cleaned up on dismount.
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Setup and save the lastest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Setup the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}
