import React, { useState, useEffect, useRef } from "react";
import Spinner from "./Spinner";


const SubmitButton = ({ children, onClick,  ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(true);  // Initialize a ref to track the mounted state

    // Cleanup the ref when the component unmounts
    useEffect(() => {
        return () => {
            isMounted.current = false;  // Set to false when the component unmounts
        };
    }, []);

    const handleClick = async (e) => {
        if (onClick) {
            setIsLoading(true);
        await onClick(e);
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleClick}
            disabled={isLoading}
            {...props}
        >
            {isLoading ? <Spinner/> : children}
        </button>
        );
    };

export default SubmitButton;