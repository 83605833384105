import React from "react";
import { useHistory } from "react-router-dom";
import { DeviceContext } from "../state/DeviceContext";
import { DetailsContext, DetailsView } from "./DetailsView";
import DeviceForm from "./DeviceForm";
import { useIntl } from "react-intl";

const DeviceDetails = ({ site, linkTo, ...rest }) => {
  const history = useHistory();
  const intl = useIntl();

  return (
    <DetailsView
      param={rest.type === "echo" ? "echo_id" : "base_id"}
      context={DeviceContext}
      getTitle={(creating) =>
        creating ? `${intl.formatMessage({id: "device_form_title.add"})} ${rest.type}` : `${rest.type} ${intl.formatMessage({id: "device_form_title.properties"})}`
      }
      linkTo={linkTo}
      splitView={true}
    >
      <DetailsContext.Consumer>
        {({ entity, creating, linkTo }) => (
          <DeviceForm
            device={entity}
            onSave={creating ? () => history.push(linkTo) : null}
            {...rest}
          />
        )}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

export default DeviceDetails;
