import React, { useContext, useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import sitePlaceholder from "../assets/site-placeholder.jpg";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import Popup, { PopupSection } from "./Popup";
import { AuthContext } from "../state/AuthContext";
import { buildRequest } from "../api";
import { IoLanguageOutline } from "react-icons/io5";

const UserMenu = () => {
  const { authenticatedFetch } = useContext(AuthContext);
  const { currentUser } = useContext(UserContext);
  const { memoizedLastSites } = useContext(SiteContext);
  const [updatedLastSites, updateLastSites] = useState(memoizedLastSites);

  // Get images for each site
  useEffect(() => {
    const qs = JSON.stringify({ with_image: true });
    memoizedLastSites &&
      Promise.all(
        memoizedLastSites?.map(
          async (site) =>
            await authenticatedFetch(
              buildRequest("GET")
                .withPath(`/sites/${site.id}/`)
                .withQuery(JSON.parse(qs))
            ).then((resp) => resp)
        )
      ).then(updateLastSites);
  }, [memoizedLastSites, authenticatedFetch]);

  return currentUser ? (
    <div className="flex items-center whitespace-no-wrap">
      <Popup
        className="w-64 right-0"
        trigger={
          <button className="ml-4 font-medium text-white focus:outline-none focus:underline">
            <FiMenu size={24} />
          </button>
        }
      >
        <h1 className="py-3 px-4 text-black font-medium ">
          {currentUser?.first_name + " " + currentUser?.last_name}
        </h1>
        <hr />
        <PopupSection>
          <Link to="/profile">
            <FormattedMessage id="view.title.profile" />
            <div className="ml-2">
              <IoLanguageOutline />
            </div>
          </Link>
          {currentUser.is_admin && (
            <Link to="/users">
              <FormattedMessage id="view.title.users" />
            </Link>
          )}
          <Link to="/help">
            <FormattedMessage id="menu.help" />
          </Link>
          <Link to="/privacy">
            <FormattedMessage id="view.title.privacy" />
          </Link>
        </PopupSection>
        <hr className="mb-2" />
        <PopupSection titleId="sites.title">
          {updatedLastSites
            ? updatedLastSites
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ id, name, image }) => (
                  <Link key={id} to={`/sites/${id}/`}>
                    <div className="h-8 w-8 overflow-hidden rounded">
                      <img
                        className="h-8"
                        src={image ? image : sitePlaceholder}
                        alt={name}
                      />
                    </div>
                    <p
                      style={{
                        maxWidth: "12rem",
                        textOverflow: "ellipsis",
                      }}
                      className="pl-2 overflow-hidden"
                    >
                      {name}
                    </p>
                  </Link>
                ))
                .concat(
                  <div key="actions" className={"flex px-4 justify-evenly"}>
                    <Link
                      className="btn block flex items-center hover:border-scout-blue"
                      to="/sites/all"
                    >
                      <FormattedMessage id="menu.all_sites" />
                    </Link>
                    {!currentUser.read_only && (
                      <Link
                        className="btn block flex items-center hover:border-scout-blue"
                        to="/sites/addSite"
                      >
                        <FormattedMessage id="menu.add_site" />
                      </Link>
                    )}
                  </div>
                )
            : null}
        </PopupSection>

        <hr className="mb-2" />
        <PopupSection>
          <Link className="text-scout-blue font-medium" to="/logout">
            <FormattedMessage id="action.logout" />
          </Link>
        </PopupSection>
      </Popup>
    </div>
  ) : null;
};

export default UserMenu;
