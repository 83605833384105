import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { FiClock } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { PlainSelect } from "./FormWidgets";
import Popup, { PopupContext } from "./Popup";
import Spinner from "./Spinner";
import { DEFAULT_DURATION } from "./analysis-studio/AnalysisView";

// TODO: Use ISO 8601 Duration Format?
// Use days as integers for now
const opts = () => [
  {
    value: 1,
    label: "duration.day",
  },
  {
    value: 7,
    label: "duration.week",
  },
  {
    value: 31,
    label: "duration.month",
  },
  {
    value: 90,
    label: "duration.three_months",
  },
  {
    value: 183,
    label: "duration.six_months",
  },
  {
    value: 365,
    label: "duration.year",
  },
  {
    value: 730,
    label: "duration.two_years",
  },
];

export const NiceDuration = ({ duration }) => {
  const label = isNice(duration);
  return (
    <span className="ml-2 hidden sm:inline-flex">
      {label ? <FormattedMessage id={label} /> : <></>}
    </span>
  );
};

const isNice = (durationValue) =>
  opts().find(({ value }) => {
    return value === durationValue;
  })?.label ?? false;

const DurationForm = ({ defaultValues, onSubmit }) => {
  const formRef = useRef();
  const { hide } = useContext(PopupContext);
  const { handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: defaultValues ? defaultValues : DEFAULT_DURATION,
  });
  const options = opts();
  const current = watch("duration");

  const transform = (data, evt) => {
    if (evt) {
      evt.stopPropagation();
    }
    hide();
    setTimeout(async () => onSubmit({ duration: data.value }), 250);
  };

  const defString = JSON.stringify(defaultValues);

  useEffect(() => {
    reset(JSON.parse(defString));
  }, [defString, reset]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(transform)}>
      <label htmlFor="dts-preset" className="font-medium">
        <FormattedMessage id="date.presets" />
      </label>
      <div className="w-full relative">
        <PlainSelect
          id="dts-preset"
          value={isNice(current)}
          onChange={(evt) => {
            const val = evt.target.value;
            const selectedOption = options.find(({ label }) => label === val);
            if (val !== "") {
              setValue(
                "duration",
                options.find(({ label }) => label === val).value
              );
            }
            transform(selectedOption);
          }}
        >
          {options.map((option, idx) => (
            <FormattedMessage key={option.label} id={option.label}>
              {(message) => <option value={option.label}>{message}</option>}
            </FormattedMessage>
          ))}
        </PlainSelect>
      </div>
    </form>
  );
};

const DurationSelector = forwardRef(({ duration, onSubmit }, ref) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const [loading, setLoading] = useState(false);

  if (ref) {
    ref.current = { loading, setLoading };
  }

  return (
    <Popup
      className="px-4 py-6 w-64"
      trigger={
        <button
          className="btn btn-blue flex justify-center items-center"
          onClick={visible ? hide : show}
        >
          {loading ? (
            <Spinner size={24} />
          ) : (
            <>
              <FiClock className="stroke-2 text-white" size={18} />
              <NiceDuration duration={duration} />
            </>
          )}
        </button>
      }
    >
      <DurationForm
        {...(duration ? { defaultValues: { duration } } : {})}
        onSubmit={onSubmit}
      />
    </Popup>
  );
});

export default DurationSelector;
