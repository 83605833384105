import React, { useCallback, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import useResource from "../hooks/useResource";
import { AuthContext } from "./AuthContext";
import schemaFields from "./schema";
import { LanguageContext } from '../localization/LocaleProvider';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [[{ entities: users, loading }], crud] = useResource("users");
  const { decodedToken } = useContext(AuthContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const intl = useIntl();

  const user = decodedToken
    ? users?.find(({ id }) => id === decodedToken.user_id)
    : undefined;
  const currentUser = user
    ? {
        // Custom fields here
        ...user,
        is_admin: decodedToken.is_admin,
      }
    : undefined;

  useEffect(() => {
    if (currentUser?.language && selectedLanguage !== currentUser.language ) {
      setSelectedLanguage(currentUser.language);
      localStorage.setItem('language', currentUser.language);
    }
  }, [currentUser?.language, selectedLanguage, setSelectedLanguage]);

  const userUnits = Object.assign(
    {},
    ...["si", "usc"].map((unit) => ({
      [unit]: intl.formatMessage({ id: `units.${unit}` }),
    }))
  );

  const validationSchema = useCallback(
    (user) =>
      yup.object().shape({
        username: schemaFields.uniqueString(users, user, "username"),
        first_name: yup
          .string()
          .required("validation.required")
          .meta({ label: "First name" }),
        last_name: yup
          .string()
          .required("validation.required")
          .meta({ label: "Last name" }),
        email: yup
          .string()
          .email("validation.email")
          .required("validation.required")
          .meta({ label: "E-mail", type: "email" }),
        pref_graph_smoothing: yup.boolean(),
        pref_unit_length: yup.string().oneOf(["metric", "imperial"]),
        pref_unit_temp: yup.string().oneOf(["celsius", "fahrenheit"]),
        password: schemaFields.nullableString.required(
          "validation.password_required"
        ),
        password_dacapo: schemaFields.nullableString
          .oneOf([yup.ref("password"), "Passwords don't match"])
          .required("validation.password_confirmation_required"),
      }),
    [users]
  );

  return (
    <UserContext.Provider
      value={{
        crud,
        users,
        entities: users,
        validationSchema,
        currentUser,
        userUnits,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
