import React, { useContext } from "react";
import { UserContext } from "../../state/UserContext";
import { DeviceContext } from "../../state/DeviceContext";
import LineGraphCard from "./LineGraphCard";
import { generateRandomId } from "../../utility/common";

export default function AnalysisContent({
  analysis,
  duration,
  durationPickerRef,
}) {
  const { currentUser } = useContext(UserContext);
  const { getMeasurementsDownsampled } = useContext(DeviceContext);

  const lineGraphCharts = analysis?.charts.filter(
    (entity) => entity.type === "line-graph"
  );

  const commonProps = {
    currentUser,
    getMeasurementsDownsampled,
    durationPickerRef,
    timeSpan: duration,
  };

  const generatedId = lineGraphCharts && generateRandomId();

  return (
    <>
      {lineGraphCharts.length > 0 ? (
        lineGraphCharts?.map((entity) => (
          <LineGraphCard
            key={entity.id}
            charts={entity}
            analysis={analysis}
            {...commonProps}
          />
        ))
      ) : (
        <LineGraphCard
          analysis={analysis}
          key={generatedId}
          charts={{
            id: generatedId,
            type: "line-graph",
            sources: [],
          }}
          {...commonProps}
        />
      )}
    </>
  );
}
