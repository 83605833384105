import React, { useState } from "react";
import { format } from "date-fns";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import Card from "./Card";
import LocaleProvider from "../localization/LocaleProvider";
import { Input, ValidationMessage } from "./FormWidgets";
import { useForm } from "react-hook-form";

const dateFormat = "yyyy-MM-dd";
const f = (date) => format(date, dateFormat);

// If both start and end is given, verify that end is larger than start
const isValidTimeRange = (start, end) => {
  if (start && end) {
    return start <= end;
  }
  return true;
};

const SoilTypeDialog = ({ resolve, soilType, deviceId, callback }) => {
  const { register, getValues, watch } = useForm();
  const [error, setError] = useState(null);

  const start = watch("from");
  const end = watch("to");
  const now = new Date();

  function onSubmit(event) {
    event.preventDefault();

    if (!isValidTimeRange(start, end)) {
      return setError({ messageId: "date.range.failure" });
    }

    const values = getValues();
    // Construct the body with non-empty fields
    let body = {
      ...(values.from?.length > 0 ? { start_date: values.from } : {}),
      ...(values.to?.length > 0 ? { end_date: values.to } : {}),
      soil_type: soilType,
    };

    // API Callback passed through the form
    callback(deviceId, body)
      .then((_) => {
        // Clear errors
        setError(null);

        // Resolve the promise if the callback was successful
        resolve({ ok: true });
        removeDialog();
      })
      .catch((_) => {
        // Our callback API request failed
        setError({ messageId: "error.server_communication" });
      });
  }

  return (
    <Card
      className="max-w-lg m-auto"
      title={<FormattedMessage id="soil_type.recalculation_title" />}
    >
      <form onSubmit={onSubmit}>
        <p className="font-medium">
          <FormattedMessage id="soil_type.recalculation_description" />
        </p>
        <br />
        <span class="italic mt-6">
          <FormattedMessage id="dialog.date_selector.option_empty" />
        </span>
        <div className="mt-6">
          <label htmlFor="st-start" id="from">
            <FormattedMessage id="date.from" />
          </label>
          <Input
            type="date"
            id="st-start"
            name="from"
            ref={register()}
            min={"2010-01-01"}
            max={end}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="st-end">
            <FormattedMessage id="date.to" />
          </label>
          <Input
            type="date"
            id="st-end"
            name="to"
            ref={register()}
            min={start}
            max={f(now)}
          />
        </div>
        {error && <ValidationMessage messageId={error?.messageId} />}
        <div className="my-4 text-yellow-600">
          <FormattedMessage id="action.recalculating_moisture_history" />
        </div>
        <div className="flex mt-10">
          <button
            className="ml-2 btn btn-blue w-1/2 shadow-md"
            onClick={() => {
              resolve({ ok: false });
              removeDialog();
            }}
          >
            <FormattedMessage id="dialog.button_dont_recalculate" />
          </button>
          <button
            className="ml-2 btn btn-red w-1/2 shadow-md"
            autoFocus
            onClick={(event) => onSubmit(event)}
          >
            <FormattedMessage id="dialog.button_recalculate" />
          </button>
        </div>
      </form>
    </Card>
  );
};

function addDialog(resolve, deviceId, soilType, callback) {
  // Get the main element from the DOM
  const main = document.getElementsByTagName("main")[0];

  // Create a new div element that holds our dialog
  const div = document.createElement("div");

  // Set id of the div and append it to the main
  div.setAttribute("id", "soiltype-dialog");
  div.classList.add(
    "absolute",
    "min-w-full",
    "min-h-full",
    "flex",
    "justify-items-center"
  );

  // Append the div to the main and render our widget within it
  main.appendChild(div);
  ReactDOM.render(
    <LocaleProvider>
      <SoilTypeDialog
        resolve={resolve}
        soilType={soilType}
        deviceId={deviceId}
        callback={callback}
      />
    </LocaleProvider>,
    div
  );
}

function removeDialog() {
  // Remove the dialog from the DOM
  const div = document.getElementById("soiltype-dialog");
  const main = document.getElementsByTagName("main")[0];
  main.removeChild(div);
}

export async function getSoilTypeConfirmation(deviceId, soilType, callback) {
  return new Promise((resolve) => {
    addDialog(resolve, deviceId, soilType, callback);
  });
}
