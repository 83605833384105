import React from "react";
import { IconShield } from "./Icons";
import { FormattedMessage } from "react-intl";

export default function Loader({ messageId }) {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="flex-col text-md">
        <IconShield className="animate-pulse h-20 w-20 text-gray-600" />
        {messageId ? <FormattedMessage id={messageId} /> : <></>}
      </div>
    </div>
  );
}
