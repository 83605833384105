import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { UserContext } from "../state/UserContext";
import Card from "./Card";
import DashContent from "./DashContent";
import DashHeader, { IconLink, TitleContainer } from "./DashHeader";
import { SectionHeading } from "./Headings";
import { IconLeft } from "./Icons";
import ProfileForm from "./ProfileForm";

export default function ProfileView() {
  const { currentUser } = useContext(UserContext);

  return (
    <>
      <DashHeader span>
        <TitleContainer>
          <IconLink icon={<IconLeft />} />
          <SectionHeading>
            <FormattedMessage id="view.title.profile" />
          </SectionHeading>
        </TitleContainer>
      </DashHeader>
      <DashContent span>
        <Card className="max-w-2xl my-8">
          <ProfileForm user={currentUser} />
        </Card>
      </DashContent>
    </>
  );
}
