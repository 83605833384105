import React from "react";
import { useIntl } from "react-intl";

export default function CellSignal({ className, status }) {
  const intl = useIntl();
  return (
    <span
      className={className}
      title={intl.formatMessage({ id: "receiver.tooltip.signal_strength" })}
    >
      {status && status.connection_type
        ? `${status.connection_type}, ${
            status.signal_strength ? status.signal_strength : "?"
          } dBm`
        : intl.formatMessage({ id: "receiver.tooltip.signal_strength_unavailable" })}
    </span>
  );
}
