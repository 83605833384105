import classNames from "classnames";
import React, { forwardRef, useState} from "react";

const DashContent = forwardRef(
  (
    {
      className,
      dodge,
      solid = false,
      visible = true,
      children,
      splitView = false,
      span,
      noScroll,
      shadow,
      wFull,
    },
    ref
  ) => 
    { 
      //Scroll position is used at the Dashboard component to rescroll the content to the previous position after zoom or reaload
      const [scrollYPosition, setScrollYPosition] = useState(0); 
      return(
        <div
          ref={ref}
          key={"content"}
          className={classNames(
            className,
            "flex flex-col items-center",
            { "bg-white": solid, "bg-transparent": !solid },
            { "inline-flex": visible, "hidden lg:inline-flex": !visible },
            { "hidden lg:inline-flex": dodge, "border-b": !dodge },
            { "pb-2 px-2 md:px-4 lg:items-start": splitView },
            { "col-span-2": span },
            { "md:overflow-y-auto": !noScroll },
            { "w-full": wFull},
            { shadow: shadow }
          )}
          onScroll={(e) => {
            setScrollYPosition(e.target.scrollTop);
          }}
        >
          {React.cloneElement(children, { scrollYPosition, dashContentRef: ref })}
        </div>
      )
  }
);

export default DashContent;
