import React from "react";
import { useHistory } from "react-router-dom";
import DeviceGroupContext from "../state/DeviceGroupContext";
import { DetailsContext, DetailsView } from "./DetailsView";
import { useIntl } from "react-intl";
import GroupForm from "./GroupForm";

const GroupDetails = ({ site_id, linkTo }) => {
  const history = useHistory();
  const intl = useIntl();
  
  return (
    <DetailsView
      param="group_id"
      context={DeviceGroupContext}
      getTitle={(creating) => (creating ?  intl.formatMessage({id: "group_form_title.add_group"}) :  intl.formatMessage({id: "group_form_title.group_properties"}))}
      linkTo={linkTo}
    >
      <DetailsContext.Consumer>
        {({ entity: group, creating }) => (
          <GroupForm
            group={group}
            site_id={site_id}
            onSave={() => creating && history.push(linkTo)}
          />
        )}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

export default GroupDetails;
