import classNames from "classnames";
import React from "react";

export const HeadingContainer = ({ children, className }) => (
  <div className={classNames(className, "px-4 sm:px-8 h-16 sm:h-20 lg:h-20")}>
    {children}
  </div>
);

export const SiteHeading = ({ className, children }) => (
  <h1
    style={{
      textOverflow: "ellipsis",
    }}
    className={classNames(
      className,
      "text-xl font-medium select-none whitespace-no-wrap overflow-hidden"
    )}
  >
    {children}
  </h1>
);

export const SectionHeading = ({ className, children }) => (
  <h2
    style={{
      textOverflow: "ellipsis",
    }}
    className={classNames(
      className,
      "text-lg sm:text-xl font-bold whitespace-no-wrap overflow-hidden capitalize"
    )}
  >
    {children}
  </h2>
);

export const SubsectionHeading = ({ children }) => (
  <h3 className="text-lg sm:text-xl font-bold">{children}</h3>
);
