import {
  addDays,
  addMilliseconds,
  parseISO,
  differenceInMilliseconds,
} from "date-fns";

export const deepCopy = (iterable) => {
  return JSON.parse(JSON.stringify(iterable));
};

export const shuffle = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export const hasMeasurements = (meas) => {
  // eslint-disable-next-line
  for (var i in meas) {
    if (Object.keys(meas[i]).length > 0) {
      return true;
    }
  }
  return false;
};

/**
 * Takes ISO timestamps and returns difference in seconds
 * @param {ISO Timestamp} start
 * @param {ISO Timestamp} end
 */
export const subISODatesToDifferenceInMilliseconds = (start, end) => {
  const s = new Date(start);
  const e = new Date(end);

  const diff = differenceInMilliseconds(e, s);
  return diff;
};

export const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;

export const generateRandomId = () => {
  const ALPHABET = "0123456789abcde";
  const ID_LENGTH = 6;
  let rtn = "";
  for (var i = 0; i < ID_LENGTH; i++) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }
  return rtn;
};

export const getQuerySet = (
  lodDomain,
  scoutStartDate,
  windowSize,
  timeSpan,
  aggregate
) => {
  const until = addDays(new Date(scoutStartDate), timeSpan);
  const epoch = new Date(0);
  let sinceDiff;
  let untilDiff;

  if (lodDomain) {
    sinceDiff = subISODatesToDifferenceInMilliseconds(
      epoch.toDateString(),
      lodDomain[0]
    );
    untilDiff = subISODatesToDifferenceInMilliseconds(
      epoch.toDateString(),
      lodDomain[1]
    );
  }

  const result = lodDomain
    ? {
        since: addMilliseconds(
          parseISO(scoutStartDate),
          sinceDiff
        ).toISOString(),
        until: addMilliseconds(
          parseISO(scoutStartDate),
          untilDiff
        ).toISOString(),
        window_size: windowSize,
      }
    : {
        since: scoutStartDate,
        until: until.toISOString(),
        window_size: windowSize,
      };

  if (aggregate) {
    result.aggregate_all = true;
  }

  return result;
};

export const isNumber = (value) => {
  return typeof value === "number" && isFinite(value);
};

// Checks if the obj really is an object and that said object
// is not an instance of the Array type
const isObjectNotArray = (obj) =>
  obj !== null &&
  obj !== undefined &&
  typeof obj === "object" &&
  !(obj instanceof Array);

function _mergeValues(val1, val2) {
  if (isObjectNotArray(val1) && isObjectNotArray(val2)) {
    return mergeObjects(val1, val2);
  }
  return val2;
}

// Merges the values of obj2 into obj1
export function mergeObjects(obj1, obj2) {
  var result = { ...obj1 };
  for (let key in obj2) {
    let value = obj2[key];
    result[key] = key in result ? _mergeValues(result[key], value) : value;
  }
  return result;
}

// A function that calculates the stepsize for graph of non-negative values
//
// Give the minimum stepsize and a max measurement (optional tick count) calculates
// a stepsize that is a factor of the minimum size in relations to maxMeasurement
export function getStepSize(
  minimumStepSize,
  maxMeasurement,
  count = 5,
  decimalAccuracy = 10
) {
  // Return undefined if we do not have a minimumStepSize to base calculations on
  if (!Number.isFinite(minimumStepSize) || minimumStepSize === 0)
    return undefined;

  // Return stepsize if we have no maxMeasurement to base calculations on
  if (!Number.isFinite(maxMeasurement)) return minimumStepSize;

  // Calculate the stepsize based on the count and round it to the decimalAccuracy
  let step =
    Math.round((maxMeasurement / count) * decimalAccuracy) / decimalAccuracy;

  // Based on the new stepsize, retrieve a factor of minimumStepSize that has
  // a product less than or equal to the newly found step.
  let stepFactor = Math.max(1, Math.floor(step / minimumStepSize));

  return stepFactor * minimumStepSize;
}

export const DEFAULT_TIMESPAN = 7;

// eslint-disable-next-line
export default {
  getQuerySet,
  deepCopy,
  shuffle,
  isNumber,
  hasMeasurements,
  generateRandomId,
  subISODatesToDifferenceInMilliseconds,
  mergeObjects,
  getStepSize,
};
