import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useRef,
  forwardRef,
} from "react";
import { FormattedMeasurement } from "../../localization";
import { FormattedMessage, useIntl } from "react-intl";
import ScoutList from "../ScoutList";
import { UserContext } from "../../state/UserContext";
import { DeviceContext } from "../../state/DeviceContext";
import { SiteContext } from "../../state/SiteContext";
import Table from "../Table";
import useModal from "../../hooks/useModal";
import DashHeader from "./../DashHeader";
import { PlainSelect } from "../FormWidgets";
import StartTimeSelector from "../StartTimeSelector";
import { ImCross } from "react-icons/im";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Spinner from "../Spinner";
import { useMediaQuery } from "react-responsive";
import config from "../../tailwindConfig";
import { ReactComponent as ScoutIcon } from "../../assets/icons/scout.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/tag.svg";

const dropdownData = [
  "moisture",
  "temperature",
  "conductivity",
  "salinity",
  "water_balance",
];

export const ColorBox = ({ color }) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className="h-4 w-4 rounded-sm"
    ></div>
  );
};

export const FormattedTableMeasurement = ({
  cell,
  diffType,
  selectedRow,
  isSelected,
}) => {
  const { currentUser } = useContext(UserContext);
  const isImperial = currentUser.pref_unit_temp === "fahrenheit";

  const intl = useIntl();

  const isTemperature = cell.data[cell.row.index]?.data_type === "temperature";
  const isMoisture =
    cell.data[cell.row.index]?.data_type === "moisture" ||
    cell.data[cell.row.index]?.data_type === "water_balance";

  const diff =
    isNaN(cell.value) || isNaN(cell.data[selectedRow][diffType])
      ? undefined
      : cell.value - cell.data[selectedRow][diffType]

  const formattedDiff =
    cell.data[selectedRow]?.data_type ===
      cell.data[cell.row.index]?.data_type && !isNaN(diff) ? (
      diff >= 0 ? (
        <>
          <wbr />
          <span className="text-green-500">
            {" "}
            +
            {isTemperature ? (
              Math.round(diff * 100) / 100
            ) : (
              <FormattedMeasurement
                value={isMoisture ? diff / 100 : diff}
                variable={cell.data[cell.row.index].data_type}
              />
            )}{" "}
          </span>
        </>
      ) : (
        <>
          <wbr />
          <span className="text-red-500">
            {" "}
            {isTemperature ? (
              Math.round(diff * 100) / 100
            ) : (
              <FormattedMeasurement
                value={isMoisture ? diff / 100 : diff}
                variable={cell.data[cell.row.index].data_type}
              />
            )}{" "}
          </span>
        </>
      )
    ) : (
      ""
    );
  return (
    <>
      {isNaN(cell.value) ? (
        "-"
      ) : cell.value ? (
        <FormattedMeasurement
          noConversion={isImperial}
          value={isMoisture ? cell.value / 100 : cell.value}
          variable={cell.data[cell.row.index].data_type}
          withUnit
        />
      ) : (
        <FormattedMeasurement
          noConversion={isImperial}
          value={isMoisture ? cell.value / 100 : cell.value}
          variable={cell.data[cell.row.index].data_type}
        />
      )}
      {isSelected
        ? " " + intl.formatMessage({id: "analyses.table.reference"})
        : (cell.value &&
          !isNaN(cell.value) &&
          cell.data[selectedRow][diffType] &&
          cell.data[selectedRow][diffType] !== 0 &&
          selectedRow !== cell.row.index &&
          cell.data[cell.row.index]?.data_type ===
          cell.data[selectedRow]?.data_type &&
          formattedDiff) ||
        ""}
    </>
  );
};

export const HeaderSpinner = forwardRef(({ value, ...rest }, ref) => {
  const [loading, setLoading] = useState(false);
  if (ref) {
    ref.current = { loading, setLoading };
  }
  return loading ? <Spinner {...rest} size={24} /> : <>{value}</>;
});

export const StudioCardTable = ({
  sources,
  analysisKey,
  chartsId,
  tableActionHandlers,
  timeSpan,
  durationPickerRef,
  hintNo,
  showHints,
}) => {
  const [selectedRow, setSelectedRow] = useState(0);
  const underXL = useMediaQuery({
    query: `(max-width: ${config.theme.screens.xl})`,
  });

  const { scouts } = useContext(DeviceContext);
  const { currentSite } = useContext(SiteContext);
  const [Modal, toggleModal] = useModal();

  const intl = useIntl();

  const differenceFromSelected = useCallback(
    (cell, diffType, isSelected) => {
      const selected =
        selectedRow < cell.rows.length ? selectedRow : selectedRow - 1;
      return (
        <FormattedTableMeasurement
          cell={cell}
          diffType={diffType}
          selectedRow={selected}
          isSelected={isSelected}
        />
      );
    },
    [selectedRow]
  );

  const CompareDataGuideText = () => (
    <div className="my-8 text-center text-graph-gray">
      <FormattedMessage id={`analysis.data_compare_hint.${hintNo}`} />
    </div>
  );

  const toggleRow = useCallback(
    (object, isToggled, clear, index) => {
      setSelectedRow(index);
    },
    [setSelectedRow]
  );

  const [showIcon, setShowIcon] = useState(false);

  const datePickerRef = useRef();
  const columns = useMemo(
    () => [
      {
        Header: undefined,
        accessor: "color",
        className: `w-1/12 pr-4 sm:pl-2 px-2 h-12" ${underXL ? "hidden" : ""}`,
        Cell: (cell) => {
          return cell.value ? (
            <ColorBox color={cell.data[cell.row.index].color} />
          ) : (
            ""
          );
        },
      },
      {
        Header: intl.formatMessage({id: "analyses.header.name"}),
        accessor: "name",
        className: "w-2/12 pr-4 sm:pl-2 px-2 h-12",
        sortIndicator: "alpha",
        Cell: (cell) => {
          return <div className="truncate w-44 xl:w-52">{cell.value}</div>;
        },
      },
      {
        Header: intl.formatMessage({id: "analyses.header.data_type"}),
        accessor: "data_type",
        className: "w-2/12 pr-4 sm:pl-2 px-2 h-12",
        sortType: "basic",
        sortIndicator: "numeric",
        Cell: (cell) => {
          const index = cell.row.index;
          return cell.value ? (
            <div className="w-34">
              <PlainSelect
                value={cell.value}
                white
                simple
                pointer
                invisibleIcon
                iconVisibility={showIcon}
                className="text-scout-blue"
                onChange={(evt) => {
                  tableActionHandlers.updateMetricType(
                    sources,
                    analysisKey,
                    chartsId,
                    index,
                    evt.target.value
                  );
                }}
                onMouseEnter={() => setShowIcon(true)}
                onMouseLeave={() => setShowIcon(false)}
              >
                {dropdownData.map((entry) => (
                  <FormattedMessage key={entry} id={`measurement.${entry}`}>
                    {(message) => (
                      <option placeholder="Select metric type" value={entry}>
                        {message}
                      </option>
                    )}
                  </FormattedMessage>
                ))}
              </PlainSelect>
            </div>
          ) : (
            ""
          );
        },
      },
      {
        Header: intl.formatMessage({id: "analyses.header.start_date"}),
        accessor: "start_date",
        className: "w-2/12 pr-4 sm:pl-4 px-4 h-12",
        Cell: (cell) => {
          const index = cell.row.index;

          return cell.value ? (
            <div className="my-auto text-scout-blue justify-start -ml-6">
              <div className="my-auto align-center w-52 flex flex-row">
                <button
                  onClick={() => {
                    let x = new Date(cell.value);
                    timeSpan === 1
                      ? x.setHours(x.getHours() - 1)
                      : x.setDate(x.getDate() - 1);

                    tableActionHandlers.updateDate(
                      sources,
                      analysisKey,
                      chartsId,
                      index,
                      x
                    );
                  }}
                >
                  <FiChevronLeft className={"h-5 w-6 my-auto align-center"} />
                </button>

                <StartTimeSelector
                  displayValue={cell.value}
                  ref={datePickerRef}
                  startDate={new Date(cell.value)}
                  onSubmit={(val) => {
                    tableActionHandlers.updateDate(
                      sources,
                      analysisKey,
                      chartsId,
                      index,
                      val
                    );
                  }}
                />
                <button
                  onClick={() => {
                    let x = new Date(cell.value);
                    timeSpan === 1
                      ? x.setHours(x.getHours() + 1)
                      : x.setDate(x.getDate() + 1);

                    tableActionHandlers.updateDate(
                      sources,
                      analysisKey,
                      chartsId,
                      index,
                      x
                    );
                  }}
                >
                  <FiChevronRight className={"h-5 w-6 my-auto align-center"} />
                </button>
              </div>
            </div>
          ) : (
            ""
          );
        },
      },
      {
        Header: intl.formatMessage({id: "analyses.header.min"}),
        accessor: "min",
        className: `w-1/10 pr-4 sm:pl-2 px-2 h-12" ${underXL ? "hidden" : ""}`,
        sortIndicator: "basic",
        Cell: (cell) => {
          return differenceFromSelected(
            cell,
            "min",
            selectedRow === cell.row.index
          );
        },
      },
      {
        Header: intl.formatMessage({id: "analyses.header.max"}),
        accessor: "max",
        className: `w-1/10 pr-4 sm:pl-2 px-2 h-12" ${underXL ? "hidden" : ""}`,
        sortIndicator: "basic",
        Cell: (cell) => {
          return differenceFromSelected(
            cell,
            "max",
            selectedRow === cell.row.index
          );
        },
      },
      {
        Header: intl.formatMessage({id: "analyses.header.average"}),
        accessor: "avg",
        className: `w-1/10 pr-4 sm:pl-2 px-2 h-12" ${underXL ? "hidden" : ""}`,
        sortIndicator: "basic",
        Cell: (cell) => {
          return differenceFromSelected(
            cell,
            "avg",
            selectedRow === cell.row.index
          );
        },
      },
      {
        Header: "",
        accessor: "remove",
        className: "flex justify-end",
        Cell: (cell) => {
          return (
            <button
              tabIndex="-1"
              className="text-gray-500 my-auto align-center"
              onClick={() => {
                tableActionHandlers.remove(
                  sources,
                  analysisKey,
                  chartsId,
                  cell.row.id
                );
              }}
            >
              <ImCross size={10} />
            </button>
          );
        },
      },
    ],
    [
      sources,
      chartsId,
      analysisKey,
      tableActionHandlers,
      differenceFromSelected,
      timeSpan,
      showIcon,
      underXL,
      selectedRow,
      intl
    ]
  );

  const toggleRowSelected = useCallback(
    (object) => {
      tableActionHandlers.add(sources, analysisKey, chartsId, object);
    },
    [analysisKey, chartsId, sources, tableActionHandlers]
  );

  const tableControl = useMemo(
    () => ({
      toggleRowSelected,
    }),
    [toggleRowSelected]
  );

  const dataListColumn = useMemo(
    () => [
      {
        id: "group",
        className: "first:pl-8",
        accessor: "group",
        sortType: "basic",
        disableSortBy: true,
        Cell: (cell) =>
          cell.value ? (
            <GroupIcon className="fill-current text-scout-green-dark mx-auto" />
          ) : (
            <ScoutIcon className="fill-current text-scout-blue mx-auto" />
          ),
      },
      {
        Header: "Name",
        accessor: "name",
        className: "first:pl-8",
        sortIndicator: "alpha",
        Cell: (cell) => {
          const amount = sources.filter((source) => source.name === cell.value)
            .length;
          return (
            <div className="text-scout-blue font-medium">
              <span className={amount > 0 ? "" : "invisible"}>{amount}</span>{" "}
              <span className="pl-6">{cell.value}</span>
            </div>
          );
        },
      },
    ],
    [sources]
  );

  return (
    <div className="w-full bg-white h-auto z-50 overflow-auto">
      <Table
        data={sources}
        columns={columns}
        highlightIdx={selectedRow}
        controlled={{ toggleRowSelected: toggleRow }}
        toggleRow={true}
      />
      <div className="flex flex-row justify-center items-center px-4 py-6 ">
        <>
          <Modal className="modal-content bg-white rounded-md">
            <DashHeader>
              <div className="flex flex-row">
                <h2 className="text-lg font-bold whitespace-no-wrap overflow-hidden capitalize">
                  <FormattedMessage id="analysis.add_data_source" />
                </h2>
              </div>
              <div className="flex flex-row">
                <button
                  className="btn btn-blue"
                  onClick={() => toggleModal(true)}
                >
                  <HeaderSpinner
                    loaderNextToValue
                    value={""}
                    ref={durationPickerRef}
                    className="mr-4"
                  />
                  Done
                </button>
              </div>
            </DashHeader>
            {showHints && (
              <DashHeader>
                <CompareDataGuideText />
              </DashHeader>
            )}

            <div className="overflow-y-auto h-screen max-h-3/4-vh">
              <ScoutList
                scouts={scouts}
                siteID={currentSite.id}
                columns={dataListColumn}
                disableRefresh
                {...{
                  rowSelect: true,
                  noEditSelect: true,
                  noRowHeaders: true,
                  controlled: tableControl,
                }}
              />
            </div>
          </Modal>
          <button
            className="btn btn-blue flex justify-center items-center"
            onClick={() => toggleModal(true)}
          >
            <FormattedMessage id="analysis.add_data_source" />
          </button>
        </>
      </div>
    </div>
  );
};
