import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import gdpr from "../assets/gdpr.json";
import useModal, { ModalContext } from "../hooks/useModal";
import { UserContext } from "../state/UserContext";
import Card from "./Card";

export const latestGdpr = gdpr.sort((a, b) => b.version - a.version)[0];

const EulaConfirmation = ({ title, version, source, onAccept }) => {
  const { close } = useContext(ModalContext);

  return (
    <Card version={version}>
      <div id="eula-content" className="overflow-y-auto h-screen max-h-1/2-vh">
        <iframe
          className="terms-and-services-iframe w-full h-full"
          title={title}
          src={source}
        />
      </div>
      <div className="flex mt-10">
        <Link to="/logout" className="btn btn-red w-1/2">
          <FormattedMessage id="dialog.button_decline" />
        </Link>
        <button
          className="ml-2 btn btn-blue w-1/2 shadow-md"
          autoFocus
          onClick={() => {
            onAccept();
            close();
          }}
        >
          <FormattedMessage id="dialog.button_accept" />
        </button>
      </div>
    </Card>
  );
};

const Eula = () => {
  const { crud, currentUser } = useContext(UserContext);
  const [Modal] = useModal(true);

  const handleAccept = () => {
    let updatedUser = currentUser;
    updatedUser.accepted_eula = latestGdpr["version"];
    crud.update(updatedUser).catch((err) => console.log(err));
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      className="modal-content max-w-5xl"
    >
      <EulaConfirmation
        title={`${latestGdpr["title"]}`}
        source={latestGdpr["source"]}
        onAccept={handleAccept}
      />
    </Modal>
  );
};

export default Eula;
