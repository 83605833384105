import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const CoverCard = ({ children, cornerWidget, cover, linkTo }) => {
  return (
    <Link
      style={{
        // Workaround for overflow during transform using Safari on iOS
        // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
        WebkitMaskImage: "-webkit-radial-gradient(white, black)",
      }}
      className="group h-64 flex-1 block outline-none relative hover:bg-gray-200 sm:max-w-sm bg-white m-2 rounded-md shadow-xl overflow-hidden"
      to={linkTo}
    >
      <img
        className="object-cover w-full rounded-md transition-all duration-150 group-focus:zoom group-hover:zoom"
        src={cover}
        alt="Site card"
      />
      <div
        style={{
          background:
            "transparent linear-gradient(180deg, #20202000 0%, #20202000 48%, #202020 100%) 0% 0% no-repeat padding-box",
        }}
        className={classNames(
          "h-full w-full absolute bottom-0 p-4 flex flex-col items-start text-white group-focus:text-scout-blue-light",
          { "justify-between": cornerWidget },
          { "justify-end": !cornerWidget }
        )}
      >
        {cornerWidget}
        {children}
      </div>
    </Link>
  );
};

export default CoverCard;
