import { bar } from "billboard.js";
import * as fns from "date-fns";
import { en, fi, sv, fr, es, it } from 'date-fns/locale'

// Grid lines for Graph Y axis
export const generateGridLines = (min, max, stepSize) => {
  let step = Math.floor(min / stepSize) * stepSize;
  let lines = undefined;
  lines = Array(Math.floor((max - step) / stepSize) + 1)
    .fill()
    .map(() => ({
      value: (step += stepSize),
    }));
  return lines;
};

// Count the amount of metricTypes used in graph
export const getMetricCount = (dataSeries) => {
  let count = 0;
  for (var metric in dataSeries) {
    if (Object.entries(dataSeries[metric]).length > 0) count++;
  }
  return count;
};

// Remove color hex from legend
export const formatName = (title) => title.slice(0, -7);

// Return our epoch-based time back to original timestamp
export const formatEpochDifferenceToOriginal = (seriesTimestamp, startTime) => {
  const result = fns.addMilliseconds(
    new Date(startTime),
    new Date(seriesTimestamp).valueOf()
  );
  return result;
};

export const formatXAxisDuration = (x, domain, language) => {
  const duration = Math.abs(fns.differenceInDays(domain[0], domain[1]));
  var daysToCurrentX = Math.abs(fns.differenceInDays(domain[0], x));
  var hoursToCurrentX = Math.abs(fns.differenceInHours(domain[0], x));
  var monthDivider = 29

  // Generated x timestamps with 24h analyses start from
  // Thu Jan 01 1970 03:00:00 GMT+0200
  // For more equal dispersion of hour ticks
  if (duration === 1) {
    hoursToCurrentX -= 2
  }

  // Generated x timestamps with 3 month analyses start from
  // Sun Jan 04 1970 00:00:00 GMT+0200
  // For more equal dispersion of month ticks
  if (duration === 90) {
    daysToCurrentX += 3
  }

  // For more equal dispersion of month ticks
  if (duration === 730) {
    monthDivider = 30
  }

  const payload = {
    years: Math.floor(daysToCurrentX / 365),
    months: Math.floor(daysToCurrentX / monthDivider ) % 12,
    weeks: Math.floor(daysToCurrentX / 7 ) % 4,
    days: Math.floor(hoursToCurrentX / 24 ),
    hours: hoursToCurrentX,
  };
  const format = [];

  if (duration > 365) {
    format.push("years");
    format.push("months");
  } else if (duration > 100) {
    format.push("months");
  } else if (duration > 31) {
    format.push("months");
    format.push("weeks");
  } else if (duration >= 7) {
    format.push("days");
  } else {
    format.push("hours");
  }

  return fns.formatDuration(payload, { format, locale: getFnsLocale(language)});
};

export const hasYAxisType = (axes, type) => {
  if (Object.keys(axes).length > 0) {
    for (const k of Object.keys(axes)) {
      if (axes[k] === type) return true;
    }
  }
  return false;
};

export const hasY2 = (dataSeries) => {
  if (dataSeries && dataSeries.length > 0) {
    for (const series of dataSeries) {
      if (series.type === bar()) return true;
    }
  }
  return false;
};

export const hasYAxisMembers = (chart, dataSeries) => {
  const axes = chart.data.axes();
  const yAxisMembers = Object.keys(axes).filter((key) => axes[key] === "y");
  return dataSeries.find(({ name }) => yAxisMembers.includes(name))
    ? true
    : false;
};

export const filterTimeseries = (timeseries) =>
  timeseries.map((entry) =>
    Array.isArray(entry) ? new Date(entry[1]) : entry
  );

export const rainFormatterTooltip = (x) => `${x.toFixed(2)} mm`;

// eslint-disable-next-line
export default {
  generateGridLines,
  getMetricCount,
  formatName,
  formatEpochDifferenceToOriginal,
  hasYAxisMembers,
  hasY2,
  filterTimeseries,
  rainFormatterTooltip,
};

const getFnsLocale = (language) => {
  switch (language) {
    case "fi":
      return fi;
    case "sv":
      return sv;
    case "fr":
      return fr;
    case "it":
      return it;
    case "es":
      return es;
    default:
      return en;
  }
}