import { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { buildRequest } from "../api";
import { AuthContext } from "../state/AuthContext";
import ErrorContext from "../state/ErrorContext";

export default function useResource(resource, qs = {}) {
  const { authenticatedFetch } = useContext(AuthContext);
  const { addError, removeError } = useContext(ErrorContext);

  const [state, dispatch] = useReducer(
    (state, { type, loading, payload }) => ({
      loading: loading !== undefined ? loading : state.loading,
      entities:
        type === "add"
          ? state.entities.concat(payload)
          : type === "reset"
            ? payload
            : type === "update"
              ? state.entities.map((elem) =>
                elem.id === payload.id ? payload : elem
              )
              : type === "remove"
                ? state.entities.filter(({ id }) => id !== payload.id)
                : state.entities,
    }),
    {
      loading: false,
      entities: undefined,
    }
  );

  console.debug(`${resource}:`, state);

  /**
   * State variable indicating whether a saving operation is in progress.
   * @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}
   */
  const [isSaving, setIsSaving] = useState(false);

  // Loading using the browser cursor
  const load = useCallback((promise, action) => {
    dispatch({ loading: true });
    removeError();
    return promise
      .then(async (result) => {
        if (action) {
          dispatch({ ...(await action(result)), loading: false });
        }
        return result;
      })
      .catch((err) => {
        dispatch({ loading: false });
        addError(`load_data_error`, err);
        return Promise.reject(err);
      });
  }, [addError, removeError]);

  const loading = state.loading;

  useEffect(() => {
    document.body.style.cursor = loading || isSaving ? "wait" : "default";
    return () => (document.body.style.cursor = "default");
  }, [loading, isSaving]);

  // CRUD actions
  const qsString = JSON.stringify(qs);
  
  /**
   * Asynchronously handles and controls saving actions.
   * @param {Function} saveAction - The save action to be executed.
  */
  const handleSave = async (saveAction) => {
    if (isSaving) {
      return; // Do nothing if already saving
      }

      setIsSaving(true);
      
      try {
        await saveAction();
      } finally {
        setIsSaving(false);
    }
  };
  
  // TODO: Investigate if a toast is always raised. If so, parameterize it.
  const list = useCallback(
    () =>
      load(
        authenticatedFetch(
          buildRequest("GET")
            .withPath(`/${resource}/`)
            .withQuery(JSON.parse(qsString))
        ),
        (payload) => ({
          type: "reset",
          payload,
        })
      ),
    [load, resource, authenticatedFetch, qsString]
  );

  const create = (obj) => handleSave(() =>
    load(
      authenticatedFetch(
        buildRequest("POST").withPath(`/${resource}/`).withBody(obj)
      ).then(list)
    )
  );

  // const update = (obj) => handleSave(() =>
  // handleSave() was causing problems with missing resp. Used original code as it works here and saves time.
  const update = (obj) =>
    load(
      authenticatedFetch(
        buildRequest("PATCH")
          .withPath(window.sessionStorage.getItem("recalculateMeasurements") === 'true' ?
            `/${resource}/${obj.id}/?skipwaterbalancerecalc` :  // recalculateMeasurements === true
            `/${resource}/${obj.id}/`)              // recalculateMeasurements === false | undefined | null
          .withBody(obj)
      ).then(list)
      
    );



  const remove = (obj) => handleSave(() =>
    load(
      authenticatedFetch(
        buildRequest("DELETE").withPath(`/${resource}/${obj.id}/`)
      ).then(list)
    )
  );

  // Initial load
  // TODO: Investigate if this is sensible to leave as such.
  useEffect(() => {
    list();
  }, [list]);

  return [[state, dispatch], { create, list, update, remove }];
}
