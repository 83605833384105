import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React, {
  cloneElement,
  createContext,
  useContext,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

export const PopupContext = createContext();

export const PopupSection = ({ title, titleId, children }) => {
  const { hide } = useContext(PopupContext);
  return (
    <>
      <p className="px-4 text-center font-medium">
        {title ||
          (titleId && <FormattedMessage id={title ? title : titleId} />)}
      </p>
      <div className="flex flex-col py-1 md:py-2">
        {[].concat(children).map((child, idx) => {
          if (child) {
            return cloneElement(child, {
              key: idx,
              onClick: (evt) => {
                const { onClick } = child.props;
                hide();
                onClick && onClick(evt);
              },
              className: classNames(
                child.props.className,
                " inline-flex items-center w-full px-4 py-2 md:py-2 focus:shadow-none" +
                  "focus:bg-scout-gray-light hover:bg-scout-gray-light"
              ),
            });
          }
          return null;
        })}
      </div>
    </>
  );
};

export default function Popup({ className, trigger, children }) {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  return (
    <Tippy
      appendTo="parent"
      interactive={true}
      visible={visible}
      onClickOutside={hide}
      placement="bottom"
      content={
        <PopupContext.Provider value={{ hide, visible }}>
          <div
            className={classNames(
              className,
              "py-4 rounded shadow-lg bg-white border w-48"
            )}
          >
            {children}
          </div>
        </PopupContext.Provider>
      }
    >
      {cloneElement(trigger, { ...trigger.props, onClick: show })}
    </Tippy>
  );
}
