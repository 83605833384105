import React, { createContext, useCallback, useContext } from "react";
import useResource from "../hooks/useResource";
import AlertsContext from "./AlertsContext";

const NotificationsContext = createContext();

const NotificationsProvider = ({ children, site }) => {
  const { rules } = useContext(AlertsContext);
  const [[{ entities: notifications }], crud] = useResource(
    "alerts/notifications",
    {
      site: site.id,
    }
  );

  const sorted = notifications?.sort(
    (a, b) => new Date(b.start) - new Date(a.start)
  );

  const unread = sorted?.filter(({ acknowledged }) => !acknowledged);
  const unresolved = sorted ? sorted.filter(({ resolved }) => !resolved) : [];

  const acknowledgeNotification = (notification) =>
    crud.update({ ...notification, acknowledged: new Date().toISOString() });

  const getNotificationsForDevice = useCallback(
    (device) => {
      const enabled = rules
        .filter((rule) => rule.enabled)
        .map((rule) => rule.id);
      return notifications
        ? notifications.filter(
            ({ rule_id, measurements }) =>
              enabled.includes(rule_id) &&
              measurements.map(({ device_id }) => device_id).includes(device.id)
          )
        : [];
    },
    [notifications, rules]
  );

  return (
    <NotificationsContext.Provider
      value={{
        crud,
        notifications: sorted,
        acknowledgeNotification,
        unread,
        unresolved,
        getNotificationsForDevice,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
export { NotificationsProvider };
