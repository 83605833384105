const { blue, red, yellow, green, orange } = require("./colors");
const shadowIndigo = "#4163C81A";
const chroma = require("chroma-js");

module.exports = {
  purge: ["./src/**/*.html", "./src/**/*.jsx"],
  theme: {
    inset: {
      "0": 0,
      auto: "auto",
      "1/2": "50%",
    },
    maxHeight: {
      "0": "0",
      "1/4": "25%",
      "1/4-vh": "25vh",
      "1/2": "50%",
      "1/2-vh": "50vh",
      "3/4": "75%",
      "3/4-vh": "75vh",
      full: "100%",
      screen: "100vh",
    },
    fontSize: {
      sm: ["12px", "14px"],
      base: ["14px", "17px"],
      md: ["16px", "19px"],
      lg: ["20px", "24px"],
      xl: ["24px", "29px"],
      "2xl": ["28px", "34px"],
    },
    borderRadius: {
      none: "0",
      // 5 px
      sm: "0.357rem",
      // 10 px
      default: "0.714rem",
      // 15 px
      md: "1.071rem",
      // 20 px
      lg: "1.429rem",
      full: "9999px",
    },
    extend: {
      fontFamily: {
        sans: ["Roboto", "sans-serif"],
      },
      textColor: {
        primary: "theme(colors.scout-black)",
        light: "theme(colors.scout-gray.light)",
      },
      gridTemplateColumns: {
        "dash-header": "minmax(36.5rem, min-content) auto",
        form: "min-content 1fr",
        single: "1fr",
      },
      gridTemplateRows: {
        "dash-content": "min-content auto",
      },
      backgroundOpacity: {
        "15": "0.15",
      },
      scale: {
        "-1": "-1",
      },
      colors: {
        "scout-black": "#4A4A55",
        "scout-gray": {
          light: "#F0F2F9",
          default: "#E3E7F1",
          dark: "#A2A9B9",
          input: "#AAB3DC33",
          transparent: chroma("#A2A9B9").alpha(0.5).css(),
        },
        "scout-blue": {
          light: "#6479FF",
          default: blue.css(),
          dark: blue.darken(0.5).css(),
        },
        "scout-green": {
          light: green.brighten(0.5).css(),
          default: green.css(),
          dark: green.darken(0.5).css(),
        },
        "scout-yellow": {
          light: yellow.brighten(0.5).css(),
          default: yellow.css(),
          dark: yellow.darken(0.5).css(),
        },
        "scout-red": {
          light: red.brighten(0.5).css(),
          default: red.css(),
          dark: red.darken(0.5).css(),
        },
        "scout-orange": {
          light: orange.brighten(0.5).css(),
          default: orange.css(),
          dark: orange.darken(0.5).css(),
          adjusted: orange
            .saturate(3.5)
            .css(),
        },
      },
      linearGradientColors: {
        primary: [
          "theme(colors.scout-blue.default)",
          "theme(colors.scout-blue.light)",
        ],
        moisture: ["theme(colors.scout-blue.default)", "#9AAAFF"],
        temperature: ["#FF50A2", "#FFC881"],
        temperature_negative: ["#6479FF", "#e2e8f0"],
        salinity: ["#8037FF", "#F192E4"],
        water_balance: ["#0BD465", "#0AC92A"],
        water_balance2: ["#FCDC0D", "#F2BC0C"],
        water_balance3: ["#FA4F2D", "#D44326"],
        voltage: [
          "theme(colors.scout-green.default)",
          "theme(colors.scout-green.light)",
        ],
        external1: [
          "theme(colors.scout-green.default)",
          "theme(colors.scout-green.light)",
        ],
        external2: ["theme(colors.scout-green.default)", "#E5DE00", "#D2042D"],
        battery1: [
          "theme(colors.scout-green.default)",
          "theme(colors.scout-green.light)",
        ],
        battery2: ["theme(colors.scout-green.light)", "#E6CC00"],
        battery3: ["#E6CC00", "#FFC881"],
        battery4: ["#FFC881", "#F88379"],
        battery5: ["#EE4B2B", "#D2042D"],
        oxygen: ["#2661ed", "#51dfd1"],

      },
      boxShadow: {
        // 4px 4px 8px @ 14px
        indigo: `0.286rem 0.286rem 0.571rem ${shadowIndigo}`,
        // 10px 10px 30px @ 14px
        "indigo-lg": `0.714rem 0.714rem 2.143rem ${shadowIndigo}`,
      },
      animation: {
        "spin-slow": "spin 2s linear infinite",
      },
      width: {
        "1/10": "10%",
        "26": "6.5rem",
        "34": "8.5rem",
        "44": "11rem",
        "52": "13rem", 
      },
    },
  },
  variants: {
    opacity: ["responsive", "hover", "focus", "disabled"],
    visibility: ["group-hover"],
    padding: ["first", "last", "responsive"],
    backgroundColor: ["responsive", "hover", "focus", "group-hover", "active"],
    borderColor: ["responsive", "hover", "focus", "first"],
    borderWidth: ["responsive", "first", "last"],
    textColor: [
      "responsive",
      "hover",
      "focus",
      "visited",
      "group-focus",
      "group-hover",
    ],
    scale: ["group-focus", "group-hover"],
    margin: ["responsive", "first", "last", "odd"],
    backgroundImage: ["responsive"],
    boxShadow: ["responsive", "hover", "focus", "active"],
  },
  plugins: [
    require("@tailwindcss/custom-forms"),
    require("tailwindcss-gradients"),
  ],
};
