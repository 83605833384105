import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { subDays } from "date-fns";
import { areaLineRange, line } from "billboard.js";
import { celciusToFahrenheit } from "../../localization";
import { useParams } from "react-router-dom";
import Card from "../Card";
import LineGraph from "../LineGraph";
import { StudioCardTable } from "./StudioCardTable";
import { FormattedMessage, useIntl } from "react-intl";
import * as util from "../../utility/common";
import AnalysisContext from "../../state/AnalysisContext";
import { usePrompt } from "../../hooks/usePrompt";
import { PopupSection } from "../Popup";
import DeviceGroupContext from "../../state/DeviceGroupContext";
import { toast } from "react-toastify";

const windowSize = 300;

const variables = [
  "moisture",
  "temperature",
  "conductivity",
  "salinity",
  "water_balance",
];

const handleAsyncError = (err) => {
  console.error("Analysis error:", err);
  toast.error(<FormattedMessage id={"analysis.toast.group_no_devices"} />);
};

const getGroupDevices = (source, groups) => {
  if (source && groups) {
    const found = groups.find((g) => source.id === g.id);
    return found && found.devices
      ? found.devices.map((id) => ({
          id,
        }))
      : [];
  }

  return [];
};

const prepareMultiple = (data, devices, tempUnit) => {
  if (data && data.length > 0) {
    let result = Object.assign(
      {},
      ...variables.map((varr) => ({
        [varr]: {
          ...Object.assign(
            {},
            ...devices.map(({ name, uniqueId }) => ({
              [`${name} ${uniqueId}`]: {
                name: `${name} ${uniqueId}`,
                type: line(),
                columns: {
                  ys: [`${name} ${uniqueId}`],
                  xs: [`xs-${name} ${uniqueId}`],
                },
                yMax: undefined,
                yMin: undefined,
                xs: `xs-${name} ${uniqueId}`,
              },
            }))
          ),
        },
      }))
    );

    const startTime = devices[0].start_date;
    const names = Object.assign(
      {},
      ...devices.map((dev) => ({ [dev.id]: `${dev.name} ${dev.uniqueId}` }))
    );

    for (const meas of data) {
      const device_id = meas.devices[0];

      variables.forEach((varr) => {
        let val =
          varr === "moisture" || varr === "water_balance"
            ? meas[varr].max
            : meas[varr].median;

        if (varr === "moisture") {
          val = val * 100;
        } else if (varr === "temperature" && tempUnit === "fahrenheit") {
          val = celciusToFahrenheit(val);
        }

        const series = result[varr][names[device_id]];

        if (series) {
          series.columns.xs.push(
            util.subISODatesToDifferenceInMilliseconds(
              startTime,
              meas.timestamp.median
            )
          );
          series.columns.ys.push(val);

          if (val !== null) {
            if (series.yMax === undefined || val > series.yMax) {
              series.yMax = val;
            }
            if (series.yMin === undefined || val < series.yMin) {
              series.yMin = val;
            }
          }
        }
      });
    }
    return result;
  } else {
    return {};
  }
};

const prepareAggregate = (data, { name, uniqueId, start_date }, tempUnit) => {
  const nameWithUniqueId = `${name} ${uniqueId}`;

  if (data && data.length > 0) {
    let result = Object.assign(
      {},
      ...variables.map((varr) => ({
        [varr]: {
          [nameWithUniqueId]: {
            name: nameWithUniqueId,
            type: areaLineRange(),
            columns: {
              ys: [nameWithUniqueId],
              xs: [`xs-${nameWithUniqueId}`],
            },
            yMax: undefined,
            yMin: undefined,
            xs: `xs-${nameWithUniqueId}`,
          },
        },
      }))
    );

    for (const meas of data) {
      variables.forEach((varr) => {
        let { max, min, mean } = meas[varr];
        let val = [max, mean, min];

        if (varr === "moisture") {
          val = val.map((num) => (num ? num * 100 : null));
        } else if (varr === "temperature" && tempUnit === "fahrenheit") {
          val = val.map(celciusToFahrenheit);
        }

        const series = result[varr][nameWithUniqueId];

        if (series) {
          series.columns.xs.push(
            util.subISODatesToDifferenceInMilliseconds(
              start_date,
              meas.timestamp.median
            )
          );
          series.columns.ys.push(val);

          if (val !== null) {
            if (series.yMax === undefined || val > series.yMax) {
              if (val[0] !== null && val[1] !== null && val[2] !== null) {
                series.yMax = val;
              }
            }
            if (series.yMin === undefined || val < series.yMin) {
              if (val[0] !== null && val[1] !== null && val[2] !== null) {
                series.yMin = val;
              }
            }
          }
        }
      });
    }
    return result;
  } else {
    return {};
  }
};

export const prepareMeasurement = (data, sources, canceled) => {
  let result = Object.assign({}, ...variables.map((varr) => ({ [varr]: {} })));
  sources.forEach((source) => {
    const meas = data.find((entity) => {
      return (
        Object.keys(entity).length !== 0 &&
        entity[source.data_type][`${source.name} ${source.uniqueId}`] !==
          undefined
      );
    });
    if (meas) {
      const name = Object.keys(meas[source.data_type])[0];
      meas[source.data_type][name].color = source.color;

      Object.assign(result[source.data_type], meas[source.data_type]);
    }
  });

  if (!canceled) {
    return result;
  }
};

const actionHandler = (state, { action, element }) => {
  switch (action) {
    case "add":
      return { action, element };
    case "add-all":
      return { action, element };
    case "remove":
      return { action, element };
    case "update":
      return { action, element };
    case "clear":
      return { action, element };
    default:
      throw new Error();
  }
};

const shouldShowPrompt = (
  hasUpdatedCharts,
  persistedDuration,
  currentDuration
) => {
  return hasUpdatedCharts || persistedDuration !== currentDuration;
};

const LineGraphCard = ({
  analysis,
  currentUser,
  charts,
  timeSpan,
  durationPickerRef,
  getMeasurementsDownsampled,
  ...rest
}) => {
  const { id } = useParams();
  const analysisKey = id;
  const { updatedCharts, updateCharts } = useContext(AnalysisContext);
  const { groups } = useContext(DeviceGroupContext);

  const [lodDomain, setLodDomain] = useState(null);

  const LineGraphGuideText = () =>
    showHints && (
      <div className="my-8 text-center text-graph-gray">
        <FormattedMessage id={`analysis.lg_hint.${hintNo}`} />
      </div>
    );

  const intl = useIntl();

  function zoom(domain) {
    setLodDomain(domain);
  }

  function unzoom() {
    setLodDomain(null);
  }

  const xAxisMinMax = useMemo(
    () =>
      lodDomain
        ? {
            min: lodDomain[0],
            max: lodDomain[1],
          }
        : {
            min: 0,
            max: util.daysToMilliseconds(timeSpan),
          },
    [timeSpan, lodDomain]
  );

  const tempUnit = currentUser?.pref_unit_temp;

  const getColor = (dev) => {
    return `#${dev.uniqueId}`;
  };

  const initialSources = updatedCharts.has(analysisKey)
    ? updatedCharts.get(analysisKey).sources
    : charts.sources?.map((source) => ({
        ...source,
        uniqueId: source?.uniqueId ? source.uniqueId : util.generateRandomId(),
        color: source.color,
      }));

  const [sources, setSources] = useState(initialSources);
  const [latestAction, dispatch] = useReducer(actionHandler, undefined);

  const [hintNo, setHintNo] = useState(1);
  const [showHints, setShowHints] = useState(true);
  // const [showHints, setShowHints] = useState(charts.sources.length === 0 ? true : false);

  useEffect(() => {
    setHintNo(1 + (sources.length > 0 ? 1 : 0) + (sources.length > 1 ? 1 : 0));
  }, [sources]);

  const setLoading = useCallback(
    (bool) => {
      if (durationPickerRef.current) {
        durationPickerRef.current.setLoading(bool);
      }
    },
    [durationPickerRef]
  );

  const handleMeasurementError = (err) => {
    console.error("No measurements:", err);
    toast.error(<FormattedMessage id={"error.no_measurements"} />);
  };

  const fetchMeasurements = (devices, qs, group) => {
    const queryDevices = group ? getGroupDevices(group, groups) : devices;

    if (group && queryDevices.length === 0) {
      return Promise.reject(new Error());
    }

    return getMeasurementsDownsampled(queryDevices, qs)
      .then((data) =>
        qs.aggregate_all
          ? prepareAggregate(data, devices[0], tempUnit)
          : prepareMultiple(data, devices, tempUnit)
      )
      .then((result) => {
        return prepareMeasurement([result], devices);
      })
      .catch((err) => console.error("Error loading measurements from API", err))
      .finally(() => setLoading(false));
  };

  const handleUpdatedMeasurement = (newMeas, device, tmp, index, action) => {
    let yMin;
    let yMax;

    if (!util.hasMeasurements(newMeas)) {
      handleMeasurementError();
      dispatch({
        action: "remove",
        element: device,
      });
      dispatch({ action: "clear", element: {} });
    } else {
      yMin =
        newMeas[device.data_type][`${device.name} ${device.uniqueId}`].yMin;
      yMax =
        newMeas[device.data_type][`${device.name} ${device.uniqueId}`].yMax;
    }

    if (device.group) {
      yMin = yMin ? yMin[2] : null;
      yMax = yMax ? yMax[0] : null;
    }

    const min = util.isNumber(yMin) ? yMin : undefined;
    const max = util.isNumber(yMax) ? yMax : undefined;
    const avg =
      util.isNumber(min) && util.isNumber(max) ? (min + max) / 2 : undefined;

    device.min = min;
    device.max = max;
    device.avg = avg;

    tmp[index] = device;

    updateCharts(analysisKey, charts, tmp);
    setSources(tmp);

    if (util.hasMeasurements(newMeas)) {
      dispatch({ action: action, element: newMeas });
      dispatch({ action: "clear", element: {} });
    }
  };

  const addItemToTable = (tableItems, key, id, scout) => {
    setLoading(true);

    const now = new Date();
    const since = subDays(now, timeSpan).toISOString();
    const isGroup = scout.group ?? false;

    const deviceWithoutColor = {
      id: scout.id,
      name: scout.name,
      data_type: "temperature",
      start_date: since,
      ...(scout.group ? { group: true } : {}),
      uniqueId: util.generateRandomId(),
    };

    const device = {
      ...deviceWithoutColor,
      color: getColor(deviceWithoutColor),
    };

    const qs = util.getQuerySet(
      lodDomain,
      since,
      windowSize,
      timeSpan,
      isGroup
    );

    fetchMeasurements([device], qs, isGroup && device)
      .then((newMeas) => {
        let yMin;
        let yMax;

        if (!util.hasMeasurements(newMeas)) {
        } else {
          yMin =
            newMeas[device.data_type][`${device.name} ${device.uniqueId}`].yMin;

          yMax =
            newMeas[device.data_type][`${device.name} ${device.uniqueId}`].yMax;

          if (isGroup) {
            yMin = yMin ? yMin[2] : null;
            yMax = yMax ? yMax[0] : null;
          }
        }

        const min = util.isNumber(yMin) ? yMin : undefined;
        const max = util.isNumber(yMax) ? yMax : undefined;
        const avg =
          util.isNumber(min) && util.isNumber(max)
            ? (min + max) / 2
            : undefined;

        const newDevice = {
          ...device,
          min: min,
          max: max,
          avg: avg,
        };

        updateCharts(analysisKey, charts, [...sources, newDevice]);
        setSources((prev) => [...prev, newDevice]);

        if (util.hasMeasurements(newMeas)) {
          dispatch({ action: "add", element: newMeas });
          dispatch({ action: "clear", element: {} });
        }
      })
      .catch(handleAsyncError)
      .finally(() => {
        setLoading(false);
        dispatch({ action: "clear", element: {} });
      });
  };

  const updateStartDate = (tableItems, key, id, index, startDate) => {
    setLoading(true);
    const tmp = util.deepCopy(sources);
    const scout = tmp[index];
    scout.color = tmp[index]?.color ? tmp[index]?.color : getColor(tmp[index]);
    const isGroup = scout.group ?? false;

    scout.start_date = new Date(startDate).toISOString();

    const qs = util.getQuerySet(
      lodDomain,
      scout.start_date,
      windowSize,
      timeSpan,
      isGroup
    );

    fetchMeasurements([scout], qs, isGroup && scout)
      .then((newMeas) =>
        handleUpdatedMeasurement(newMeas, scout, tmp, index, "update")
      )
      .finally(() => {
        dispatch({ action: "clear", element: {} });
        setLoading(false);
      });
  };

  const updateMetricType = (tableItems, key, id, index, metricType) => {
    setLoading(true);
    const tmp = util.deepCopy(sources);
    const scout = tmp[index];
    scout.color = tmp[index]?.color ? tmp[index]?.color : getColor(tmp[index]);
    const isGroup = scout.group ?? false;

    scout.data_type = metricType;

    const qs = util.getQuerySet(
      lodDomain,
      scout.start_date,
      windowSize,
      timeSpan,
      isGroup
    );

    fetchMeasurements([scout], qs, isGroup && scout)
      .then((newMeas) =>
        handleUpdatedMeasurement(newMeas, scout, tmp, index, "update")
      )
      .finally(() => {
        dispatch({ action: "clear", element: {} });
        setLoading(false);
      });
  };

  const removeItemFromTable = (tableItems, key, id, index) => {
    setLoading(true);
    if (index > -1) {
      const tmp = util.deepCopy(sources);
      dispatch({
        action: "remove",
        element: tmp[index],
      });

      tmp.splice(index, 1);
      updateCharts(analysisKey, charts, tmp);
      setSources(tmp);
    }
    setLoading(false);
  };

  const tableActionHandlers = {
    add: addItemToTable,
    remove: removeItemFromTable,
    updateDate: updateStartDate,
    updateMetricType,
  };

  const updateAllMeasurements = useCallback(
    (domain) => {
      setLoading(true);
      let canceled = false;
      let promises = [];

      sources.forEach((source) => {
        const qs = util.getQuerySet(
          domain,
          source.start_date,
          windowSize,
          timeSpan,
          source.group
        );

        const newSource = source.group
          ? { ...source, devices: getGroupDevices(source, groups) }
          : source;

        const queryDevices = newSource.devices ?? [newSource];

        promises.push(
          getMeasurementsDownsampled(queryDevices, qs).then((data) =>
            qs.aggregate_all
              ? prepareAggregate(data, newSource, tempUnit)
              : prepareMultiple(data, [source], tempUnit)
          )
        );
      });

      Promise.all(promises)
        .then((data) => {
          const result = prepareMeasurement(data, sources, canceled);

          if (util.hasMeasurements(result)) {
            const res = sources.map((updated) => {
              let min =
                result[updated.data_type][`${updated.name} ${updated.uniqueId}`]
                  ?.yMin;
              let max =
                result[updated.data_type][`${updated.name} ${updated.uniqueId}`]
                  ?.yMax;

              if (updated.group) {
                min = min ? min[2] : null;
                max = max ? max[0] : null;
              }
              const avg =
                util.isNumber(min) && util.isNumber(max)
                  ? (min + max) / 2
                  : undefined;

              return {
                ...updated,
                min,
                max,
                avg,
              };
            });
            dispatch({ action: "add-all", element: result });
            setSources(res);
          }
        })
        .finally(() => {
          dispatch({ action: "clear", element: {} });
          setLoading(false);
        });

      return () => {
        canceled = true;
        document.body.style.cursor = "default";
      };
    },
    [
      getMeasurementsDownsampled,
      setLoading,
      tempUnit,
      timeSpan,
      sources,
      groups,
    ]
  );

  useEffect(() => {
    dispatch({ action: "clear", element: {} });
    updateAllMeasurements(lodDomain);
    // eslint-disable-next-line
  }, [timeSpan, lodDomain]);

  const prompt = usePrompt(
    shouldShowPrompt(
      updatedCharts?.has(analysisKey),
      analysis.duration,
      timeSpan
    ),
    intl.formatMessage({ id: "analysis.unsaved" })
  );

  const HideHintsMenu = useCallback(
    () => (
      <PopupSection title={intl.formatMessage({ id: "line_graph.menu.hints" })}>
        <button onClick={() => setShowHints((prev) => !prev)}>
          {showHints ? intl.formatMessage({ id: "line_graph.menu.hide_hints" }) : intl.formatMessage({ id: "line_graph.menu.show_hints" })} 
        </button>
      </PopupSection>
    ),
    [showHints, intl]
  );

  return (
    <Card heading={intl.formatMessage({ id: "analyses_graph.title.graph_overlay" })} className="mb-6" menu={<HideHintsMenu />}>
      <LineGraphGuideText />
      <>
        {lodDomain && (
          <button
            className="absolute btn bg-white shadow-indigo mr-2 ml-auto 
            -mt-6 -mb-2 z-50 right-0 transform -translate-x-1/2"
            onClick={() => {
              unzoom();
            }}
          >
            <FormattedMessage id="button.reset-zoom" />
          </button>
        )}
        <LineGraph
          sources={sources}
          latestAction={latestAction}
          xAxisMinMax={xAxisMinMax}
          tempUnit={tempUnit}
          timeSpan={timeSpan}
          windowSize={windowSize}
          levelOfDetail={{ zoom, unzoom }}
          {...rest}
        />
      </>
      <StudioCardTable
        sources={sources}
        analysisKey={analysisKey}
        chartsId={charts.id}
        tableActionHandlers={tableActionHandlers}
        timeSpan={timeSpan}
        durationPickerRef={durationPickerRef}
        hintNo={hintNo}
        showHints={showHints}
      />
      {prompt}
    </Card>
  );
};

export default LineGraphCard;
