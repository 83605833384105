import React, { useContext, useMemo, useState } from "react";
import { Redirect } from "react-router";
import { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import sitePlaceholder from "../assets/site-placeholder.jpg";
import { SiteContext } from "../state/SiteContext";
import Card from "./Card";
import CoverCard from "./CoverCard";
import DashContent from "./DashContent";
import DashHeader from "./DashHeader";
import { Input } from "./FormWidgets";
import { SectionHeading, SubsectionHeading } from "./Headings";
import { IconBell, IconGrid, IconList } from "./Icons";
import Table from "./Table";
import { AuthContext } from "../state/AuthContext";
import { buildRequest } from "../api";
import { UserContext } from "../state/UserContext";

const SiteTable = ({ sites }) => {
  const intl = useIntl()

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({id:"site_table.header.name"}),
        accessor: "name",
        className: "first:pl-8",
        sortIndicator: "alpha",
        Cell: (cell) => (
          <span className="text-scout-blue font-medium">{cell.value}</span>
        ),
      },
      { Header: intl.formatMessage({id:"site_table.header.devices"}), accessor: "device_count", sortIndicator: "numeric" },
      { Header: intl.formatMessage({id:"site_table.header.groups"}), accessor: "group_count", sortIndicator: "numeric" },
      {
        Header: intl.formatMessage({id:"site_table.header.notifications"}),
        accessor: "unread_notification_count",
        sortIndicator: "numeric",
      },
    ],
    [intl]
  );

  const data = useMemo(() => sites, [sites]);

  return (
    <Card className="max-w-2xl my-4 pt-0 px-0">
      <Table
        columns={columns}
        data={data}
        sortBy={[{ id: "name" }]}
        linkTo={(site) => `/sites/${site.id}/`}
      />
    </Card>
  );
};

const SiteList = ({ sites }) => (
  <ul className="w-full h-full max-w-screen-xl flex flex-wrap items-start content-start justify-center">
    {sites?.map((site) => (
      <li className="w-full sm:w-auto" key={site.id}>
        <CoverCard
          cover={site.image ? site.image : sitePlaceholder}
          linkTo={`/sites/${site.id}/`}
          cornerWidget={
            site.unread_notification_count > 0 && (
              <div className="flex w-full justify-end">
                <div className="ml-2 px-2 py-1 bg-red-600 rounded flex items-center">
                  <IconBell className="text-white" />
                  <span className="ml-3 mr-1 font-medium">
                    {site.unread_notification_count}
                  </span>
                </div>
              </div>
            )
          }
        >
          <div>
            <SubsectionHeading>{site.name}</SubsectionHeading>
            <p className="whitespace-no-wrap capitalize">
              {site.device_count > 0
                ? site.device_count > 1
                  ? `${site.device_count} devices`
                  : "1 device"
                : "No devices"}
              {", "}
              {site.group_count > 0
                ? site.group_count > 1
                  ? `${site.group_count} groups`
                  : "1 group"
                : "no groups"}
            </p>
          </div>
        </CoverCard>
      </li>
    ))}
  </ul>
);

const SearchBar = ({ filter, setFilter, ...rest }) => {
  return (
    <FormattedMessage id="sites.search_placeholder">
      {(message) => (
        <Input
          className="w-full h-12 max-w-xl shadow-indigo hover:bg-gray-100 focus:shadow-none"
          placeholder={message}
          value={filter}
          onChange={(evt) => {
            const searchString = evt.target.value;
            setFilter(searchString ? searchString : "");
          }}
          white
          {...rest}
        />
      )}
    </FormattedMessage>
  );
};

const SiteView = ({ preventRedirect }) => {
  const { authenticatedFetch } = useContext(AuthContext);
  const { currentUser } = useContext(UserContext);
  const [view, setView] = useQueryParam(
    "display",
    withDefault(StringParam, currentUser.is_superuser ? "table" : "grid")
  );
  const { sites } = useContext(SiteContext);
  const [updatedSites, updateSites] = useState(sites);

  useEffect(() => {
    if (view === "grid") {
      authenticatedFetch(
        buildRequest("GET").withPath("/sites/").withQuery({ with_image: true })
      ).then(updateSites);
    }
  }, [view, authenticatedFetch]);

  const [filter, setFilter] = useState("");
  const filtered = updatedSites
    .filter(({ name }) =>
      name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    )
    .sort((a, b) => (a?.name < b?.name ? -1 : 1));

  return (
    <>
      {sites?.length === 1 && !preventRedirect && (
        <Redirect to={`/sites/${sites[0].id}/`} />
      )}
      <DashHeader span>
        <SectionHeading className="hidden sm:inline-flex">
          <FormattedMessage id="sites.header" />
        </SectionHeading>
        <div className="sm:ml-4 flex-1 flex items-center justify-end">
          <SearchBar
            autoFocus
            filter={filter}
            setFilter={setFilter}
            sites={updatedSites}
          />
          <button
            className="btn bg-white ml-2 hover:bg-gray-100 hover:border-gray-400 border-2 border-transparent shadow-indigo focus:shadow-none focus:border-scout-blue"
            onClick={() => setView(view === "grid" ? "table" : "grid")}
          >
            {view !== "table" ? (
              <IconList className="h-5" />
            ) : (
              <IconGrid className="h-5" />
            )}
          </button>
        </div>
      </DashHeader>
      <DashContent className="px-4" span>
        {view === "grid" ? (
          <SiteList sites={filtered} />
        ) : (
          <SiteTable sites={filtered} />
        )}
      </DashContent>
    </>
  );
};

export default SiteView;
