import React from "react";
import { default as ReactModal } from "react-modal";

if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

const Modal = ({ isOpen, onRequestClose, children, ...props }) => (
  <ReactModal
    className="modal-content"
    overlayClassName="modal-overlay"
    onRequestClose={onRequestClose}
    isOpen={isOpen}
    {...props}
  >
    {children}
  </ReactModal>
);

export default Modal;
