import React, { useContext, useEffect, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  CapitalizedMessage,
  FormattedDatetime,
  FormattedVoltage,
  FormattedPacketsPerDay,
} from "../localization";
import Card from "./Card";
import ConnectionStatus from "./ConnectionStatus";
import Gauge from "./Gauge";
import { useInterval } from "../hooks/useInterval";
import { buildRequest } from "../api";
import { AuthContext } from "../state/AuthContext";
import { PopupSection } from "./Popup";
import { ShowElementButton } from "./DashCards";
import { useIntl } from "react-intl";

const UPDATE_INTERVAL = 10000;

export default function ScoutOverview({ scout, minMax }) {
  const { authenticatedFetch } = useContext(AuthContext);
  const [updatedScout, updateScout] = useState(scout);
  const qs = JSON.stringify({ with_details: true });

  const intl = useIntl();

  const fetchDevice = useCallback(
    () =>
      authenticatedFetch(
        buildRequest("GET")
          .withPath(`/devices/${scout.id}/`)
          .withQuery(JSON.parse(qs))
      ),
    [scout, qs, authenticatedFetch]
  );

  useInterval(async () => {
    fetchDevice().then(updateScout);
  }, UPDATE_INTERVAL);

  useEffect(() => {
    fetchDevice().then(updateScout);
    updateScout(scout);
  }, [scout, fetchDevice]);

  const { moisture, temperature, salinity, oxygen } = updatedScout.last_measurement
    ? updatedScout.last_measurement
    : {};
  const { last_seen } = updatedScout;
  const { voltage_battery } = updatedScout;
  const { packets_per_day } = updatedScout;
  const [visible, toggleOverview] = ShowElementButton("overview", intl.formatMessage({id: "scout_overview.popupsection.overview"}));

  return (
    <FormattedMessage id="dash_card.scout_overview">
      {(message) => (
        <Card
          heading={message}
          menu={<PopupSection title={intl.formatMessage({id: "scout_overview.tooltip.label.show"})}>
                  {toggleOverview}
                </PopupSection>
                }
        >
          {visible && (
            <>
              <div className="-mb-4 items-center grid sm:grid-cols-form row-gap-2 col-gap-3">
                <CapitalizedMessage
                  className="font-medium"
                  id="measurement.moisture"
                />
                <Gauge
                  className="bg-gradient-l-moisture"
                  value={moisture}
                  lastMeasurement={updatedScout.last_measurement}
                  measuredVariable={"moisture"}
                />

                <CapitalizedMessage
                  className="font-medium"
                  id="measurement.temperature"
                />
                <Gauge
                  className="bg-gradient-l-temperature"
                  value={temperature}
                  lastMeasurement={updatedScout.last_measurement}
                  measuredVariable={"temperature"}
                />

                {(updatedScout.device_type === "hydra" && updatedScout.protocol_version === "209") ? 
                  (
                    <>
                      <CapitalizedMessage
                      className="font-medium"
                      id="measurement.oxygen"
                      />
                      <Gauge
                        className="bg-gradient-l-oxygen"
                        value={oxygen}
                        lastMeasurement={updatedScout.last_measurement}
                        measuredVariable={"oxygen"}
                      />
                    </>
                  )
                  :
                  (
                    <>
                      <CapitalizedMessage
                      className="font-medium"
                      id="measurement.salinity"
                      />
                      <Gauge
                        className="bg-gradient-l-salinity"
                        value={salinity}
                        lastMeasurement={updatedScout.last_measurement}
                        measuredVariable={"salinity"}
                      />
                    </>
                  )
                }
                

                

                <CapitalizedMessage
                  className="font-medium flex"
                  id="device_table.header.status"
                />
                <div className="flex justify-between items-center">
                  <ConnectionStatus device={updatedScout} withText />
                  <p className="text-gray-600 text-sm">
                    <FormattedMessage id="connection_status.last_update" />{" "}
                    {last_seen ? (
                      <>
                        <FormattedDatetime value={last_seen} />,{" "}
                        <FormattedMessage id="measurement.battery" />{" "}
                        <FormattedVoltage value={voltage_battery} /> V,{" "}
                        <FormattedMessage id="measurement.packets_per_day" />:{" "}
                        <FormattedPacketsPerDay value={packets_per_day} />
                      </>
                    ) : (
                      <FormattedMessage id="scout_never" />
                    )}
                  </p>
                </div>
              </div>
            </>
          )}
        </Card>
      )}
    </FormattedMessage>
  );
}
