import React, { createContext, useContext, useEffect, useRef } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { AuthContext } from "../state/AuthContext";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import Header from "./Header";
import Loader from "./Loader";
import { NavSpacer } from "./Navigation";
import ProfileView from "./ProfileView";
import Eula, { latestGdpr } from "./Eula";
import SiteView from "./SiteView";
import UsersView from "./UsersView";
import PrivacyPolicyView from "./PrivacyPolicyView";
import Help from "./Help";
import Dashboard from "./Dashboard";
import SiteDetails from "./SiteDetails";
import ReactGA from "react-ga4";

export const DashContext = createContext();

const LogoutView = () => {
  const { logout } = useContext(AuthContext);
  useEffect(() => logout(), [logout]);
  return null;
};

export default function Main() {
  const {
    crud: { list: fetchSites },
  } = useContext(SiteContext);
  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  const match = useRouteMatch("/sites/:site_id/:view");
  const { site_id, view } = match ? match.params : {};
  const navRef = useRef();
  const { currentUser } = useContext(UserContext);
  const viewingDash = site_id !== undefined;
  const splitView = ["scouts", "devices"].includes(view);
  const history = useHistory();
  const location = useLocation();

  const loading = (
    <div className="col-span-2 row-span-2">
      <Loader />
    </div>
  );

  // Initialize google analytics page view tracking
  useEffect(() => {
    if (currentUser && !currentUser.is_superuser) {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.send({hitType: "pageview", page: location.pathname}); // Record a pageview for the given page
    }
  }, [location.pathname, currentUser]);

  return (
    <>
      <DashContext.Provider
        value={{ viewingDash, splitView, view, site_id, navRef }}
      >
        <Header ref={navRef} />
        <main className="flex-1 md:overflow-y-auto grid grid-rows-dash-content lg:grid-flow-col lg:grid-cols-dash-header">
          {currentUser ? (
            <SiteContext.Consumer>
              {({ sites }) =>
                sites === undefined ? (
                  loading
                ) : (
                  <Switch>
                    <Route path="/logout">
                      <LogoutView />
                    </Route>
                    {currentUser.accepted_eula !== latestGdpr["version"] && (
                      <Eula />
                    )}
                    <Route path="/profile">
                      <ProfileView />
                    </Route>
                    <Route path="/sites/all">
                      <SiteView preventRedirect />
                    </Route>
                    <Route path={`/sites/addSite`}>
                      <SiteDetails
                        linkTo={"/sites/"}
                        onSave={() => history.goBack()}
                      />
                    </Route>
                    <Route path={`/sites/:site_id`}>
                      <Dashboard />
                    </Route>
                    <Route path="/sites">
                      <SiteView />
                    </Route>
                    <Route path="/privacy">
                      <PrivacyPolicyView />
                    </Route>
                    <Route path="/help">
                      <Help />
                    </Route>
                    {currentUser.is_admin && (
                      <Route path={"/users"}>
                        <UsersView />
                      </Route>
                    )}
                    <Route>
                      <Redirect to="/sites" />
                    </Route>
                  </Switch>
                )
              }
            </SiteContext.Consumer>
          ) : (
            loading
          )}
        </main>
        <NavSpacer />
      </DashContext.Provider>
    </>
  );
}
