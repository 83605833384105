const apiPrefix = "internal_api/";

const errorTranslate = {
  "This field must be unique.": "validation.serial_taken",
  "no device matching serial_number found": "validation.no_such_serial",
};

const createURL = (path, version) =>
  new URL(`${apiPrefix}${version}${path}`, window.location.origin);

const paginatedFetch = (url, opts, result = []) =>
  url
    ? fetch(url, opts)
      .then((resp) =>
        resp.ok
          ? // Skipping 'no-content' responses
          resp.status !== 204
            ? resp.headers.get("content-type") === "application/json"
              ? resp.json()
              : resp
            : Promise.resolve({})
          : Promise.reject(resp)
      )
      .then((json) =>
        "next" in json
          ? paginatedFetch(
            json.next
              ? new URL(json.next).pathname + new URL(json.next).search
              : undefined,
            opts,
            result.concat(json.results)
          )
          : paginatedFetch(undefined, opts, json)
      )
    : result;

const buildRequest = (method) => ({
  opts: {
    method,
    headers: {
      "Content-type": "application/json",
    },
  },
  withUrl: function (url) {
    this.url = url;
    return this;
  },
  withPath: function (path, version) {
    if (version) {
      this.url = createURL(path, version);
      return this;
    }
    
    this.url = createURL(path, "v1");
    return this;
  },
  withQuery: function (qs) {
    Object.entries(qs).forEach(([key, value]) => {
      if (value) {  // TODO: This skips all boolean == false values. Fix me.
        this.url.searchParams.append(key, value);
      }
    });
    return this;
  },
  withToken: function (token) {
    this.opts.headers.Authorization = `Bearer ${token.access}`;
    return this;
  },
  withBody: function (body) {
    this.opts.body = JSON.stringify(body);
    return this;
  },
  fetch: function () {
    return paginatedFetch(this.url, this.opts);
  },
});

export { buildRequest, errorTranslate };
