import chroma from "chroma-js";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import * as yup from "yup";
import useResource from "../hooks/useResource";
import schemaFields from "../state/schema";
import tailwindConfig from "../tailwindConfig";
import { SiteContext } from "./SiteContext";

const DeviceGroupContext = createContext();

const DeviceGroupProvider = ({ children }) => {
  const { currentSite } = useContext(SiteContext);
  const [[{ entities: groups, loading }], crud] = useResource("groups", {
    site: currentSite.id,
  });

  const validationSchema = useCallback(
    (group) =>
      yup.object().shape({
        name: schemaFields.uniqueString(groups, group, "name"),
      }),
    [groups]
  );

  const withColors = useMemo(() => {
    const color = (key) => tailwindConfig.theme.colors[key].default;
    if (groups) {
      const colors = chroma
        .scale([color("scout-blue"), color("scout-yellow")])
        .mode("lch")
        .colors(groups.length);
      return groups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dev, idx) => ({ ...dev, color: colors[idx] }));
    }
  }, [groups]);

  return (
    <DeviceGroupContext.Provider
      value={{
        groups: withColors,
        entities: withColors,
        crud,
        loading,
        validationSchema,
      }}
    >
      {children}
    </DeviceGroupContext.Provider>
  );
};

export default DeviceGroupContext;
export { DeviceGroupProvider };
