import { areaLineRange, bar, line } from "billboard.js";
import { celciusToFahrenheit } from "../localization";

// Takes a metric value if measuredVariable is "temperature"
export const isFreezingTemp = (value, measuredVariable) =>
  measuredVariable === "temperature" && value <= 0.0;

// Calculate padding from min to max regardless of units
// Dividing by zero or by NaN = NaN, which will result in no padding
export const calculateGaugePadding = (value, min, max) => {
  const padding = 95;
  const paddingMultiplier = 90;
  return padding - ((value - min) / (max - min)) * paddingMultiplier;
};

export const getDefiniteMax = (maxMeasurement, stepSize, maxRule) =>
  maxRule
    ? Math.ceil(Math.max(maxMeasurement, maxRule) / stepSize) * stepSize +
      stepSize
    : Math.ceil(maxMeasurement / stepSize) * stepSize + stepSize;

export const getDefiniteMin = (minMeasurement, stepSize, minRule) =>
  minRule
    ? Math.floor(Math.min(minRule, minMeasurement) / stepSize) * stepSize -
      stepSize
    : Math.floor(minMeasurement / stepSize) * stepSize - stepSize;

export const generateGridLines = (min, max, stepSize) => {
  let step = Math.floor(min / stepSize) * stepSize;
  let lines = undefined;
  lines = Array(Math.floor((max - step) / stepSize) + 1)
    .fill()
    .map(() => ({
      value: (step += stepSize),
    }));
  return lines;
};

export const getMinAlertRule = (alertRules, metric, min) => {
  let finalMin = min ? min : 0;
  Object.values(alertRules).forEach((rule) => {
    rule.conditions.forEach((condition) => {
      if (condition.measurement_type === metric?.toUpperCase()) {
        finalMin = Math.min(finalMin, condition.low_limit);
      }
    });
  });
  return metric === "moisture" ? finalMin * 100 : finalMin;
};

export const rulesToLines = (rules, transform) => {
  let result = [];
  for (const [idx, { name, condition }] of rules.entries()) {
    if (condition) {
      result.push({
        text: `${name} (low)`,
        value: transform
          ? transform.lo(condition.low_limit)
          : condition.low_limit,
        class: "rule-line-" + (idx % 2 ? "odd" : "even"),
        position: idx % 2 ? "start" : "end",
      });
      result.push({
        text: `${name} (high)`,
        value: transform
          ? transform.hi(condition.high_limit)
          : condition.high_limit,
        class: "rule-line-" + (idx % 2 ? "odd" : "even"),
        position: idx % 2 ? "start" : "end",
      });
    }
  }
  return result;
};

export const getMaxMeasurement = (measurements, metric) => {
  const tempMeas = measurements[metric] && Object.values(measurements[metric]);
  if (tempMeas?.length > 0) {
    let finalMax = 0;
    tempMeas.forEach((scout) => {
      scout?.yMax > finalMax && (finalMax = scout.yMax);
    });
    return finalMax;
  }
  return tempMeas;
};

export const getMinMeasurement = (measurements, metric) => {
  const tempMeas = measurements[metric] && Object.values(measurements[metric]);
  if (tempMeas?.length > 0) {
    let finalMin = tempMeas[0].yMin;
    tempMeas.forEach((scout) => {
      if (finalMin === undefined) {
        finalMin = scout.yMin;
      } else {
        scout?.yMin < finalMin && (finalMin = scout.yMin);
      }
    });
    return finalMin;
  }
  return tempMeas;
};

export const getMaxAlertRule = (alertRules, metric) => {
  let finalMax = 0;
  Object.values(alertRules).forEach((rule) => {
    rule.conditions.forEach((condition) => {
      if (condition.measurement_type === metric?.toUpperCase()) {
        finalMax = Math.max(finalMax, condition.high_limit);
      }
    });
  });
  return metric === "moisture" ? finalMax * 100 : finalMax;
};

export const prepareAggregateLine = (data, name, tempUnit, variables, timestamp, metadata) => {
  // Returns a single, grouped series
  if (data && data.length > 0) {
    let result = Object.assign(
      {},
      ...variables.map((varr) => ({
        [varr]: {
          [name]: {
            name,
            type: areaLineRange(),
            columns: { ys: [name], xs: [`xs-${name}`] },
            yMax: undefined,
            yMin: undefined,
            xs: `xs-${name}`,
          },
        },
      })),
      metadata,
    );

    for (const meas of data) {
      variables.forEach((varr) => {
        let { max, min, mean } = meas[varr];
        let val = [max, mean, min];

        if (varr === "moisture") {
          val = val.map((num) => (num ? num * 100 : null));
        } 
        else if (varr === "oxygen"){
          val = val.map((num) => (num ? num * 100 : null));
        } 
        else if (varr === "temperature" && tempUnit === "fahrenheit") {
          val = val.map(celciusToFahrenheit);
        }

        const series = result[varr][name];

        if (series) {
          series.columns.xs.push(new Date(meas.timestamp.median));
          series.columns.ys.push(val);
        }

        const newMean = val[1];

        if (newMean !== null) {
          if (series.yMax === undefined || newMean > series.yMax) {
            series.yMax = newMean;
          }
          if (series.yMin === undefined || newMean < series.yMin) {
            series.yMin = newMean;
          }
        }
      });
    }
    const maxDate = data.length
    result["timestamp"] = data[0].timestamp
    result["timestamp"]["max"] = data[maxDate-1].timestamp.max
    return result;
  } else {
    return {};
  }
};

export const prepareMultipleLine = (data, devices, tempUnit, variables, timestamp, metadata) => {
  // Returns multiple series, individually
  if (data && data.length > 0) {
    let result = Object.assign(
      {},
      ...variables.map((varr) => ({
        [varr]: {
          ...Object.assign(
            {},
            ...devices.map(({ name }) => ({
              [name]: {
                name,
                type: line(),
                columns: { ys: [name], xs: [`xs-${name}`] },
                yMax: undefined,
                yMin: undefined,
                xs: `xs-${name}`,
              },
            }))
          ),
        },
      })),
      metadata
    );

    const names = Object.assign(
      {},
      ...devices.map((dev) => ({ [dev.id]: dev.name }))
    );

    for (const meas of data) {
      const device_id = meas.devices[0];
      variables.forEach((varr) => {
        let val;
        if (typeof meas[varr] === "object") {
          val =
            varr === "moisture" || varr === "water_balance" || varr === "oxygen"
              ? meas[varr].max
              : meas[varr].median;
        } else {
          val = meas[varr];
        }

        if (varr === "moisture") {
          val = val * 100;
        } else if (varr === "temperature" && tempUnit === "fahrenheit") {
          val = celciusToFahrenheit(val);
        }else if (varr === "oxygen"){
          val = val * 100;
        }

        const series = result[varr][names[device_id]];

        if (series) {
          series.columns.xs.push(new Date(meas.timestamp.median));
          series.columns.ys.push(val);

          if (val !== null) {
            if (series.yMax === undefined || val > series.yMax) {
              series.yMax = val;
            }
            if (series.yMin === undefined || val < series.yMin) {
              series.yMin = val;
            }
          }
        }
      });
    }

    const maxDate = data.length
    result["timestamp"] = data[0].timestamp
    result["timestamp"]["max"] = data[maxDate-1].timestamp.max

    return result;
  } else {
    return {};
  }
};

export const prepareMultipleBar = (data, devices, variables, metric) => {
  if (data && data.length > 0) {
    let result = Object.assign(
      {},
      ...variables.map((varr) => ({
        [varr]: {
          ...Object.assign(
            {},
            ...devices.map(({ name }) => ({
              [name]: {
                name,
                type: bar(),
                columns: { ys: [name], xs: [`xs-${name}`] },
                yMax: undefined,
                yMin: undefined,
                xs: `xs-${name}`,
                metric,
              },
            }))
          ),
        },
      }))
    );

    const names = Object.assign(
      {},
      ...devices.map((dev) => ({ [dev.id]: dev.name }))
    );

    for (const meas of data) {
      const device_id = meas.device_id;

      variables.forEach((varr) => {
        let val = meas[varr] ? meas[varr] : null;
        const series = result[varr][names[device_id]];
        if (series) {
          series.columns.xs.push(
            Object.keys(meas.timestamp).map(
              (entry) => new Date(meas.timestamp[entry])
            )
          );
          series.columns.ys.push(val);
        }
        if (val !== null) {
          if (series.yMax === undefined || val > series.yMax) {
            series.yMax = val;
          }
          if (series.yMin === undefined || val < series.yMin) {
            series.yMin = val;
          }
        }
      });
    }

    return result;
  } else {
    return {};
  }
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  isFreezingTemp,
  calculateGaugePadding,
  getDefiniteMax,
  getDefiniteMin,
  getMaxAlertRule,
  getMinAlertRule,
  getMaxMeasurement,
  getMinMeasurement,
  generateGridLines,
  prepareAggregateLine,
  prepareMultipleBar,
  prepareMultipleLine,
  rulesToLines,
};
