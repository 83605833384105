import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import LocaleProvider from "../localization/LocaleProvider";
import { AuthContext, AuthProvider } from "./AuthContext";
import { SiteProvider } from "./SiteContext";
import { UserProvider } from "./UserContext";

const State = ({ children }) => (
  <>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <LocaleProvider>
          <AuthProvider>
            <AuthContext.Consumer>
              {({ isAuthenticated, loading }) =>
                loading ? null : isAuthenticated ? (
                  <UserProvider>
                    <SiteProvider>
                      {children}
                    </SiteProvider>
                  </UserProvider>
                ) : (
                  <>{children}</>
                )
              }
            </AuthContext.Consumer>
          </AuthProvider>
        </LocaleProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </>
);

export default State;
