import classNames from "classnames";
import React from "react";

const UnreadBadge = ({ className, count }) => (
  <div
    className={classNames(
      className,
      "font-medium rounded-sm w-4 h-4 sm:w-6 sm:h-6 flex items-center justify-center text-white",
      { "bg-red-500": count > 0 },
      { "bg-gray-500": count === undefined }
    )}
  >
    {count}
  </div>
);

export default UnreadBadge;
