import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-hub-white.png";
import { SiteContext } from "../state/SiteContext";
import { HeadingContainer, SiteHeading } from "./Headings";
import UserMenu from "./UserMenu";

const Header = forwardRef((props, ref) => {
  // Access the current site from the context
  const { currentSite } = useContext(SiteContext);

  // State to manage the header class based on the presence of the navigation element
  const [headerClass, setHeaderClass] = useState('flex-none');

  useEffect(() => {
    // Function to handle changes in the children of the ref
    const handleChildrenChange = () => {
      // Check if the navigation element with the id 'navigation' is present
      const hasNavigation = ref.current && ref.current.querySelector('#navigation');

      // Update the headerClass based on the presence of the navigation element
      if (hasNavigation) {
        setHeaderClass('flex-none mb-12 sm:mb-16 lg:mb-0');
      } else {
        setHeaderClass('flex-none');
      }
    };

    // Use MutationObserver to observe changes in the children of ref.current
    const observer = new MutationObserver(handleChildrenChange);
    observer.observe(ref.current, { childList: true, subtree: true });

    // Call the handler initially to set the initial state
    handleChildrenChange();

    // Clean up the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <header className={headerClass}>
      <HeadingContainer className="bg-gradient-r-primary shadow px-4 flex min-w-0 justify-between">
        <div className="flex min-w-0 items-center">
          <Link to="/" className="h-full w-32 flex flex-shrink-0 items-center">
            <img src={logo} alt="Soil Scout" />
          </Link>
          <Link
            className="text-white outline-none focus:underline whitespace-no-wrap overflow-hidden"
            to={currentSite ? `/sites/${currentSite.id}/` : "/"}
          >
            {currentSite && (
              <SiteHeading className="ml-4">{currentSite.name}</SiteHeading>
            )}
          </Link>
        </div>
        <div ref={ref} />
        <UserMenu />
      </HeadingContainer>
    </header>
  );
});

export default Header;
