import classNames from "classnames";
import React, { forwardRef, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import landing_image from "../assets/landing.jpg";
import Logo from "../assets/logo-hub.png";
import { AuthContext } from "../state/AuthContext";
import Card from "./Card";
import { Input } from "./FormWidgets";
import { LanguageContext } from "../localization/LocaleProvider";
import { IconFinlandFlag, IconUnitedKingdomFlag, IconSwedenFlag, IconSpainFlag, IconFranceFlag, IconItalyFlag } from "./Icons";

const LoginInput = forwardRef(({ ...rest }, ref) => (
  <input
    ref={ref}
    className="border-b border-gray p-2 text-md mt-12 leading-7 outline-none focus:shadow-outline"
    {...rest}
  />
));

const LoginMessage = ({ message, red, clear }) => (
  <div
    className={classNames(
      {
        "text-red-700 border-red-400 bg-red-100": red,
        "text-blue-700 border-blue-400 bg-blue-100": !red,
      },
      "flex items-center justify-between border rounded p-6 text-md mb-8"
    )}
    role="alert"
  >
    <p className="px-4">{message}</p>
    {clear && (
      <button type="button" onClick={() => clear()}>
        <CloseIcon className="fill-current " />
      </button>
    )}
  </div>
);

export default function LoginView() {
  const auth = useContext(AuthContext);
  const { url, path } = useRouteMatch();
  const [error, setError] = useState();

  const [resetResult, setResetResult] = useState();
  const [resetError, setResetError] = useState();

  const { register, handleSubmit, formState } = useForm();

  const { setSelectedLanguage } = useContext(LanguageContext);

  const onSubmit = ({ username, password }) => {
    if (!(username && password)) {
      return;
    }
    auth
      .login(username, password)
      .then()
      .catch((err) => {
        if ([400, 401].includes(err.status)) {
          setError(
            <FormattedMessage id="login.failure.pw" values={{ br: <br /> }} />
          );
        } else {
          setError(
            <FormattedMessage
              id="login.failure.unknown"
              values={{ br: <br /> }}
            />
          );
        }
      });
  };

  const onReset = ({ email }) => {
    auth
      .resetPassword(email)
      .then(() =>
        setResetResult(
          <FormattedMessage id="password_reset.sent" values={{ br: <br /> }} />
        )
      )
      .catch((err) => {
        if ([400, 401].includes(err.status)) {
          setResetError(
            <FormattedMessage
              id="password_reset.failure.request"
              values={{ br: <br /> }}
            />
          );
        } else {
          setResetError(
            <FormattedMessage
              id="login.failure.unknown"
              values={{ br: <br /> }}
            />
          );
        }
      });
  };

  if (auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  
  const changeLanguage = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <main
      style={{
        backgroundImage: "url(" + landing_image + ")",
      }}
      className="bg-cover h-full"
    >
      <div className="absolute top-0 right-0 flex items-center mt-5 mr-5">
        <span
          className="text-black p-2 cursor-pointer"
          onClick={() => changeLanguage("en")}
        >
          <IconUnitedKingdomFlag className="w-8 h-8 rounded-full border border-gray" />
        </span>
        <span
          className="text-black p-2 cursor-pointer"
          onClick={() => changeLanguage("fi")}
        >
          <IconFinlandFlag className="w-8 h-8 rounded-full border border-gray" />
        </span>
        <span
          className="text-black p-2 cursor-pointer"
          onClick={() => changeLanguage("sv")}
        >
          <IconSwedenFlag className="w-8 h-8 rounded-full border border-gray" />
        </span>
        <span
          className="text-black p-2 cursor-pointer"
          onClick={() => changeLanguage("es")}
        >
          <IconSpainFlag className="w-8 h-8 rounded-full border border-gray" />
        </span>
        <span
          className="text-black p-2 cursor-pointer"
          onClick={() => changeLanguage("fr")}
        >
          <IconFranceFlag className="w-8 h-8 rounded-full border border-gray" />
        </span>
        <span
          className="text-black p-2 cursor-pointer"
          onClick={() => changeLanguage("it")}
        >
          <IconItalyFlag className="w-8 h-8 rounded-full border border-gray" />
        </span>
      </div>

      <section className="h-full flex justify-center items-center">
        <Switch>
          <Route path={`${path}/resetPassword`}>
            <Card className="max-w-xl" 
              title={<FormattedMessage id="login.title.reset_password"/>}
            >
              <form onSubmit={handleSubmit(onReset)}>
                <label htmlFor="email-input" className="font-medium">
                  <FormattedMessage id="login.label.email"/>
                </label>
                <Input
                  id="email-input"
                  type="email"
                  name="email"
                  ref={register}
                  autoFocus
                />
                <div className="pt-4">
                  {resetResult ? (
                    <LoginMessage message={resetResult} />
                  ) : resetError ? (
                    <LoginMessage message={resetError} red />
                  ) : null}
                </div>
                <div className="w-full flex justify-between mt-4">
                  <Link to="/login" className="btn btn-blue-clear">
                    <FormattedMessage id="login.link.login"/>
                  </Link>
                  <button
                    disabled={!formState.isDirty}
                    className="btn btn-blue"
                    type="submit"
                  >
                    <FormattedMessage id="login.button.send_request"/>
                  </button>
                </div>
              </form>
            </Card>
          </Route>
          <Route>
            <Card
              className="max-w-xl"
              title= {<FormattedMessage id="login.title.login"/>}
              cornerWidget={<img src={Logo} alt="Soil Scout" draggable={false} />}
            >
              <div className="flex items-center justify-start hidden">
                <span
                  className="text-black p-2 cursor-pointer"
                  onClick={() => changeLanguage("en")}
                >
                  <IconUnitedKingdomFlag className="w-12 h-9 rounded-full border border-gray" />
                </span>
                <span
                  className="text-black p-2 cursor-pointer"
                  onClick={() => changeLanguage("fi")}
                >
                  <IconFinlandFlag className="w-12 h-9 rounded-md border border-gray" />
                </span>
                <span
                  className="text-black p-2 cursor-pointer"
                  onClick={() => changeLanguage("sv")}
                >
                  <IconSwedenFlag className="w-12 h-9 rounded-md border border-gray" />
                </span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col justify-center py-4 mb-8">
                  <FormattedMessage id="form.label.username">
                    {(message) => (
                      <LoginInput
                        name="username"
                        placeholder={message}
                        ref={register}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="form.label.password">
                    {(message) => (
                      <LoginInput
                        name="password"
                        type="password"
                        placeholder={message}
                        ref={register}
                      />
                    )}
                  </FormattedMessage>
                </div>
                {error && (
                  <LoginMessage
                    message={error}
                    clear={() => setError(null)}
                    red
                  />
                )}
                <button
                  className="btn btn-blue p-4 w-full"
                  title="Login"
                  type="submit"
                >
                  <FormattedMessage id="login.button.login"/>
                </button>
              </form>
              <div className="flex mt-4 items-center justify-between">
                <Link
                  to={`${url}/resetPassword`}
                  className="block text-scout-blue font-medium"
                >
                  <FormattedMessage id="password_reset.link" />
                </Link>
                <p
                  title={process.env.REACT_APP_BUILD_DATE}
                  className="text-gray-400 text-sm"
                >
                  {process.env.REACT_APP_VERSION}
                </p>
              </div>
            </Card>
          </Route>
        </Switch>
      </section>
    </main>
  );
}
