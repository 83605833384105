import React from 'react'
import { FormattedMessage } from "react-intl";

class NDMIControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startdate: '',
      enddate: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.searchClick = this.searchClick.bind(this);    
  }

  handleInputChange(event) {
    
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });  

  }

  searchClick(event){
    
    var ndviLayer = this.props.view.map.findLayerById('NDMI');
    var dateStart = this.state.startdate;
    var dateEnd = this.state.enddate;
    
    ndviLayer.definitionExpression = "acquisitiondate BETWEEN timestamp '" + dateStart + " 21:00:00' AND timestamp '" + dateEnd + " 20:59:59'";
    ndviLayer.refresh();
  }
    render() {
        const ndmi_widget_style = {
            
            backgroundColor: "white",
            padding: "10px",
            
          };
        return (
            <div style={ndmi_widget_style} className="ndmi-widget">
              <h2 className="text-lg">
                <FormattedMessage id="ndvi.title.search" />
              </h2>
              <div className="p-5">
                <FormattedMessage id="ndvi.label.earliest_date" /><br></br>
              <input type="date" id="ndmi_startdateControl" name="startdate" value={this.state.startdate} onChange={this.handleInputChange}></input>
              </div>
              <div className="p-5">
                <FormattedMessage id="ndvi.label.latest_date" /><br></br>
              <input type="date" id="ndmi_enddateControl" name="enddate" value={this.state.enddate} onChange={this.handleInputChange}></input>
              </div>
              <button className="btn bg-white shadow mr-2" onClick={this.searchClick}>
                <FormattedMessage id="ndvi.label.load_imagery" />
              </button>
            </div>
            )
    }
}

export default NDMIControl