import classNames from "classnames";
import React, { cloneElement } from "react";
import { Link, useHistory } from "react-router-dom";
import { HeadingContainer } from "./Headings";

export const TitleContainer = ({ children }) => (
  <div className="-ml-4 sm:-ml-8 h-full flex items-center min-w-0 overflow-hidden">
    {children}
  </div>
);

export const IconLink = ({ icon, to, ...rest }) => {
  const history = useHistory();

  const className = "group h-full w-16 inline-flex items-center justify-center";
  const iconElem = cloneElement(icon, {
    className: "mx-auto group-hover:text-scout-blue",
  });

  return to ? (
    <Link className={className} to={to} {...rest}>
      {iconElem}
    </Link>
  ) : (
    <button className={className} onClick={() => history.goBack()} {...rest}>
      {iconElem}
    </button>
  );
};

const DashHeader = ({ className, shadow, solid, span, children }) => (
  <HeadingContainer
    className={classNames(
      className,
      "border-b border-scout-gray flex justify-between items-center min-w-0",
      { "bg-white": solid },
      { "col-span-2": span },
      { shadow: shadow }
    )}
  >
    {children}
  </HeadingContainer>
);

export default DashHeader;
