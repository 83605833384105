import React, { useState,createContext } from "react";
import { IntlProvider } from "react-intl";
import messages from ".";


const defaultLanguage = () => {
  let lang = localStorage.getItem('language');
  if(lang){
    return lang;
  }
  return getBrowserLanguage();
}

const getBrowserLanguage = () => {
    if(navigator?.language){
      switch(navigator.language){
        case "en-GB":
        case "en-US":
            return "en";
        case "fi-FI":
        case "fi":
            return "fi";
        case "sv-FI":
        case "sv-SE":
        case "sv":
            return "sv";
        case "fr":
        case "fr-be":
        case "fr-lu":
        case "fr-ch":
        case "fr-ca":
            return "fr"
        case "it":
        case "it-ch":
            return "it"
        case "es":
            return "es"
        default:
            return "en";
      }
    }
    return "en";
}

export const LanguageContext = createContext();

export default function LocaleProvider({ children }) {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage());

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      <IntlProvider
        locale={selectedLanguage}
        messages={messages[selectedLanguage]}
        onError={(err) => {
          if (err.code === "MISSING_TRANSLATION" || err.code === "MISSING_DATA") {
            // In order to "temporarily" use error messages straight from the API
            console.error("Error: The locale is missing data or translation.");
            return;
          }
          console.error("Error: Unexpected locale error occurred.");
          return;
        }}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
      
  );
}
