import React from "react";
import { useHistory } from "react-router-dom";
import { DeviceContext } from "../state/DeviceContext";
import { DetailsContext, DetailsView } from "./DetailsView";
import { useIntl } from "react-intl";
import DeviceForm from "./DeviceForm";

const ScoutDetails = ({ site_id, linkTo, splitView }) => {
  const history = useHistory();
  const intl = useIntl();

  return (
    <DetailsView
      param="scout_id"
      context={DeviceContext}
      getTitle={(creating) => (creating ? intl.formatMessage({id: "scout_form.heading.add"}) : intl.formatMessage({id: "scout_form.heading.properties"}))}
      linkTo={linkTo}
      splitView={splitView}
    >
      <DetailsContext.Consumer>
        {({ entity: scout, creating, linkTo }) => (
          <DeviceForm
            device={scout}
            type="hydra"
            site_id={site_id}
            onSave={creating ? () => history.push(linkTo) : null}
          />
        )}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

export default ScoutDetails;
