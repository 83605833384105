import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import useLocalStorage from "../hooks/useLocalStorage";
import useResource from "../hooks/useResource";
import schemaFields from "../state/schema";

const SiteContext = createContext();

const emailsValidator = yup.array().when("enabled", {
  is: true,
  then: yup
    .array()
    .of(
      yup.object().shape({
        email: yup
          .string()
          .email("validation.email")
          .required("validation.required"),
      })
    )
    .required("validation.required"),
});

const triggerTimeValidator = yup.number().when("enabled", {
  is: true,
  then: yup
    .number()
    .nullable(false)
    .moreThan(0, "validation.positive")
    .integer("validation.integer")
    .required("validation.required")
    .transform((v) => (isNaN(v) ? 0 : v)),
  otherwise: yup
    .number()
    .nullable(false)
    .moreThan(0, "validation.positive")
    .transform((v) => (isNaN(v) ? 0 : v)),
});

const triggerValidator = yup.array().when("enabled", {
  is: true,
  then: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          trigger_type: yup.string().nullable(false),
          trigger_time: triggerTimeValidator,
        })
        .required("validation.required")
    )
    .required("validation.required"),
});

const SiteProvider = ({ children }) => {
  const [[{ entities: sites }], crud] = useResource("sites");
  const [lastSites, setLastSites] = useLocalStorage("last-sites", []);

  const match = useRouteMatch("/sites/:site_id/");
  const site_id = Number(match?.params.site_id);
  const currentSite = useMemo(
    () => (sites ? sites.find(({ id }) => id === site_id) : null),
    [sites, site_id]
  );

  const memoizedLastSites = useMemo(
    () => sites?.filter(({ id }) => lastSites.includes(id)),
    [sites, lastSites]
  );

  const validationSchema = useCallback(
    (site) =>
      yup.object().shape({
        site_name: schemaFields.uniqueString(sites, site, "name"),
        location: yup.object().shape({
          latitude: schemaFields.latitude,
          longitude: schemaFields.longitude,
        }),
        enabled: yup.boolean(),
        emails: emailsValidator,
        triggers: triggerValidator,
      }),
    [sites]
  );

  useEffect(() => {
    if (site_id) {
      setLastSites((prev) =>
        site_id !== prev[0]
          ? [...new Set([site_id, ...prev])].slice(0, 5)
          : prev
      );
    }
  }, [site_id, setLastSites]);

  return (
    <SiteContext.Provider
      value={{
        crud,
        sites,
        entities: sites,
        validationSchema,
        currentSite,
        memoizedLastSites: memoizedLastSites,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export { SiteContext, SiteProvider };
