import { format } from "date-fns";
import React, { useContext, useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { DeviceContext } from "../state/DeviceContext";
import { SiteContext } from "../state/SiteContext";

export default function ExportButton({
  className,
  scouts,
  tStart,
  tEnd,
  ...rest
}) {
  const { currentSite } = useContext(SiteContext);
  const { getMeasurementsCSV, getMeasurementsCSVStatus } = useContext(DeviceContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const pollingIntervalRef = useRef(2000); // Ref to store the polling interval
  const timeoutIdRef = useRef(null); // Ref to store the timeout ID without triggering renders

  const pollTaskStatus = async (taskId) => {
    const checkStatus = async () => {
      try {
        const data = await getMeasurementsCSVStatus(taskId);

        if (data.task_status === "SUCCESS") {
          handleDownload(data.csv_content);
          toast.dismiss("pending-toast");
          return;
        } else if (data.task_status === "FAILURE") {
          setIsProcessing(false);
          toast.dismiss("pending-toast");
          toast.error(<FormattedMessage id="error.csv_generation_failed" />);
          return;
        } else if (data.task_status === "PENDING") {
          if (!toast.isActive("pending-toast")) {
            toast.info(
              <div className="flex items-center space-x-2">
                <FormattedMessage id="info.csv_generation_pending" />
              </div>, 
              {
                toastId: "pending-toast",
                autoClose: false,
              }
            );
          }

          // Increase polling interval to double, capped at 60 seconds
          pollingIntervalRef.current = Math.min(pollingIntervalRef.current * 2, 60000);

          // Use the updated pollingIntervalRef for the next status check
          timeoutIdRef.current = setTimeout(checkStatus, pollingIntervalRef.current);
        }
      } catch (error) {
        console.error("Error fetching task status:", error);
        setIsProcessing(false);
        toast.dismiss("pending-toast");
        toast.error(<FormattedMessage id="error.generic" />);
      }
    };

    checkStatus();
  };

  const handleDownload = (csvContent) => {
    const decodedCsv = atob(csvContent);
    const blob = new Blob([decodedCsv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `${format(tEnd, "yyyy-MM-dd")}_soil-scout_export.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
    setIsProcessing(false);
    toast.success(<FormattedMessage id="success.csv_downloaded" />);
  };

  const handleClick = async () => {
    document.body.style.cursor = "wait";
    setIsProcessing(true);
    pollingIntervalRef.current = 2000; // Reset interval to 5 seconds on new task

    try {
      const data = await getMeasurementsCSV(scouts, {
        site_id: currentSite.id,
        since: tStart.toISOString(),
        until: tEnd.toISOString(),
      });

      if (data && data.task_id) {
        toast.info(<FormattedMessage id="info.csv_generation_pending" />, {
          toastId: "pending-toast",
          autoClose: false,
        });
        pollTaskStatus(data.task_id);
      } else {
        toast.warning(<FormattedMessage id="error.no_measurements" />);
        setIsProcessing(false);
      }
    } catch (error) {
      console.error("Error fetching CSV generation:", error);
      setIsProcessing(false);
      toast.error(<FormattedMessage id="error.csv_generation_failed" />);
      document.body.style.cursor = "default";
    } finally {
      document.body.style.cursor = "default";
    }
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return (
    <button
      className={className}
      onClick={handleClick}
      disabled={isProcessing}
      {...rest}
    >
      <FormattedMessage id="action.export_csv" />
    </button>
  );
}
