import {
  differenceInDays,
  format,
  isSameDay,
  parseISO,
  subDays,
  subHours,
} from "date-fns";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { FiClock } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { FormattedDatetime } from "./../localization";
import { Input, PlainSelect } from "./FormWidgets";
import Popup, { PopupContext } from "./Popup";
import Spinner from "./Spinner";

const dateFormat = "yyyy-MM-dd";

const p = (date) => parseISO(date);
const f = (date) => format(date, dateFormat);

const toString = ({ from, to, preset }) => ({
  from: f(from),
  to: f(to),
  preset,
});

const toDate = ({ from, to }) => ({
  from: p(from),
  to: p(to),
});

const adjust = (diff) => {
  const now = new Date();
  return { from: f(subDays(now, diff)), to: f(now) };
};

const opts = (now) => [
  {
    value: subHours(now, 24),
    label: "timespan.last_24h",
    days: 1,
  },
  {
    value: subDays(now, 7),
    label: "timespan.last_week",
    days: 7,
  },
  {
    value: subDays(now, 31),
    label: "timespan.last_month",
    days: 31,
  },
  {
    value: subDays(now, 90),
    label: "timespan.last_three_months",
    days: 90,
  },
  {
    value: subDays(now, 180),
    label: "timespan.last_six_months",
    days: 180,
  },
  {
    value: subDays(now, 365),
    label: "timespan.last_year",
    days: 365,
  },
  {
    value: subDays(now, 730),
    label: "timespan.last_two_years",
    days: 730,
  },
];

const isNice = (from, to, now) =>
  isSameDay(to, now) && from <= to
    ? opts(now).find(({ value }) => {
        return differenceInDays(value, now) === differenceInDays(from, now);
      })?.label ?? false
    : false;

const NiceTimespan = ({ from, to, preset }) => {
  const label = opts().find(({ days }) => differenceInDays(to, from) === days)
    ?.label;

  return (
    <span className="ml-2 hidden sm:inline-flex">
      {label && preset > 0 ? (
        <FormattedMessage id={label} />
      ) : (
        <>
          <FormattedDatetime value={from} />
          <span>—</span>
          <FormattedDatetime value={to} />
        </>
      )}
    </span>
  );
};

const DateTimeForm = ({ defaultValues, onSubmit }) => {
  const formRef = useRef();
  const { hide } = useContext(PopupContext);
  const { register, handleSubmit, setValue, watch, reset, getValues } = useForm(
    {
      defaultValues: defaultValues
        ? toString(defaultValues)
        : adjust(opts[0].value),
    }
  );
  const now = new Date();
  const options = opts(now);
  const start = watch("from");
  const end = watch("to");

  const transform = (data, evt) => {
    if (evt) {
      evt.stopPropagation();
    }
    hide();
    setTimeout(
      async () =>
        onSubmit(data?.preset ? data : { ...toDate(data), preset: -1 }),
      250
    );
  };

  const defString = JSON.stringify(defaultValues);
  useEffect(() => {
    reset(toString(toDate(JSON.parse(defString))));
  }, [defString, reset]);
  return (
    <form ref={formRef} onSubmit={handleSubmit(transform)}>
      <label htmlFor="dts-preset" className="font-medium">
        <FormattedMessage id="date.presets" />
      </label>
      <div className="w-full relative">
        <PlainSelect
          id="dts-preset"
          value={isNice(p(start), p(end), now) ?? "0"}
          onChange={(evt) => {
            const val = evt.target.value;
            let option = options.find(({ label }) => label === val);
            if (val !== "0") {
              setValue("to", f(now));
              setValue("from", f(option.value));
            }
            transform(
              option
                ? {
                    to: now,
                    from: subDays(now, option.days),
                    preset: option.days,
                  }
                : getValues()
            );
          }}
        >
          {[].concat(
            options.map((option, idx) => (
              <FormattedMessage key={option.label} id={option.label}>
                {(message) => <option value={option.label}>{message}</option>}
              </FormattedMessage>
            ))
          )}
        </PlainSelect>
      </div>
      <div className="mt-6">
        <label htmlFor="dts-start">
          <FormattedMessage id="date.from" />
        </label>
        <Input
          type="date"
          id="dts-start"
          name="from"
          min={"2010-01-01"}
          max={end}
          ref={register()}
        />
      </div>
      <div className="mt-2">
        <label htmlFor="dts-end">
          <FormattedMessage id="date.to" />
        </label>
        <Input
          type="date"
          id="dts-end"
          name="to"
          min={start}
          max={f(now)}
          ref={register()}
        />
      </div>
      <button type="submit" className="mt-4 w-full btn btn-blue">
        <FormattedMessage id="button.timerange-apply" />
      </button>
    </form>
  );
};

const DateTimeSelector = forwardRef(({ from, to, preset, onSubmit }, ref) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const [loading, setLoading] = useState(false);

  if (ref) {
    ref.current = { loading, setLoading };
  }

  return (
    <Popup
      className="px-4 py-6 w-64"
      trigger={
        <button
          className="btn btn-blue flex justify-center items-center"
          onClick={visible ? hide : show}
        >
          {loading ? (
            <Spinner size={24} />
          ) : (
            <>
              <FiClock className="stroke-2 text-white" size={18} />
              <NiceTimespan from={from} to={to} preset={preset} />
            </>
          )}
        </button>
      }
    >
      <DateTimeForm
        {...(from && to ? { defaultValues: { from, to, preset } } : {})}
        onSubmit={onSubmit}
      />
    </Popup>
  );
});

export default DateTimeSelector;
