import React, { useContext } from "react";
import { DeviceContext } from "../state/DeviceContext";
import DeviceGroupContext from "../state/DeviceGroupContext";
import { UserContext } from "../state/UserContext";
import Form from "./Form";
import { DeviceSelect, Input, LabeledWidget } from "./FormWidgets";

const GroupForm = ({ group, site_id, onSave }) => {
  const {
    crud: { list: fetchDevices },
  } = useContext(DeviceContext);
  const { currentUser } = useContext(UserContext);
  return (
    <Form
      context={DeviceGroupContext}
      defaultValues={{ devices: [], ...group }}
      transform={(formValues) => ({
        ...group,
        ...formValues,
        site: site_id,
        devices: formValues.devices ? formValues.devices : [],
      })}
      onSave={() => {
        fetchDevices();
        onSave();
      }}
      readOnly={currentUser.read_only}
    >
      <LabeledWidget
        name="name"
        labelId="form.label.group_name"
        widget={<Input />}
      />
      <DeviceSelect name="devices" labelId="form.label.devices" />
    </Form>
  );
};

export default GroupForm;
