import React, { createContext, useContext, useCallback, useState } from "react";
import { SiteContext } from "./SiteContext";
import useResource from "../hooks/useResource";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { deepCopy, generateRandomId } from "../utility/common";
import { toast } from "react-toastify";

const AnalysisContext = createContext();

const AnalysisProvider = ({ children }) => {
  const { currentSite } = useContext(SiteContext);
  const [updatedCharts, setUpdatedCharts] = useState(new Map());
  const [[{ entities: analyses, loading }], crud] = useResource("analyses", {
    site: currentSite.id,
  });

  const [duration, setDuration] = useState();

  const handleDuration = useCallback(
    (newDuration) => setDuration(newDuration),
    [setDuration]
  );

  const validationSchema = useCallback(
    () =>
      yup.object().shape({
        duration: yup.number().min(0).required("validation.required"),
        name: yup
          .string()
          .required("validation.required")
          .meta({ label: "Analysis name" }),
        site: yup
          .number()
          .required("validation.required")
          .min(0)
          .meta({ label: "Site id" }),
        charts: yup.array().of(
          yup.object().shape({
            id: yup.string().required("validation.required"),
            type: yup
              .string()
              .required("validation.required")
              .meta({ label: "Analysis name" }),
            sources: yup.array().of(
              yup.object().shape({
                id: yup.number().required("validation.required"),
                name: yup
                  .string()
                  .required("validation.required")
                  .meta({ label: "Analysis name" }),
                data_type: yup
                  .string()
                  .required("validation.required")
                  .meta({ label: "Metric type" }),
                start_date: yup.date(),
              })
            ),
          })
        ),
      }),
    []
  );

  const handleAsyncError = (err) => {
    console.error("Analysis error:", err);
    toast.error(<FormattedMessage id={"error.server_communication"} />);
  };

  const handleResponse = (response) => {
    toast.success(<FormattedMessage id={"analysis.toast.saved"} />);
    setUpdatedCharts(new Map());
  };

  const updateCharts = useCallback(
    (analysisKey, charts, sources) => {
      // Clear updatedCharts if no key is given
      if (!analysisKey) {
        setUpdatedCharts(new Map());
        return;
      }

      const type = updatedCharts.get(analysisKey)?.type || charts.type;
      const generatedId =
        updatedCharts.get(analysisKey)?.generatedId || charts.id;

        // FIXME: Is there a smoother way to prevent Modal from re-rendering
        // while still creating a new Map object?
      setUpdatedCharts((prev) =>
        prev.set(analysisKey, { type, generatedId, sources })
      );
    },
    [updatedCharts]
  );

  const getUpdatedAnalysis = useCallback(
    (analysis) => {
      let currentAnalysis = deepCopy(analysis);

      currentAnalysis.updated = new Date().toISOString();
      if (duration) {
        currentAnalysis.duration = duration;
      }

      const keys = [...updatedCharts.keys()].filter(
        (key) => key === currentAnalysis.id.toString()
      );

      if (keys && keys.length > 0) {
        const preparedUpdatedChart = keys.map((key) => ({
          id: updatedCharts.get(key).generatedId,
          type: updatedCharts.get(key).type,
          sources: updatedCharts
            .get(key)
            .sources.map(({ min, max, avg, ...keepAttrs }) => keepAttrs),
        }));

        currentAnalysis.charts = preparedUpdatedChart;
      }

      return currentAnalysis;
    },
    [duration, updatedCharts]
  );

  const saveAnalysis = useCallback(
    (analysis) => {
      const updatedAnalysis = getUpdatedAnalysis(analysis);

      validationSchema()
        .validate(updatedAnalysis)
        .then(crud.update)
        .then(handleResponse)
        .catch(handleAsyncError);
    },
    [crud, validationSchema, getUpdatedAnalysis]
  );

  const getAnalysisAsCopy = (id) => {
    const found = analyses.find((analysis) => analysis.id === id);

    if (found) {
      const tmp = getUpdatedAnalysis(found);
      tmp?.charts?.forEach((chart) => (chart.id = generateRandomId()));

      return tmp;
    } else {
      return undefined;
    }
  };

  return (
    <AnalysisContext.Provider
      value={{
        entities: analyses,
        crud,
        updatedCharts,
        updateCharts,
        validationSchema,
        saveAnalysis,
        getAnalysisAsCopy,
        loading,
        durationHandler: { duration, handleDuration },
      }}
    >
      {children}
    </AnalysisContext.Provider>
  );
};

export default AnalysisContext;
export { AnalysisProvider };
