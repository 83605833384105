import React from "react";

import CellSignal from "./CellSignal";
import ConnectionStatus from "./ConnectionStatus";
import Gauge from "./Gauge";
import Card from "./Card";
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from "react-intl";
import {
  CapitalizedMessage,
  FormattedVoltage,
  FormattedPacketsPerHour,
} from "../localization";

export const BaseOverview = ({ base }) => {
  const {
    voltage_external,
    voltage_battery,
    last_seen,
    last_modem_status,
    packets_per_hour,
    device_type,
  } = base;

  return (
    <FormattedMessage id="dash_card.scout_overview">
      {(message) => (
        <Card heading={message}>
          <div className="-mb-4 items-center grid sm:grid-cols-form row-gap-3 col-gap-3">
            <CapitalizedMessage
              className="font-medium"
              id="measurement.voltage_external"
            />
            <Gauge
              className="bg-gradient-l-voltage"
              value={voltage_external}
              measuredVariable={"voltage_external"}
              big
              deviceType={device_type}
            />
            <CapitalizedMessage
              className="font-medium"
              id="measurement.voltage_battery"
            />
            <Gauge
              className="bg-gradient-l-voltage"
              value={voltage_battery}
              measuredVariable={"voltage_battery"}
              big
              deviceType={device_type}
            />
            <hr className="sm:col-span-2 -mx-10" />
            <CapitalizedMessage
              className="font-medium"
              id="device_table.header.status"
            />
            <div className="flex justify-between items-center">
              <ConnectionStatus device={base} withText />
              <p className="text-gray-600 text-sm">
                <CellSignal status={last_modem_status} />
                {voltage_battery ? (
                  <>
                    , Battery <FormattedVoltage value={voltage_battery} /> V
                  </>
                ) : null}
              </p>
              <div className="flex-col">
                <p className="text-gray-600 text-sm">
                  <FormattedMessage id="connection_status.last_update" />{" "}
                  {last_seen ? (
                    <>
                      <FormattedDate value={last_seen} />{" "}
                      <FormattedTime value={last_seen} />
                    </>
                  ) : (
                    "never"
                  )}
                </p>
                {packets_per_hour && (
                  <p className="text-gray-600 text-sm">
                    <FormattedMessage id="measurement.packets_per_hour" />:{" "}
                    <FormattedPacketsPerHour value={packets_per_hour} />
                  </p>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </FormattedMessage>
  );
};

export const EchoOverview = ({ echo }) => {
  const intl = useIntl();
  const {
    voltage_external,
    voltage_battery,
    last_seen,
    packets_per_hour,
    device_type,
  } = echo;

  const charging =
    voltage_external > voltage_battery && voltage_battery < 4.1
      ? intl.formatMessage({id:'receiver_overview.label.charging'})
      : voltage_battery >= 4.1
      ? intl.formatMessage({id:'receiver_overview.label.fully_charged'})
      : intl.formatMessage({id:'receiver_overview.label.discharging'});

  return (
    <FormattedMessage id="dash_card.scout_overview">
      {(message) => (
        <Card heading={message}>
          <div className="-mb-4 items-center grid sm:grid-cols-form row-gap-3 col-gap-3">
            <CapitalizedMessage
              className="font-medium"
              id="measurement.voltage_external"
            />
            <Gauge
              className="bg-gradient-l-voltage"
              value={voltage_external}
              measuredVariable={"voltage_external"}
              big
              deviceType={device_type}
            />
            <CapitalizedMessage
              className="font-medium"
              id="measurement.voltage_battery"
            />
            <Gauge
              className="bg-gradient-l-voltage"
              value={voltage_battery}
              measuredVariable={"voltage_battery"}
              big
              deviceType={device_type}
            />
            <hr className="sm:col-span-2 -mx-8" />
            <CapitalizedMessage
              className="font-medium"
              id="device_table.header.status"
            />
            <div className="flex justify-between items-center">
              <ConnectionStatus device={echo} withText />
              {voltage_battery && (
                <FormattedVoltage value={voltage_external}>
                  {([val, unit]) => (
                    <p
                      className="text-gray-600 text-sm"
                      title={`Input ${val} ${unit}`}
                    >
                      {charging}
                    </p>
                  )}
                </FormattedVoltage>
              )}
              <div className="flex-col">
                <p className="text-gray-600 text-sm">
                  <FormattedMessage id="connection_status.last_update" />{" "}
                  {last_seen ? (
                    <>
                      <FormattedDate value={last_seen} />{" "}
                      <FormattedTime value={last_seen} />
                    </>
                  ) : (
                    "never"
                  )}
                </p>
                {packets_per_hour && (
                  <p className="text-gray-600 text-sm">
                    <FormattedMessage id="measurement.packets_per_hour" />:{" "}
                    <FormattedPacketsPerHour value={packets_per_hour} />
                  </p>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </FormattedMessage>
  );
};
