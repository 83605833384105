import classNames from "classnames";
import React, { forwardRef } from "react";
import useModal from "../hooks/useModal";
import { IconClose, IconMore } from "./Icons";
import Popup from "./Popup";

const Card = ({
  className,
  heading,
  menu,
  modal,
  title,
  action,
  cornerWidget,
  children,
}) => {
  const [Modal, toggleModal] = useModal();
  const MoreButton = forwardRef(({ ...rest }, ref) => (
    <button
      className="absolute right-0 top-0 mt-2 mr-4 text-scout-gray-dark"
      ref={ref}
      {...rest}
    >
      <IconMore />
    </button>
  ));

  return (
    <div
      className={classNames(
        className,
        "p-8 bg-white rounded-lg w-full shadow-indigo-lg",
        { relative: modal || menu }
      )}
    >
      {heading && (
        <div className="flex -mt-8 h-12 justify-center items-center">
          <p className="inline-block mx-auto font-bold text-lg"> {heading}</p>
          {menu && <Popup trigger={<MoreButton />}>{menu}</Popup>}
          {modal && (
            <>
              <Modal>
                <Card>{modal}</Card>
              </Modal>
              <MoreButton onClick={() => toggleModal(true)} />
            </>
          )}
        </div>
      )}
      {title && (
        <>
          <div className="mb-8 flex items-center justify-between">
            <p className="text-lg font-bold">{title}</p>
            {cornerWidget ? (
              cornerWidget
            ) : action ? (
              <button onClick={action}>
                <IconClose />
              </button>
            ) : null}
          </div>
          <hr className="-mx-8 mb-8" />
        </>
      )}
      {children}
    </div>
  );
};

export default Card;
