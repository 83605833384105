import {
  format,
  parseISO,
  subDays,
  subHours,
  add,
  subMonths,
  subYears,
  startOfDay,
} from "date-fns";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { FormattedDatetime } from "../localization";
import { Input } from "./FormWidgets";
import Popup, { PopupContext } from "./Popup";
import Spinner from "./Spinner";

const dateFormat = "yyyy-MM-dd";

const p = (date) => parseISO(date);
const f = (date) => format(date, dateFormat);

const toString = ({ startDate }) => ({
  startDate: f(startDate),
});

const toDate = ({ startDate }) => ({
  startDate: p(startDate),
});

const adjust = (diff) => {
  const now = new Date();
  return { startDate: f(subDays(now, diff)) };
};

const opts = (now) => [
  {
    value: subHours(now, 24),
    label: "timespan.last_24h",
  },
  {
    value: subDays(now, 7),
    label: "timespan.last_week",
  },
  { value: subDays(now, 31), label: "timespan.last_month" },
  {
    value: subMonths(now, 3),
    label: "timespan.last_three_months",
  },
  {
    value: subMonths(now, 6),
    label: "timespan.last_six_months",
  },
  {
    value: subYears(now, 1),
    label: "timespan.last_year",
  },
  {
    value: subYears(now, 2),
    label: "timespan.last_two_years",
  },
];

const DateTimeForm = ({ defaultValues, onSubmit }) => {
  const formRef = useRef();
  const { hide } = useContext(PopupContext);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues
      ? toString(defaultValues)
      : adjust(opts[0].value),
  });
  const now = new Date();

  const transform = (data, evt) => {
    let result;
    if (evt) {
      evt.stopPropagation();
      const tmp = startOfDay(new Date(data.startDate));
      const hours = data.startTime.split(":")[0]
        ? data.startTime.split(":")[0]
        : "";
      const minutes = data.startTime.split(":")[1]
        ? data.startTime.split(":")[1]
        : "";
      result = add(tmp, { hours, minutes });
    }
    hide();
    setTimeout(async () => onSubmit(result), 250);
  };

  const defString = JSON.stringify(defaultValues);
  useEffect(() => {
    reset(toString(toDate(JSON.parse(defString))));
  }, [defString, reset]);
  return (
    <form ref={formRef} onSubmit={handleSubmit(transform)}>
      <div className="mt-0">
        <label htmlFor="dts-date">
          <FormattedMessage id="date.start_date" />
        </label>
        <Input
          type="date"
          id="dts-date"
          name="startDate"
          min={"2010-01-01"}
          max={f(now)}
          ref={register()}
        />
      </div>
      <div className="mt-2">
        <label htmlFor="dts-time">
          <FormattedMessage id="date.start_time" />
        </label>
        <Input type="time" id="dts-time" name="startTime" ref={register()} />
      </div>
      <button type="submit" className="mt-4 w-full btn btn-blue">
        <FormattedMessage id="button.apply" />
      </button>
    </form>
  );
};

const StartTimeSelector = forwardRef(
  ({ startDate, onSubmit, displayValue }, ref) => {
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const [loading, setLoading] = useState(false);

    if (ref) {
      ref.current = { loading, setLoading };
    }

    return (
      <Popup
        className="px-4 py-6 w-64"
        trigger={
          <div onClick={visible ? hide : show}>
            {loading ? (
              <Spinner size={24} />
            ) : (
              <div className="cursor-pointer">
                <FormattedDatetime value={displayValue} />
              </div>
            )}
          </div>
        }
      >
        <DateTimeForm
          {...(startDate ? { defaultValues: { startDate } } : {})}
          onSubmit={onSubmit}
        />
      </Popup>
    );
  }
);

export default StartTimeSelector;
