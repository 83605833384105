import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";
import "./App.css";
import App from "./components/App";

setUseWhatChange(process.env.NODE_ENV === "development")

ReactDOM.render(<App />, document.getElementById("root"));