import React, { useContext, useRef } from "react";
import { FormattedMessage } from "react-intl";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { UserContext } from "../../state/UserContext";
import DashContent from "./../DashContent";
import DashHeader, { IconLink, TitleContainer } from "./../DashHeader";
import { SectionHeading } from "./../Headings";
import { IconEdit, IconLeft } from "./../Icons";
import ResponsiveSwitchView from "./../ResponsiveSwitchView";
import AnalysisList from "./AnalysisList";
import AnalysisDetails from "./AnalysisDetails";
import AnalysisContent from "./AnalysisContent";
import AnalysisContext from "../../state/AnalysisContext";
import DurationSelector from "../DurationSelector";
import Spinner from "../Spinner";
import SaveAsButton from "./SaveAsButton";

export const DEFAULT_DURATION = 365;

export default function AnalysisView({ site }) {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const {
    entities: analyses,
    saveAnalysis,
    loading,
    durationHandler,
  } = useContext(AnalysisContext);
  const { currentUser } = useContext(UserContext);
  const durationPickerRef = useRef();
  const saveAsButtonRef = useRef();

  let duration = durationHandler.duration ?? DEFAULT_DURATION;

  const sidebar = (
    <>
      <DashHeader shadow solid>
        <SectionHeading>
          <FormattedMessage id="views.analysis_view.heading" />
        </SectionHeading>
        {!currentUser.read_only && (
          <Link className="btn btn-green" to={`${url}/edit/`}>
            <FormattedMessage id="button.action.new_analysis" />
          </Link>
        )}
      </DashHeader>
      <DashContent shadow solid>
        <AnalysisList
          editRow={(obj) => history.push(`${url}/edit/${obj.id}`)}
          linkTo={(obj) => `${url}/view/${obj.id}`}
        />
      </DashContent>
    </>
  );

  const initial = (
    <>
      <DashHeader>
        <SectionHeading>
          <FormattedMessage id="views.analysis_view.heading" />
        </SectionHeading>
      </DashHeader>
      <DashContent>
        <br />
        <span>
          <FormattedMessage id="analysis.no_content" />
        </span>
      </DashContent>
    </>
  );

  return (
    <ResponsiveSwitchView sidebar={sidebar} initial={initial}>
      <Route path={[`${path}/edit/:id/`, `${path}/edit/`]}>
        <AnalysisDetails site_id={site?.id} linkTo={url} />
      </Route>
      <Route path={`${path}/view/:id`}>
        {({ match }) => {
          const currentAnalysis = analyses?.find(
            ({ id }) => Number(match.params?.id) === id
          );

          if (currentAnalysis) {
            duration =
              durationHandler.duration ??
              currentAnalysis.duration ??
              DEFAULT_DURATION;
          }

          return !analyses ? null : currentAnalysis ? (
            <>
              <Switch>
                <Route>
                  <DashHeader>
                    <TitleContainer>
                      <IconLink to={url} icon={<IconLeft />} />
                      <SectionHeading>{currentAnalysis.name}</SectionHeading>
                      {!currentUser.read_only && (
                        <IconLink
                          to={`${url}edit/${currentAnalysis.id}`}
                          icon={<IconEdit />}
                        />
                      )}
                    </TitleContainer>
                    <div className="flex flex-row">
                      <DurationSelector
                        ref={durationPickerRef}
                        duration={duration}
                        onSubmit={(durationQuery) => {
                          durationHandler.handleDuration(
                            durationQuery.duration
                          );
                        }}
                      />
                      <SaveAsButton
                        ref={saveAsButtonRef}
                        className="mx-2"
                        analysis={currentAnalysis}
                      />
                      <button
                        className="btn btn-blue flex justify-center items-center mr-2"
                        onClick={() => {
                          saveAnalysis(currentAnalysis);
                        }}
                      >
                        {loading ? (
                          <Spinner size={24} />
                        ) : (
                          <FormattedMessage id="analysis.save" />
                        )}
                      </button>
                    </div>
                  </DashHeader>
                  <DashContent className="py-4 px-4 sm:px-8 flex-col" grid>
                    <AnalysisContent
                      analysis={currentAnalysis}
                      duration={duration}
                      durationPickerRef={durationPickerRef}
                    />
                  </DashContent>
                </Route>
              </Switch>
            </>
          ) : (
            <Redirect to={url} />
          );
        }}
      </Route>
    </ResponsiveSwitchView>
  );
}
