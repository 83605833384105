import React from 'react';
const Alert = ({ message, infoLevel }) => {
    return (
        <div className="flex items-center justify-center px-4 lg:px-0" >
            <div id="alert" className="lg:w-11/12 py-3 px-4 bg-gray-100 md:flex items-center justify-between shadow-md rounded">
                <div className="sm:flex sm:items-start lg:items-center w-full">
                    <div className="flex items-end">
                        <div className="mr-2 text-yellow-400">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} fill="currentColor">
                                <path className="heroicon-ui" d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>
                        </div>
                        <p className="mr-4 text-base font-bold text-gray-800 dark:text-gray-100">
                            {infoLevel}
                        </p>
                    </div>
                    <div className="h-1 w-1 bg-gray-300 dark:bg-gray-700 rounded-full mr-2 hidden xl:block" />
                    <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 pt-2 pb-2 text-center w-full">
                        { message }
                    </p>
                </div>
            </div>
        </div>
    )
};
export default Alert;