import React from "react";
import { FiInfo } from "react-icons/fi";
import { ReactComponent as AlertCircle } from "../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangle } from "../assets/icons/alert-triangle.svg";
import { ReactComponent as Left } from "../assets/icons/arrow.svg";
import { ReactComponent as BellBig } from "../assets/icons/bell-big.svg";
import { ReactComponent as BellOff } from "../assets/icons/bell-off.svg";
import { ReactComponent as Bell } from "../assets/icons/bell.svg";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { ReactComponent as Chevron } from "../assets/icons/chevron.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as Edit } from "../assets/icons/edit.svg";
import { ReactComponent as Funnel } from "../assets/icons/filter.svg";
import { ReactComponent as Grid } from "../assets/icons/grid.svg";
import { ReactComponent as Hourglass } from "../assets/icons/hourglass.svg";
import { ReactComponent as List } from "../assets/icons/list.svg";
import { ReactComponent as More } from "../assets/icons/more-horizontal.svg";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import { ReactComponent as Slash } from "../assets/icons/slash.svg";
import { ReactComponent as Shield } from "../assets/icons/soil-scout-shield.svg";
import { ReactComponent as NumericAsc } from "../assets/icons/sort-1-9.svg";
import { ReactComponent as NumericDesc } from "../assets/icons/sort-9-1.svg";
import { ReactComponent as AlphaAsc } from "../assets/icons/sort-a-z.svg";
import { ReactComponent as AmountAsc } from "../assets/icons/sort-amount-asc.svg";
import { ReactComponent as AmountDesc } from "../assets/icons/sort-amount-desc.svg";
import { ReactComponent as AlphaDesc } from "../assets/icons/sort-z-a.svg";
import { ReactComponent as AnalyticsStudioIcon } from "../assets/icons/analytics-studio.svg";
import { ReactComponent as FinlandFlag } from "../assets/icons/fi-flag.svg";
import { ReactComponent as UnitedKingdomFlag } from "../assets/icons/gb-flag.svg";
import { ReactComponent as SwedenFlag } from "../assets/icons/se-flag.svg";
import { ReactComponent as SpainFlag } from "../assets/icons/es-flag.svg";
import { ReactComponent as FranceFlag } from "../assets/icons/fr-flag.svg";
import { ReactComponent as ItalyFlag } from "../assets/icons/it-flag.svg";

const iconProps = (className, base = "fill-current") => ({
  className: base + (className ? " " + className : ""),
});

const iconComp = (Icon) => ({ className, ...rest }) => (
  <Icon {...iconProps(className)} {...rest} />
);

export const IconClose = iconComp(Close);
export const IconChevron = iconComp(Chevron);
export const IconMore = iconComp(More);
export const IconCheck = iconComp(Check);
export const SortAlphaDesc = iconComp(AlphaDesc);
export const SortAlphaAsc = iconComp(AlphaAsc);
export const SortNumericDesc = iconComp(NumericDesc);
export const SortNumericAsc = iconComp(NumericAsc);
export const SortAmountDesc = iconComp(AmountDesc);
export const SortAmountAsc = iconComp(AmountAsc);
export const IconEdit = iconComp(Edit);
export const IconSlash = iconComp(Slash);
export const IconList = iconComp(List);
export const IconGrid = iconComp(Grid);
export const IconLeft = iconComp(Left);
export const IconSearch = iconComp(Search);
export const IconShield = iconComp(Shield);
export const IconAlertCircle = iconComp(AlertCircle);
export const IconHourglass = iconComp(Hourglass);
export const IconAlertTriangle = iconComp(AlertTriangle);
export const IconBellBig = iconComp(BellBig);
export const IconBell = iconComp(Bell);
export const IconBellOff = iconComp(BellOff);
export const IconFunnel = iconComp(Funnel);
export const IconInfo = ({ className }) => (
  <FiInfo className={className + " stroke-2"} size={24} />
);
export const IconAnalyticsStudio = iconComp(AnalyticsStudioIcon);
export const IconFinlandFlag = iconComp(FinlandFlag);
export const IconUnitedKingdomFlag = iconComp(UnitedKingdomFlag);
export const IconSwedenFlag = iconComp(SwedenFlag);
export const IconSpainFlag = iconComp(SpainFlag);
export const IconFranceFlag = iconComp(FranceFlag);
export const IconItalyFlag = iconComp(ItalyFlag);
