import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import { DetailsContext, DetailsView } from "./DetailsView";
import { UseConsentCheckbox } from "./FormWidgets";
import { useHistory } from "react-router-dom";
import SiteForm from "./SiteForm";

export const DeleteSiteConsent = () => {
  const [devicesToggled, devicesCheckbox] = UseConsentCheckbox("devices");
  const [measurementsToggled, measurementsCheckbox] = UseConsentCheckbox(
    "measurements"
    );

    return [
      devicesToggled & measurementsToggled,
      <>
      <div className='mt-6 mb-6'>
        <div className='mt-2'>
          <FormattedMessage id='consent.i_consent' />
        </div>
        <div className='mt-2 space-y-1'>
          <div className='flex flex-row flex-no-wrap space-x-2'>
            <div>{devicesCheckbox}</div>
            <div>
              <FormattedMessage id='consent.site_delete_devices' />
            </div>
          </div>
          <div className='flex flex-row flex-no-wrap space-x-2'>
            <div>{measurementsCheckbox}</div>
            <div>
              <FormattedMessage id='consent.site_delete_measurements' />
            </div>
          </div>
        </div>
      </div>
    </>,
  ];
};

const SiteDetails = ({ linkTo, onSave }) => {
  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const intl = useIntl();
  
  return (
    <DetailsView
      param='site_id'
      context={SiteContext}
      getTitle={(creating) => (creating ? intl.formatMessage({ id: "site_settings.title.add_site"}) : intl.formatMessage({ id: "site_settings.title.site_settings" }))}
      linkTo={linkTo}
      onDelete={() => history.push("/sites")}
      withDelete={currentUser.is_superuser}
      consentPrompt={currentUser.is_superuser && DeleteSiteConsent()}
    >
      <DetailsContext.Consumer>
        {({ entity: site }) => <SiteForm site={site} onSave={onSave} />}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

export default SiteDetails;
