import React, { cloneElement, useContext } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedTime,
} from "react-intl";
import { UserContext } from "../state/UserContext";
import en_US from "./en-US.json";
import fi_FI from "./fi-FI.json";
import sv_SV from "./sv-SV.json";
import french from "./french.json";
import italian from "./italian.json";
import spanish from "./spanish.json";

const messages = { 
  "en": en_US,
  "fi": fi_FI,
  "sv": sv_SV,
  "fr": french,
  "it": italian,
  "es": spanish 
};

export default messages;

export const CapitalizedMessage = (props) => (
  <span className={props.className} style={{ textTransform: "capitalize" }}>
    <FormattedMessage {...props} />
  </span>
);

export const FormattedDatetime = ({ value, children }) =>
  children ? (
    <FormattedDate value={value}>
      {(date) => (
        <FormattedTime value={value}>
          {(time) => children(date + " " + time)}
        </FormattedTime>
      )}
    </FormattedDate>
  ) : (
    <>
      <FormattedDate value={value} /> <FormattedTime value={value} />
    </>
  );

const numberWithUnit = (unit, adjustProps) => ({
  withUnit,
  children,
  ...rest
}) => {
  const newProps = adjustProps(rest);
  return (
    <FormattedNumber {...newProps}>
      {(num) =>
        children
          ? children([num, unit, newProps.value])
          : `${num}${withUnit ? " " + unit : ""}`
      }
    </FormattedNumber>
  );
};

export const FormattedMoisture = numberWithUnit("%", (props) => ({
  ...props,
  value: props.value * 100,
  maximumFractionDigits: 3,
}));

export const FormattedWaterBalance = numberWithUnit("", (props) => ({
  ...props,
  value: props.value,
  maximumFractionDigits: 3,
}));

export const FormattedTemperatureFahrenheit = numberWithUnit("°F", (props) => ({
  ...props,
  value: props.noConversion ? props.value : celciusToFahrenheit(props.value),
  maximumFractionDigits: 2,
}));

export const FormattedTemperatureCelcius = numberWithUnit("°C", (props) => ({
  ...props,
  maximumFractionDigits: 2,
}));

export const FormattedSalinity = numberWithUnit("dS/m", (props) => ({
  ...props,
  maximumFractionDigits: 2,
}));

export const FormattedVoltage = numberWithUnit("V", (props) => ({
  ...props,
  maximumFractionDigits: 2,
}));

export const FormattedOxygen = numberWithUnit("%", (props) => ({
  ...props,
  value: props.value * 100,
  maximumFractionDigits: 3,
}));

export const FormattedPacketsPerDay = numberWithUnit(
  "packets / day",
  (props) => ({
    ...props,
    maximumFractionDigits: 0,
  })
);

export const FormattedPacketsPerHour = numberWithUnit(
  "packets / hour",
  (props) => ({
    ...props,
    maximumFractionDigits: 0,
  })
);

export const FormattedMeasurement = ({ variable, ...rest }) => {
  const { currentUser } = useContext(UserContext);
  const imperial = currentUser.pref_unit_temp === "fahrenheit";
  return cloneElement(
    {
      moisture: <FormattedMoisture />,
      temperature: imperial ? (
        <FormattedTemperatureFahrenheit />
      ) : (
        <FormattedTemperatureCelcius />
      ),
      salinity: <FormattedSalinity />,
      conductivity: <FormattedSalinity />,
      water_balance: <FormattedWaterBalance />,
      voltage_battery: <FormattedVoltage />,
      voltage_external: <FormattedVoltage />,
      oxygen: <FormattedOxygen />
    }[variable],
    { ...rest }
  );
};

export const celciusToFahrenheit = (c) => c * (9 / 5) + 32;
export const fahrenheitToCelcius = (f) => ((f - 32) * 5) / 9;
export const meterToInchFactor = 39.37;
export const footToMeter = (ft) => ft * 0.3048;
