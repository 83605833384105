import { yupResolver } from "@hookform/resolvers";
import * as deepEqual from "fast-deep-equal";
import flat from "flat";
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import { errorTranslate, buildRequest } from "../api";
import { FormSeparator } from "./FormWidgets";
import { AuthContext } from "../state/AuthContext";
import Spinner from "./Spinner";

export const WatchContext = createContext();

export default function Form({
  onCancel,
  context,
  defaultValues,
  onSave,
  overrideOnSubmit,
  transform,
  watch,
  children,
  overrideValidationSchema,
  readOnly,
  onAsyncErr,
  validateOnSubmit,
  disableAddAnother,
}) {
  const {
    crud: { create, list, update },
    validationSchema,
  } = useContext(context);
  const prevValues = useRef();
  const isCreating = defaultValues?.id === undefined;
  const { authenticatedFetch } = useContext(AuthContext);

  const methods = useForm({
    mode: validateOnSubmit ? "onSubmit" : "onBlur",
    resolver: yupResolver(
      overrideValidationSchema
        ? overrideValidationSchema(defaultValues)
        : validationSchema(defaultValues)
    ),
    defaultValues,
  });

  const watchFields = watch ? methods.watch(watch) : null;
  const doTransform = (validatedData) =>
    transform
      ? transform(validatedData)
      : { ...defaultValues, ...validatedData };

  const handleAsyncErr = (err) => {
    if (err.status === 400) {
      err.json().then((obj) => {
        const errors = Object.entries(flat(obj, { safe: true })).map(
          ([name, [message]]) => ({
            name,
            message:
              message in errorTranslate ? errorTranslate[message] : message,
          })
        );
        errors.forEach(({ name, message }) => {
          methods.setError(name, { type: "manual", message });
          onAsyncErr && onAsyncErr({ name, message }, methods);
        });
      });
    } else if (err.status === 500) {
      toast.error(<FormattedMessage id={"error.server_communication"} />);
    } else {
      toast.error(`Error: ${err.status} ${err.statusText}`);
      list();
    }
  };

  const recalculateMeasurements = () => {
    const data = JSON.parse(window.sessionStorage.getItem("recalculateMeasurementsData"));
    const deviceId = data.deviceId;
    const body = data.body;

    const failToast = () => toast.error(
      <FormattedMessage id={"error.server_communication"} />,
      { autoClose: 15000 }
    );

    const successToast = () => toast.info(
      <FormattedMessage id={"action.recalculating_measurements"} />,
      { autoClose: 15000 }
    );

    let promise = new Promise((resolve, reject) => {
      authenticatedFetch(
        buildRequest("PATCH")
          .withPath(`/devices/${deviceId}/recalculate_measurements/`)
          .withBody(body)
      ).then(() => {
        successToast();
        resolve();
      }).catch((error) => {
        failToast();
        reject(error);
      }).finally(() => {
        window.sessionStorage.removeItem("recalculateMeasurementsData");
      })
    });

    return promise;
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitterId, setSubmitterId] = useState(null); // Track which button was clicked

  const onSubmit = async (validatedData, { nativeEvent: { submitter } }) => {
    // If a submission is already in progress, prevent another submission
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    setSubmitterId(submitter.id); // Set the clicked button's ID

    overrideOnSubmit && (await overrideOnSubmit(validatedData));

    const data = doTransform(validatedData);

    try {
      if (isCreating) {
        const resp = await create(data);
        toast.success(<FormattedMessage id={"action.created"} />);
        if (submitter.id === "save-and-add") {
          methods.reset();
        } else {
          onSave && onSave(resp);
        }
      } else {
        const resp = await update(data);
        toast.success(<FormattedMessage id={"action.updated"} />);
        onSave && onSave(resp);
        if (window.sessionStorage.getItem("recalculateMeasurements") === 'true') {
          recalculateMeasurements();
          window.sessionStorage.setItem("recalculateMeasurements", false);
      }
      }
    } catch (error) {
      handleAsyncErr(error);
    } finally {
      setIsSubmitting(false);
      setSubmitterId(null);
    }
  };

  const errors = methods.errors;

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.debug("Validation errors:", errors);
    }
  }, [errors]);

  const resetForm = methods.reset;
  useEffect(() => {
    if (!deepEqual(defaultValues, prevValues.current)) {
      resetForm(defaultValues);
    }
    prevValues.current = defaultValues;
  }, [defaultValues, resetForm]);

  const touched = methods.formState.isDirty && methods.formState.touched;

  return (
    <div className="flex flex-col h-full">
      <FormattedMessage id="prompt.form.dirty">
        {(message) => (
          <Prompt
            when={touched && !methods.formState.isSubmitted}
            message={message}
          />
        )}
      </FormattedMessage>

      <div className="flex-1">
        <form
          id="form"
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
          className="grid grid-cols-1 sm:grid-cols-form col-gap-4 row-gap-3 sm:justify-between items-center"
        >
          <FormProvider {...methods}>
            <WatchContext.Provider value={watchFields}>
              {[].concat(children).map(
                (child, idx) =>
                  child !== null &&
                  child !== false && (
                    <Fragment key={`frag-${idx}`}>
                      {readOnly
                        ? React.cloneElement(child, {
                          ...child.props,
                          disabled: true,
                        })
                        : child}
                      {idx < children.length - 1 && <FormSeparator />}
                    </Fragment>
                  )
              )}
              {!readOnly && (
                <div className="col-span-2 flex-1 pt-8 flex flex-row-reverse align-middle">
                  <FormattedMessage id="button.save">
                    {(message) => (
                      <button
                        form="form"
                        type="submit"
                        id="save"
                        disabled={!touched || methods.formState.isSubmitting}
                        className="ml-4 btn btn-blue w-1/2 shadow-indigo-md"
                      >
                        {/* Show spinner only if this button is the submitter */}
                        {submitterId === "save" && methods.formState.isSubmitting ? (
                          <Spinner />
                        ) : (
                          message
                        )}
                      </button>
                    )}
                  </FormattedMessage>

                  {onCancel ? (
                    <FormattedMessage id="button.cancel">
                      {(message) => (
                        <button
                          onClick={onCancel}
                          form="form"
                          type="submit"
                          id="cancel"
                          className="ml-4 btn bg-gray-300 w-1/2 shadow-indigo-md hover:bg-gray-200"
                        >
                          {message}
                        </button>
                      )}
                    </FormattedMessage>
                  ) : null}

                  {isCreating && !disableAddAnother ? (
                    <FormattedMessage id="button.save-and-add">
                      {(message) => (
                        <button
                          form="form"
                          type="submit"
                          id="save-and-add"
                          disabled={!touched || methods.formState.isSubmitting}
                          className="py-3 btn btn-blue-clear w-1/2"
                        >
                          {/* Show spinner only if this button is the submitter */}
                          {submitterId === "save-and-add" && methods.formState.isSubmitting ? (
                            <Spinner />
                          ) : (
                            message
                          )}
                        </button>
                      )}
                    </FormattedMessage>
                  ) : null}
                </div>
              )}
            </WatchContext.Provider>
          </FormProvider>
        </form>
      </div>
    </div>
  );
}
