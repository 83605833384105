import classNames from "classnames";
import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import { ReactComponent as MapPinBig } from "../assets/icons/map-pin-big.svg";
import { ReactComponent as ScoutBigIcon } from "../assets/icons/scout-big.svg";
import { ReactComponent as SettingsBigIcon } from "../assets/icons/settings-big.svg";
import { ReactComponent as WifiBigIcon } from "../assets/icons/wifi-big.svg";
import { ReactComponent as AnalyticsStudioIcon } from "../assets/icons/analytics-studio.svg";
import NotificationsContext from "../state/NotificationsContext";
import { IconBellBig } from "./Icons";
import { DashContext } from "./Main";
import UnreadBadge from "./UnreadBadge";

export const NavSpacer = () => {
  const match = useRouteMatch("/sites/:site_id/:view");
  const { site_id } = match ? match.params : {};
  return site_id ? (
    <div className="pb-12 md:pb-16 lg:pb-0 flex-shrink-0"></div>
  ) : null;
};

const NavItem = ({ className, selected, to, children }) => {
  return (
    <Link
      className={classNames(
        className,
        "flex flex-col justify-center p-3 px-4 sm:px-8 outline-none focus:bg-white focus:bg-opacity-25 items-center",
        { "bg-white bg-opacity-15": selected }
      )}
      to={to}
    >
      {children}
    </Link>
  );
};

const Navigation = () => {
  const { viewingDash, view, site_id, navRef } = useContext(DashContext);

  const notifications = useContext(NotificationsContext);
  const unreadCount = notifications?.unread?.length;
  return viewingDash
    ? createPortal(
        <div
          id="navigation"
          className="text-white flex h-12 sm:h-16 lg:h-full mt-16 sm:mt-20 lg:mt-0 bg-gradient-l-primary lg:bg-none absolute justify-center top:0 lg:relative inset-x-0 z-20 mb-12 sm:mb-16 lg:mb-0"
        >
          <NavItem selected={view === "scouts"} to={`/sites/${site_id}/scouts`}>
            <ScoutBigIcon className="fill-current" />
            <p className="mt-2 hidden lg:inline-flex text-sm font-medium uppercase">
              <FormattedMessage id="views.scout_view.heading" />
            </p>
          </NavItem>
          <NavItem selected={view === "analyses"} to={`/sites/${site_id}/analyses`}>
            <AnalyticsStudioIcon className="fill-current" />
            <p className="mt-2 hidden lg:inline-flex text-sm font-medium uppercase">
              <FormattedMessage id="views.analysis_view.heading" />
            </p>
          </NavItem>
          <NavItem selected={view === "map"} to={`/sites/${site_id}/map`}>
            <MapPinBig className="fill-current" />
            <p className="mt-2 hidden lg:inline-flex text-sm font-medium uppercase">
              <FormattedMessage id="view.title.map" />
            </p>
          </NavItem>
          <NavItem
            className="relative"
            selected={view === "alerts"}
            to={`/sites/${site_id}/alerts`}
          >
            <IconBellBig className="fill-current" />
            <p className="mt-2 hidden lg:inline-flex text-sm font-medium uppercase">
              <FormattedMessage id="navigation.alerts" />
            </p>
            {unreadCount > 0 && (
              <UnreadBadge
                className="absolute top-0 right-0 mr-8 mt-2"
                count={unreadCount}
              />
            )}
          </NavItem>
          <NavItem
            selected={view === "devices"}
            to={`/sites/${site_id}/devices`}
          >
            <WifiBigIcon className="fill-current" />
            <p className="mt-2 hidden lg:inline-flex text-sm font-medium uppercase">
              <FormattedMessage id="navigation.network_devices" />
            </p>
          </NavItem>
          <NavItem
            selected={view === "settings"}
            to={`/sites/${site_id}/settings`}
          >
            <SettingsBigIcon className="fill-current" />
            <p className="mt-2 hidden lg:inline-flex text-sm font-medium uppercase">
              <FormattedMessage id="view.settings.title" />
            </p>
          </NavItem>
        </div>,
        navRef.current
      )
    : null;
};

export default Navigation;
