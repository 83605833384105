import React, { useContext, useEffect, useMemo, } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { meterToInchFactor } from "../localization";
import { DeviceContext } from "../state/DeviceContext";
import DeviceGroupContext from "../state/DeviceGroupContext";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import { DetailsContext } from "./DetailsView";
import { getSoilTypeConfirmation } from "./SoilTypeDialog";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import Form from "./Form";
import {
  Fieldset,
  AntennaSelector,
  GroupSelect,
  Input,
  LabeledWidget,
  LocationPicker,
  SoilTypeSelect
} from "./FormWidgets";
import OxygenCalibration from "./DeviceOxygenCalibration";

const defaultSoilDensities = {
  loam: 1.4,
  organic: 0.5,
  clay: 1.1,
  sand: 1.6,
};

const defaultFieldCapacities = {
  loam: 25.0,
  organic: 60.0,
  clay: 40.0,
  sand: 20.0,
};

const defaultWiltingPoints = {
  loam: 8.0,
  organic: 15.0,
  clay: 20.0,
  sand: 5.0,
};

const defaultOxygenCalibrationValue = {
  oxygen_current_calib: 20.9,
};

const SoilDensityField = (props) => {
  const { setValue } = useFormContext();
  const soilType = useWatch({ name: "location.soil_type" });
  useEffect(() => {
    setValue("location.soil_density", defaultSoilDensities[soilType], {
      shouldDirty: true,
    });
  }, [soilType, setValue]);
  return (
    <LabeledWidget
      widget={<Input />}
      type="number"
      name="location.soil_density"
      labelId="form.label.soil_density"
      step={0.2}
      {...props}
    />
  );
};

const FieldCapacityField = (props) => {
  const { setValue } = useFormContext();
  const soilType = useWatch({ name: "location.soil_type" });
  useEffect(() => {
    setValue("location.field_capacity", defaultFieldCapacities[soilType], {
      shouldDirty: true,
    });
  }, [soilType, setValue]);
  return (
    <LabeledWidget
      widget={<Input />}
      type="number"
      step={1}
      min={0}
      name="location.field_capacity"
      labelId="form.label.field_capacity"
      placeholder={`E.g. ${defaultFieldCapacities[soilType]}`}
      {...props}
    />
  );
};

const WiltingPointField = (props) => {
  const { setValue } = useFormContext();
  const soilType = useWatch({ name: "location.soil_type" });
  useEffect(() => {
    setValue("location.wilting_point", defaultWiltingPoints[soilType], {
      shouldDirty: true,
    });
  }, [soilType, setValue]);
  return (
    <LabeledWidget
      widget={<Input />}
      type="number"
      step={1}
      min={0}
      name="location.wilting_point"
      labelId="form.label.wilting_point"
      placeholder={`E.g. ${defaultWiltingPoints[soilType]}`}
      {...props}
    />
  );
};

const DeviceForm = ({ device, type, onSave, onDelete }) => {
  const {
    crud: { list: fetchGroups },
  } = useContext(DeviceGroupContext);
  const { currentSite } = useContext(SiteContext);
  const { creating } = useContext(DetailsContext);
  const { currentUser } = useContext(UserContext);


  const siteId = currentSite.id;
  const defaultLat = currentSite.latitude;
  const defaultLon = currentSite.longitude;

  const imperial = currentUser.pref_unit_length === "imperial";

  let heightLabel = "form.label";

  if (type === "hydra") {
    heightLabel += ".depth";
  } else {
    heightLabel += ".height";
  }

  if (imperial) {
    heightLabel += ".in";
  } else {
    heightLabel += ".cm";
  }

  const heightSign = type === "hydra" ? -1 : 1;

  const defaultValues = useMemo(
    () =>
      device
        ? {
          ...device,
          latlon: {
            ...device.location,
          },
          location: {
            ...device.location,
            height:
              device.location.height !== null
                ? Math.round(
                  device.location.height *
                  (heightSign * (imperial ? meterToInchFactor : 100))
                )
                : null,
            field_capacity: device.location.field_capacity
              ? Math.round(device.location.field_capacity * 100)
              : device.location.field_capacity,
            wilting_point: device.location.wilting_point
              ? Math.round(device.location.wilting_point * 100)
              : device.location.wilting_point,
          },
          oxygen_current_calib:
            device.oxygen_current_calib ??
            defaultOxygenCalibrationValue.oxygen_current_calib,
          oxygen_last_calib: device.oxygen_last_calib
        }
        : {
          ...(type === "hydra"
            ? {
              location: {
                soil_type: "loam",
                soil_density: defaultSoilDensities["loam"],
                field_capacity: defaultFieldCapacities["loam"],
                wilting_point: defaultWiltingPoints["loam"],
                irrigation_threshold: 0.5,
              },
              oxygen_current_calib: defaultOxygenCalibrationValue.oxygen_current_calib,
              oxygen_last_calib: null
            }
            : {}),
          device_type: type,
          groups: [],
          latlon: {
            latitude: defaultLat,
            longitude: defaultLon,
          },
        },
    [device, type, imperial, heightSign, defaultLat, defaultLon]
  );

  const showRecalculationMessage = (device, newDevices) => {
    // Generate informational toast if field capacity has been changed
    const updatedDevice = newDevices.find((d) => d.id === device.id);
    if (
      (updatedDevice &&
        updatedDevice.location.field_capacity !==
        device.location.field_capacity) ||
      updatedDevice.location.wilting_point !== device.location.wilting_point
    ) {
      if (window.sessionStorage.getItem("recalculateMeasurements") !== 'true') {
        toast.info(
          <FormattedMessage id={"action.recalculating_water_balance"} />,
          { autoClose: 15000 }
        );
      }
    }
  };

  const soilTypeChanged = (persistedSoilType, updatedSoilType) =>
    persistedSoilType !== updatedSoilType;

  const recalculateMeasurements = (deviceId, body) => {

    let promise = new Promise((resolve) => {
      window.sessionStorage.setItem("recalculateMeasurements", true)
      const data = { body: body, deviceId: deviceId }
      window.sessionStorage.setItem("recalculateMeasurementsData", JSON.stringify(data))
      resolve();
    });

    return promise;
  }

  const overrideOnSubmit = async (device, validatedData) => {
    if (!creating && type === "hydra") {
      // Intercept the submit action before executing API requests
      const updatedSoilType = validatedData.location?.soil_type;
      if (soilTypeChanged(device.location?.soil_type, updatedSoilType)) {
        // Present the user with an option to recalculate moisture measurement
        await getSoilTypeConfirmation(
          device.id,
          updatedSoilType,
          recalculateMeasurements
        );
      }
    }
  };

  return (
    <Form
      entityName={device?.name}
      context={DeviceContext}
      creating={creating}
      defaultValues={defaultValues}
      transform={(formValues) => {
        const oxygenCalibration = 
          formValues.oxygen_current_calib ??
          defaultOxygenCalibrationValue.oxygen_current_calib;

        delete formValues.stats;
        delete formValues.last_measurement;

        // Sets default antenna configuration based on device_type and antenna_type
        // directive_yagi = num
        // others = null
        const antenna = {
          antenna_type: formValues.antenna_type ?? null,
          antenna_orientation:
            formValues.antenna_type === "directive_yagi" &&
              (formValues.antenna_orientation === undefined ||
                formValues.antenna_orientation === null)
              ? 0
              : formValues.antenna_type === "directive_yagi"
                ? formValues.antenna_orientation
                : null,
        };

        delete formValues.antenna_orientation;
        delete formValues.antenna_type;

        const serial_number =
          type === "base"
            ? parseInt(formValues.imei.replace(/^0+/, ""), 10)
            : formValues.serial_number;

        if (type === "hydra" || type === "echo") {
          delete formValues.imei;
        }

        const { latitude, longitude } = formValues.latlon
          ? formValues.latlon
          : {};

        if (formValues.latlon) {
          delete formValues.latlon;
        }


        return {
          ...device,
          ...formValues,
          ...antenna,
          serial_number,
          site: siteId,
          location: {
            ...formValues.location,
            latitude,
            longitude,
            height:
              formValues.location.height !== null
                ? heightSign *
                (imperial
                  ? formValues.location.height / meterToInchFactor
                  : formValues.location.height * 0.01)
                : null,
            field_capacity:
              type === "hydra"
                ? formValues.location.field_capacity / 100
                : formValues.location.field_capacity,
            wilting_point:
              type === "hydra"
                ? formValues.location.wilting_point / 100
                : formValues.location.wilting_point,
          },
          device_type: type,
          oxygen_current_calib:
            type === "hydra" && device?.protocol_version === "209"
              ? parseFloat(oxygenCalibration, 3)
              : oxygenCalibration,
        };
      }}
      onSave={(newDevices) => {
        // Maybe group memberships have changed?
        fetchGroups();
        device?.device_type === "hydra" &&
          showRecalculationMessage(device, newDevices);
        onSave && onSave();
      }}
      onDelete={onDelete}
      readOnly={currentUser.read_only}
      onAsyncErr={({ name, message }, form) => {
        // These fields are combined for base stations
        if (name === "serial_number" && type === "base") {
          form.setError("imei", { type: "manual", message });
        }
      }}
      overrideOnSubmit={(validatedData) =>
        creating ? null : overrideOnSubmit(device, validatedData)
      }
    >
      {type === "base" ? (
        <LabeledWidget
          widget={<Input />}
          name="imei"
          labelId="form.label.identifier"
          helpId="form.help.base_identifier"
          required={true}
          disabled={device?.id}
          onBlur={(evt) =>
            (evt.target.value = evt.target.value.padStart(15, "0"))
          }
        />
      ) : (
        <LabeledWidget
          widget={<Input />}
          name="serial_number"
          labelId="form.label.serial_number"
          disabled={device?.id}
          required={true}
        />
      )}
      <LabeledWidget
        widget={<Input />}
        name="name"
        labelId="form.label.device_name"
        required={true}
      />


      {type === "hydra" ? (
        <Fieldset legendId="form.legend.soil">
          <SoilTypeSelect
            name="location.soil_type"
            disabled={type !== "hydra"}
          />
          <SoilDensityField />
          <FieldCapacityField />
          <WiltingPointField />
          <LabeledWidget
            widget={<Input />}
            type="number"
            step={0.01}
            min={0}
            name="location.irrigation_threshold"
            labelId="form.label.irrigation_threshold"
            placeholder={0.5}
          />
        </Fieldset>
      ) : null }

      <LocationPicker name="latlon" />
      
      <LabeledWidget
        widget={<Input />}
        type="number"
        step={1}
        min={0}
        name="location.height"
        labelId={heightLabel}
      />
      {type === "hydra" && <GroupSelect name="groups" />}
      {(type === "base" || type === "echo") && (
        <AntennaSelector disabled={type === "hydra"} />
      )}
      {(type === "hydra" && device?.protocol_version === "209") && (
        <OxygenCalibration
          device={device}
          isImperial={imperial}
        />
      )}
    </Form>
  );
};
export default DeviceForm;
