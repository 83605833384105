import React, { useContext, useMemo } from "react";
import { string, object } from "yup";
import Form from "../Form";
import { Input, LabeledWidget } from "../FormWidgets";
import AnalysisContext from "../../state/AnalysisContext";
import { UserContext } from "../../state/UserContext";
import { DEFAULT_DURATION } from "./AnalysisView";

const validationSchemaOnCreate = () =>
  object().shape({
    name: string().required("validation.required"),
  });

export const AnalysisForm = ({
  analysis,
  site_id,
  creating,
  onSave,
  children,
  cancelButton,
  ...rest
}) => {
  const { currentUser } = useContext(UserContext);
  const { durationHandler } = useContext(AnalysisContext);

  const defaultValues = useMemo(() => (analysis ? analysis : {}), [analysis]);

  return (
    <Form
      cancelButton={cancelButton}
      entityName={analysis?.name}
      context={AnalysisContext}
      creating={creating}
      readOnly={currentUser.read_only}
      onSave={(result) => {
        onSave && onSave(result);
      }}
      defaultValues={defaultValues}
      validateOnSubmit={false}
      overrideValidationSchema={validationSchemaOnCreate}
      transform={(formValues) => {
        const charts = formValues.charts ?? [];

        return {
          name: formValues.name,
          ...(analysis ? { charts: analysis.charts } : { charts }),
          site: site_id,
          duration: creating
            ? DEFAULT_DURATION
            : durationHandler.duration ?? analysis?.duration,
          ...(analysis && { id: analysis.id }),
        };
      }}
      {...rest}
    >
      <LabeledWidget
        widget={<Input />}
        name="name"
        labelId="form.label.analysis_name"
        required
      />
    </Form>
  );
};

export default AnalysisForm;
