import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { DetailsContext, DetailsView } from "./../DetailsView";
import { AnalysisForm } from "./AnalysisForm";
import AnalysisContext from "../../state/AnalysisContext";

export function getLatestAnalysis(analyses) {
  return analyses?.length > 0
    ? analyses.reduce(
        (latest, current) =>
          current.created >= latest.created ? current : latest,
        analyses[0]
      )
    : undefined;
}

const AnalysisDetails = ({ site_id, linkTo }) => {
  const history = useHistory();
  return (
    <DetailsView
      param="id"
      context={AnalysisContext}
      getTitle={(creating) =>
        creating ? (
          <FormattedMessage id="analysis.create" />
        ) : (
          <FormattedMessage id="analysis.edit" />
        )
      }
      linkTo={linkTo}
    >
      <DetailsContext.Consumer>
        {({ entity: analysis, creating }) => (
          <AnalysisForm
            analysis={analysis}
            site_id={site_id}
            creating={creating}
            onSave={
              creating
                ? (entities) => {
                    const latest = getLatestAnalysis(entities);

                    return latest && linkTo
                      ? history.push(linkTo.concat("/view/", latest.id))
                      : history.goBack();
                  }
                : null
            }
          />
        )}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

export default AnalysisDetails;
