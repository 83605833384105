import React, {
  cloneElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import * as deepEqual from "fast-deep-equal";
import {
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  subDays,
} from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import { FiServer, FiWifi } from "react-icons/fi";
import {
  DateTimeParam,
  NumberParam,
  withDefault,
  useQueryParams,
} from "use-query-params";

import { buildRequest } from "../api";
import useLocalStorage from "../hooks/useLocalStorage";
import { useInterval } from "../hooks/useInterval";
import { AuthContext } from "../state/AuthContext";
import { DeviceContext } from "../state/DeviceContext";
import { UserContext } from "../state/UserContext";

import Card from "./Card";
import ConnectionStatus from "./ConnectionStatus";
import DashContent from "./DashContent";
import DashHeader, { IconLink, TitleContainer } from "./DashHeader";
import DateTimeSelector from "./DateTimeSelector";
import DeviceDetails from "./DeviceDetails";
import Graph from "./Graph";
import Popup, { PopupSection } from "./Popup";
import ResponsiveSwitchView from "./ResponsiveSwitchView";
import Table from "./Table";
import { BaseOverview, EchoOverview } from "./ReceiverOverviews";
import { DEFAULT_TIMESPAN } from "../utility/common";
import { IconChevron, IconEdit, IconInfo, IconLeft } from "./Icons";
import { SectionHeading } from "./Headings";
import {
  generateGridLines,
  getDefiniteMin,
  getDefiniteMax,
  getMinMeasurement,
  getMaxMeasurement,
  prepareMultipleLine,
} from "../utility/measurement-helpers";

var domain = null; // zoom-level (domain in billboardjs represents an axis)
const UPDATE_INTERVAL = 20000;

const centeredSvg = (elem, title) =>
  cloneElement(elem, {
    title: title,
    className: "mx-auto stroke-2 block text-scout-blue",
  });

const sortDevices = ({ original: a }, { original: b }, _columnID, desc) => {
  const isBase = (d) => d.device_type === "base";
  if (isBase(a) && !isBase(b)) {
    return desc ? 1 : -1;
  } else if (!isBase(a) && isBase(b)) {
    return desc ? -1 : 1;
  }

  return a.name.localeCompare(b.name);
};

const DeviceList = ({ devices, selectionID, ...rest }) => {
  const someNotConnected = devices.some(
    (obj) => obj?.device_status === "NOT_CONNECTED"
  );

  const columns = useMemo(
    () =>
      [
        {
          id: "type_icon",
          accessor: "device_type",
          className: "pl-4",
          noHeaderPopup: true,
          Cell: (cell) => (
            <FormattedMessage id={`device.type.${cell.value}`}>
              {(title) =>
                cell.value === "base"
                  ? centeredSvg(<FiServer size={24} />, title)
                  : centeredSvg(<FiWifi size={24} />, title)
              }
            </FormattedMessage>
          ),
        },
        someNotConnected
          ? {
              id: "status",
              Header: null,
              accessor: "device_status",
              Cell: (cell) => <ConnectionStatus device={cell.row.original} />,
            }
          : {},
        {
          Header: <FormattedMessage id="table.header.name" />,
          accessor: "name",
          sortType: sortDevices,
          className: "w-full",
          Cell: (cell) => <p className="py-2">{cell.value}</p>,
        },
        {
          Header: <FormattedMessage id="device_list.header.type" />,
          accessor: "device_type",
          className: "pr-4",
          popupLeft: true,
          Cell: (cell) => <FormattedMessage id={`device.type.${cell.value}`} />,
        },
      ].filter((obj) => {
        // fastest way to filter out empty objects
        for (var i in obj) return true;
        return false;
      }),
    [someNotConnected]
  );

  return (
    <Table
      columns={columns}
      data={devices ? devices : []}
      sortBy={useMemo(() => [{ id: "name", desc: false }], [])}
      {...rest}
    />
  );
};

const BatteryHistoryCard = ({ device, measurements, ...rest }) => {
  const intl = useIntl();
  const metric = "voltage_battery";

  const stepSize = 0.2;
  const minMeasurement = getMinMeasurement(measurements, metric);

  const min = getDefiniteMin(minMeasurement, stepSize);
  const max = 4.2;
  const lines =
    Number.isFinite(max) &&
    Number.isFinite(min) &&
    generateGridLines(min, max, stepSize);


    const getTresholds = () => {
        return [
          {
            axis: "y",
            start: 3.4,
            end: 3.7,
            class: "region-red",
          },
          {
            axis: "y",
            start: 3.7,
            end: 3.9,
            class: "region-yellow",
          },
          {
            axis: "y",
            start: 3.9,
            end: 4.2,
            class: "region-green",
          }
        ]
    }

  return (
    <div>
      <Card heading={intl.formatMessage({ id: "dash_card.battery" })}>
        <Graph
          {...(measurements.voltage_battery
            ? { dataSeries: Object.values(measurements.voltage_battery) }
            : {})}
          metricType={{
            value: metric,
            symbol: "V",
            label: intl.formatMessage({ id: "measurement.voltage_battery" }),
          }}
          yAxisMax={max}
          yAxisMin={3.4}
          yAxisConfig={{ stepSize }}
          gridConfig={{
            y: {
              lines: lines ? lines : [],
            },
          }}
          regionConfig={getTresholds()}
          {...rest}
        />
      </Card>
    </div>
  );
};

const ExternalVoltageHistoryCard = ({ device, measurements, ...rest }) => {
  const intl = useIntl();
  const metric = "voltage_external";
  const maxMeasurement = getMaxMeasurement(measurements, metric);
  const minMeasurement = getMinMeasurement(measurements, metric);

  const stepSize = 2.5;

  const min = getDefiniteMin(minMeasurement, stepSize);
  const max = getDefiniteMax(maxMeasurement, 1);
  const lines =
    Number.isFinite(max) &&
    Number.isFinite(min) &&
    generateGridLines(min, max, stepSize);

  return (
    <div>
      <Card heading={intl.formatMessage({ id: "dash_card.external" })}>
        <Graph
          {...(measurements.voltage_external
            ? { dataSeries: Object.values(measurements.voltage_external) }
            : {})}
          metricType={{
            value: metric,
            symbol: "V",
            label: intl.formatMessage({ id: "measurement.voltage_external" }),
          }}
          yAxisMax={max}
          yAxisMin={0}
          yAxisConfig={{ stepSize }}
          gridConfig={{
            y: {
              lines: lines ? lines : [],
            },
          }}
          {...rest}
        />
      </Card>
    </div>
  );
};

export default function DeviceView({ site }) {
  const { currentUser } = useContext(UserContext);
  const { authenticatedFetch } = useContext(AuthContext);
  const {
    networkDevices,
    updateDevices,
    getSensorDataDownsampled,
  } = useContext(DeviceContext);
  const { path, url } = useRouteMatch();
  const selectionMatch = useRouteMatch([
    `${url}/edit/echoes/:echo_id`,
    `${url}/edit/bases/:base_id`,
    `${url}/edit/echoes/`,
    `${url}/edit/bases/`,
    `${url}/view/echoes/:echo_id`,
    `${url}/view/bases/:base_id`,
    `${url}/view/echoes/`,
    `${url}/view/bases/`,
  ]);

  // Logic for storing datetime selection state in the URL
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", DEFAULT_TIMESPAN);
  const now = new Date();
  const queryMap = {
    from: withDefault(DateTimeParam, subDays(now, timeSpan)),
    to: withDefault(DateTimeParam, now),
    preset: withDefault(NumberParam, timeSpan),
  };
  const [query, setQuery] = useQueryParams(queryMap);
  const timeStart = query.from;
  const timeEnd = query.to;
  const preset = query.preset;
  const datePickerRef = useRef();
  const zoomStore = useRef();
  const [zoomed, setZoomed] = useState(false);

  (function updateParamsByPreset() {
    const REFRESH_INTERVAL = 1;
    if (preset > 0) {
      let newStart = subDays(now, preset);
      let diffInMins = differenceInMinutes(newStart, timeStart);
      if (diffInMins > REFRESH_INTERVAL) {
        setQuery({ from: newStart, to: now });
      }
    }
  })();

  domain = [timeStart, timeEnd];

  const zoom = ([from, to]) => {
    const prevDomain = domain;
    setQuery({ from, to });
    if (!zoomStore.current) {
      zoomStore.current = prevDomain;
    }
    setZoomed(true);
  };

  const qs = JSON.stringify({ site: site.id });
  const [updatedNetworkDevices, updateNetworkDevices] = useState(
    networkDevices
  );
  const [batteryMeasurements, setBatteryMeasurements] = useState({});
  const history = useHistory();

  const editId = selectionMatch?.params
    ? Number(selectionMatch.params.echo_id) ||
      Number(selectionMatch.params.base_id)
    : null;
  const selectedDevice = updatedNetworkDevices.find(({ id }) => editId === id);
  const highlightIdx = updatedNetworkDevices?.findIndex(
    ({ id }) => id === editId
  );

  // Set up a pageConfig so we can react to any changes necessary to re-render the page
  const prevConfig = useRef();
  const newConfig = { editId, domain: [timeStart, timeEnd] };

  // Get sensor data once, and only once for any selected id
  // This would be reset every time another row is selected
  if (!deepEqual(prevConfig.current, newConfig)) {
    // if (selectedReceiver === editId && domain === [timeStart, timeEnd]) {
    prevConfig.current = newConfig;
    document.body.style.cursor = "wait";
    if (datePickerRef.current) {
      datePickerRef.current.setLoading(true);
    }

    if (selectedDevice !== undefined) {
      const windowSize = 500;
      const sensorDataQs = {
        since: timeStart.toISOString(),
        until: endOfDay(timeEnd).toISOString(),
        window_size: windowSize,
        site: site.id,
      };

      getSensorDataDownsampled([selectedDevice], sensorDataQs)
        .then((data) => {
          const result = prepareMultipleLine(data, [selectedDevice], null, [
            "voltage_battery",
            "voltage_external"
          ]);
          setBatteryMeasurements(result ?? {});
        })
        .finally(() => {
          datePickerRef?.current?.setLoading(false);
          document.body.style.cursor = "default";
        });
    }
  }

  useEffect(() => {
    updateNetworkDevices(networkDevices);
  }, [networkDevices]);

  // Get devices and filter networkDevices
  const fetchDevices = useCallback(() => {
    authenticatedFetch(
      buildRequest("GET").withPath(`/devices/`).withQuery(JSON.parse(qs))
    ).then((resp) => {
      updateDevices(resp);
      updateNetworkDevices(
        resp?.filter(({ device_type }) =>
          ["echo", "base"].includes(device_type)
        )
      );
    });
  }, [authenticatedFetch, qs, updateDevices]);

  useInterval(async () => {
    fetchDevices();
  }, UPDATE_INTERVAL);

  useEffect(() => fetchDevices(), [fetchDevices]);

  const sorted = networkDevices
    .map((original) => ({
      original,
    }))
    .sort(sortDevices)
    .map(({ original }) => original);

  const toolbar = (
    <div className="flex flex-row">
      {!zoomed && differenceInDays(Date.now(), timeEnd) < 1 && (
        <button
          className="btn bg-white shadow-indigo mr-2"
          onClick={() => {
            const from = timeStart;
            const to = new Date();
            setQuery({ from, to });
          }}
        >
          <FormattedMessage id="button.update-graphs" />
        </button>
      )}
      {zoomed && (
        <button
          className="btn bg-white shadow-indigo mr-2"
          onClick={() => {
            const [from, to] = zoomStore.current;
            zoomStore.current = null;
            setZoomed(false);
            setQuery({ from, to });
          }}
        >
          <FormattedMessage id="button.reset-zoom" />
        </button>
      )}
      <DateTimeSelector
        ref={datePickerRef}
        from={timeStart}
        to={timeEnd}
        preset={preset}
        onSubmit={(val) => {
          setQuery(val);
          zoomStore.current = null;
          setZoomed(false);
          val?.preset >= 0 && setTimeSpan(val.preset);
        }}
      />
    </div>
  );

  const header = (
    <DashHeader>
      <TitleContainer>
        <IconLink to={{ pathname: url }} icon={<IconLeft />} />
        <>
          <SectionHeading>{selectedDevice?.name}</SectionHeading>
          <IconLink
            className="mb-1 ml-4"
            to={`${url}/edit/${
              selectedDevice?.device_type === "echo" ? "echoes" : "bases"
            }/${selectedDevice?.id}`}
            icon={currentUser.read_only ? <IconInfo /> : <IconEdit />}
          />
          {!currentUser.read_only && (
            <div className="mx-2">
              <FormattedMessage id="button.edit" />
            </div>
          )}
        </>
      </TitleContainer>
      {toolbar}
    </DashHeader>
  );

  const commonProps = {
    doZoom: zoom,
    measurements: batteryMeasurements,
    device: selectedDevice,
    domain,
  };

  return (
    <ResponsiveSwitchView
      initial={
        <Redirect
          to={
            sorted?.length > 0
              ? `${url}/view/${
                  sorted[0].device_type === "echo" ? "echoes" : "bases"
                }/${sorted[0].id}`
              : url
          }
        />
      }
      sidebar={
        <>
          <DashHeader shadow solid>
            <SectionHeading>
              <FormattedMessage id="view.devices.title" />
            </SectionHeading>
            {!currentUser.read_only && (
              <Popup
                trigger={
                  <button className="btn btn-green">
                    <FormattedMessage id="button.action.new" />
                    <IconChevron className="ml-2" />
                  </button>
                }
              >
                <PopupSection titleId="button.action.new">
                  <Link to={`${url}/edit/echoes/`}>Echo</Link>
                  <Link to={`${url}/edit/bases/`}>Base</Link>
                </PopupSection>
              </Popup>
            )}
          </DashHeader>
          <DashContent shadow solid>
            <DeviceList
              site={site}
              updateDevices={updateDevices}
              highlightIdx={highlightIdx}
              devices={networkDevices}
              editRow={(obj) =>
                history.push(
                  `${url}/edit/${
                    obj.device_type === "echo" ? "echoes" : "bases"
                  }/${obj.id}`
                )
              }
              linkTo={(obj) =>
                `${url}/view/${
                  obj.device_type === "echo" ? "echoes" : "bases"
                }/${obj.id}`
              }
            />
          </DashContent>
        </>
      }
    >
      <Route path={`${path}/view`}>
        {({ match }) => (
          <Switch>
            <Route path={[`${match.path}/bases/:base_id`]}>
              <>
                {header}
                <DashContent className="pt-4 px-4 sm:px-8 grid row-gap-6 " grid>
                  <div className="w-full h-full">
                    <BaseOverview base={selectedDevice} site={site} />
                    <div className="mb-6" />
                    {selectedDevice?.has_battery ? (
                      <BatteryHistoryCard {...commonProps} />
                    ) : (
                      <></>
                    )}
                    <div className="mb-6"/>
                    <ExternalVoltageHistoryCard {...commonProps} />
                  </div>
                </DashContent>
              </>
            </Route>
            <Route path={[`${match.path}/echoes/:echo_id`]}>
              <>
                {header}
                <DashContent className="pt-4 px-4 sm:px-8 grid row-gap-6 " grid>
                  <div className="w-full h-full">
                    <EchoOverview echo={selectedDevice} />
                    <div className="mb-6" />
                    {selectedDevice?.has_battery ? (
                      <BatteryHistoryCard {...commonProps} />
                    ) : (
                      <></>
                    )}
                    <div className="mb-6"/>
                    <ExternalVoltageHistoryCard {...commonProps} />
                  </div>
                </DashContent>
              </>
            </Route>
          </Switch>
        )}
      </Route>
      <Route path={`${path}/edit`}>
        {({ match }) => (
          <Switch>
            <Route
              path={[`${match.path}/bases/:base_id/`, `${match.path}/bases/`]}
            >
              <DeviceDetails
                type="base"
                site_id={site?.id}
                linkTo={`/sites/${site?.id}/devices`}
              />
            </Route>
            <Route
              path={[`${match.path}/echoes/:echo_id/`, `${match.path}/echoes/`]}
            >
              <DeviceDetails
                type="echo"
                site_id={site?.id}
                linkTo={`/sites/${site?.id}/devices`}
              />
            </Route>
          </Switch>
        )}
      </Route>
    </ResponsiveSwitchView>
  );
}
