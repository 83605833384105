import React, { createContext, useCallback } from "react";
import * as yup from "yup";
import useResource from "../hooks/useResource";
import schemaFields from "../state/schema";

const AlertsContext = createContext();

const AlertsProvider = ({ children, site }) => {
  const [[{ entities: rules }], crud] = useResource("alerts/rules", {
    site: site.id,
  });

  const validationSchema = useCallback(
    (rule) =>
      yup.object().shape({
        name: schemaFields.uniqueString(rules, rule, "name"),
        group_id: yup
          .number()
          .typeError("validation.choose_group")
          .required("validation.required"),
        site_id: schemaFields.validNumber,
        retrigger: yup.boolean(),
        conditions: yup
          .array()
          .required("validation.conditions.required")
          .of(
            yup.object({
              measurement_type: yup.string().required(),
              inside_limits: yup.boolean(),
              low_limit: schemaFields.validNumber.lessThan(
                yup.ref("high_limit"),
                "validation.range"
              ),
              high_limit: schemaFields.validNumber.moreThan(
                yup.ref("low_limit"),
                "validation.range"
              ),
            })
          )
          .min(1),
        actions: yup.array().of(
          yup.object().shape({
            email: yup.string().email("validation.email"),
          })
        ),
      }),
    [rules]
  );

  return (
    <AlertsContext.Provider
      value={{
        rules,
        entities: rules,
        validationSchema,
        crud,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsContext;
export { AlertsProvider };
