import bb, { bar, line, selection, zoom } from "billboard.js";
import "billboard.js/dist/theme/insight.css";
import chroma from "chroma-js";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOMServer from "react-dom/server";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import config from "../tailwindConfig";
import {
  formatName,
  formatEpochDifferenceToOriginal,
  formatXAxisDuration,
} from "../utility/graph-helpers";
import useModal from "../hooks/useModal";
import usePrevious from "../hooks/usePrevious";
import { addDays, lightFormat } from "date-fns";

var domain;

// Generator to alternate sides for yAxes
function* yAxisSorter() {
  let i = 0;
  while (true) {
    const next = i ^ 1;
    i = i ^ 1;
    yield next % 2 === 0 ? "y" : "y2";
  }
}

const load = (element, chart, axisSorter) => {
  let data = {
    xs: {},
    axes: {},
    colors: {},
    columns: [],
    types: {},
  };
  for (const metricType in element) {
    const yAxis = axisSorter.next().value;
    for (const [name, series] of Object.entries(element[metricType])) {
      series.columns.xs[0] = `xs-${name}`;
      series.columns.ys[0] = `${name}`;

      data.columns.push(series.columns.xs);
      data.columns.push(series.columns.ys);
      data.xs[`${name}`] = `${series.xs}`;
      data.types[`${name}`] = series.type;
      data.axes[`${name}`] = yAxis;
      data.colors[`${name}`] = series.color;
    }
  }

  data.done = () => {
    if (chart.unzoom) {
      chart.unzoom();
    }
  };

  chart.load(data);
};

const actionHandler = ({ action, element }, chart, axisSorter) => {
  switch (action) {
    case "add":
      load(element, chart, axisSorter);
      return;
    case "add-all":
      chart.unload({ done: () => load(element, chart, axisSorter) });
      return;
    case "remove":
      chart.unload(`${element.name} ${element.uniqueId}`);
      return;
    case "update":
      chart.unload({
        ids: [`${element.name} ${element.uniqueId}`],
        done: load(element, chart, axisSorter),
      });
      return;
    case "clear":
      return;
    default:
      throw new Error();
  }
};

export default function LineGraph({
  sources,
  latestAction,
  xAxisMinMax,
  tempUnit,
  timeSpan,
  windowSize,
  levelOfDetail,
}) {
  const bbRef = useRef();
  const graphRef = useRef();
  const intl = useIntl();
  const currentLocale = intl.locale; 
  const haveMedium = useMediaQuery({
    query: `(min-width: ${config.theme.screens.md})`,
  });
  const [allData, setAllData] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [Modal, toggleModal] = useModal();

  

  const valueFormatterTooltip = useCallback(
    (x, metricType) => {
      const value = Array.isArray(x) ? x[1] : x;

      switch (metricType) {
        case "temperature":
          return `${value.toFixed(1)} ${
            tempUnit === "fahrenheit" ? "°F" : "°C"
          }`;
        case "moisture":
          return `${value.toFixed(1)} %`;
        case "salinity":
          return `${value.toFixed(2)} dS/m`;
        case "conductivity":
          return `${value.toFixed(2)} dS/m`;
        case "water_balance":
          return `${value.toFixed(2)} `;
        case "oxygen":
          return `${value.toFixed(2)} %`;
        default:
          return;
      }
    },
    [tempUnit]
  );

  const makeTooltip = useCallback(
    function (ttdata) {
      const fDateTime = (x) => `${intl.formatDate(x)} ${intl.formatTime(x)}`;

      let subTitles = ttdata.map((d) => {
        const uniqueId = d.id.split(" ");
        const source = sources.find(
          (source) => source.uniqueId === uniqueId[uniqueId.length - 1]
        );

        const metricType = source?.data_type;

        const originalTimestamp = formatEpochDifferenceToOriginal(
          d.x,
          source?.start_date
        );

        return Array.isArray(d.value)
          ? [
              `t: ${fDateTime(originalTimestamp)}`,
              `min: ${
                d.value[2]
                  ? valueFormatterTooltip(d.value[2], metricType, tempUnit)
                  : "-"
              } max: ${
                d.value[0]
                  ? valueFormatterTooltip(d.value[0], metricType, tempUnit)
                  : "-"
              }`,
            ]
          : [`t: ${fDateTime(originalTimestamp)}`];
      });

      const result = (
        <>
          {ttdata.map((d, idx) => {
            const uniqueId = d.id.split(" ");
            const source = sources.find(
              (source) => source.uniqueId === uniqueId[uniqueId.length - 1]
            );

            const metricType = source?.data_type;

            return (
              <div
                key={idx}
                style={{
                  opacity: "95%",
                  backgroundColor: chroma(this.color(d.id)).brighten(2),
                  color: chroma(this.color(d.id)).darken(2),
                }}
                className="rounded overflow-hidden shadow-lg px-5 py-2 flex flex-col items-center justify-evenly font-semibold"
              >
                <span className="text-sm">{formatName(d.id)}</span>
                <p className="inline">
                  {Array.isArray(d.value) && (
                    <span className="text-sm">{intl.formatMessage({ id: "graph.tooltip.mean" })} </span>
                  )}
                  <span className="text-lg ">
                    {valueFormatterTooltip(
                      !Array.isArray(d.value) ? d.value : d.value[1],
                      metricType
                    )}
                  </span>
                </p>
                {subTitles[idx].map((val, idx) => (
                  <p key={idx} className="text-sm">
                    {val}
                  </p>
                ))}
              </div>
            );
          })}
        </>
      );

      return ReactDOMServer.renderToStaticMarkup(result);
    },
    [valueFormatterTooltip, intl, sources, tempUnit]
  );

  const MakeBigTooltip = ({ values, sources, tempUnit }) => {
    const fDateTime = (x) => `${intl.formatDate(x)} ${intl.formatTime(x)}`;
    const timeConversionFactor = 1000000000;
    const date = values[0].x;
    const dateMilli = date.getTime();

    const result = values[1].map((val) => {
      const found = sources.find(
        ({ uniqueId }) => uniqueId === val.id.split(" ").pop()
      );

      /*
      With this function you can deside on the granularity by passing in the appropriate "i".
        Starting on i=1 you get everything timeSpan/windowSize*1000000000 difference from the point in time.
        This becomes more granular until you find one item. 
        At 1 day this formula gives a start size of around 0.55 of an hour. 
        At 7 days this formula gives a start size of around 3.8 hours
        At 30 days this formula gives a start size of around 0.7 days
        At 90 days this formula gives a start size of around 2 days
        At 180 days this formula gives a start size of around  4.2 days
        At 365 days this formula gives a start size of around  8.5 days 
        At 730 days this formula gives a start size of around  17 days
      */
      const getClosest = (values, i) => {
        if (values.length === 0) {
          return [undefined];
        }
        if (values.length === 1) {
          return values;
        }
        const tmp = values.filter(
          (v) =>
            v.x.getTime() <
              dateMilli +
                ((timeSpan / windowSize) * timeConversionFactor) / i &&
            v.x.getTime() >
              dateMilli - ((timeSpan / windowSize) * timeConversionFactor) / i
        );
        return getClosest(tmp, ++i);
      };

      const getClosestDate = getClosest(val.values, 1)[0];

      return found
        ? {
            id: found.uniqueId,
            name: found.name,
            data_type: found.data_type,
            value: getClosestDate?.value,
            date: !getClosestDate
              ? undefined
              : formatEpochDifferenceToOriginal(
                  getClosestDate.x,
                  found.start_date
                ),
            color: found.color,
          }
        : {};
    });

    const ret = result ? (
      result.map(
        (val) =>
          val && (
            <ul
              key={val.id}
              className="overflow-hidden px-10 py-2 flex flex-col items-center justify-evenly font-semibold rounded w-full"
              style={{
                backgroundColor: val.color
                  ? chroma(val.color).brighten(2)
                  : "white",
                color: val.color ? chroma(val.color).darken(2) : "black",
                opacity: "95%",
              }}
            >
              <li key={val.id + "_1"} className="text-sm text-black">
                {" "}
                {val.name}{" "}
              </li>
              <li key={val.id + "_2"} className="text-lg text-black">
                {!val.value
                  ? ""
                  : valueFormatterTooltip(val.value, val.data_type, tempUnit)}
              </li>
              <li key={val.id + "_3"} className="text-sm text-black">
                {!val.date ? "no data" : fDateTime(val.date)}
              </li>
            </ul>
          )
      )
    ) : (
      <span>No data</span>
    );

    return (
      <div className="flex flex-col items-center justify-evenly shadow-lg rounded ">
        {ret}
      </div>
    );
  };

  const typeFormatter = useCallback(
    (metricType) => {
      switch (metricType) {
        case "temperature":
          return `${tempUnit === "fahrenheit" ? "(°F)" : "(°C)"}`;
        case "moisture":
          return `(%)`;
        case "salinity":
          return `(dS/m)`;
        case "conductivity":
          return `(dS/m)`;
        case "water_balance":
          return `(water balance)`;
        case "oxygen":
          return `(%)`;
        default:
          return;
      }
    },
    [tempUnit]
  );

  const showIconAndStartDate = (title, sources) => {
    const uniqueId = title.slice(-6);
    const source = sources?.find((s) => s.uniqueId === uniqueId);
    return (
      typeFormatter(source?.data_type) +
      " " +
      lightFormat(new Date(source?.start_date), "yyyy-MM-dd")
    );
  };

  const legendContent = useCallback(
    (title, color) => {
      return (
        "<div style='display:flex; flex-direction:row; flex-wrap:wrap; margin-right:8px;'><div style='margin-top:auto;" +
        "margin-bottom:auto; height:8px; width:8px; background-color:" +
        color +
        ";'></div><span style='margin-left:4px'>" +
        formatName(title) +
        " " +
        showIconAndStartDate(title, sources) +
        "</span></div>"
      );
    },
     // eslint-disable-next-line
    [sources]
  );

  const opts = useMemo(
    () => (
      {
      transition: { duration: 100 },
      padding: {
        top: 10,
        bottom: 10,
      },
      tooltip: haveMedium
        ? {
            contents: makeTooltip,
          }
        : {
            show: false,
          },
      data: {
        empty: {
          label: {
            text: "No Data",
          },
        },
        type: "line",
        columns: [],
        onover: function (d) {
          this.focus([d.id]);
        },
        onout: function (d) {
          this.focus();
          this.xgrids().length > 0 &&
            this.xgrids.remove({ class: "hover-line" });
        },
        onclick: function (d) {
          const mouseX = document.defaultView.event.clientX;
          const mouseY = document.defaultView.event.clientY;

          const getLimit = this.data().map((val) =>
            val.values.slice(-1)[0].x.getTime()
          );

          this.xgrids.add({ value: d.x, class: "hover-line" });

          const positionLeft = d.x.getTime() > Math.max(...getLimit) * 0.75;

          setCoordinates([mouseX, mouseY, positionLeft]);
          setAllData([d, this.data()]);

          haveMedium && this.tooltip.hide();
          toggleModal(true);
        },
      },
      zoom: {
        enabled: haveMedium ? zoom() : false,
        type: "drag",
        resetButton: false,
        onzoomend: (d) => {
          domain = d;
          setTimeout(() => levelOfDetail && levelOfDetail.zoom(d), 250);
        },
      },
      legend: {
        show: true,
        contents: {
          bindto: "#legend",
          template: legendContent,
        },
      },
      axis: {
        x: {
          ...(xAxisMinMax ? xAxisMinMax : { min: 0 }),
          tick: {
            fit: false,
            culling: {
              max: haveMedium ? 8 : 4,
            },
            width: 60,
            multiline: true,
            outer: true,
            format: (x) =>         
              formatXAxisDuration(
                x,
                domain ? domain : [
                  new Date("Thu Jan 01 1970 00:00:00"),
                  addDays(new Date("Thu Jan 01 1970 00:00:00"), timeSpan),
                ],
                currentLocale
              )
          },
          type: "timeseries",
        },
        y: {
          padding: {
            bottom: 0,
          },
        },
        y2: {
          show: true,
          padding: {
            top: 0,
            bottom: 0,
          },
        },
      },
      spline: {
        interpolation: {
          type: "catmull-rom",
        },
      },
      point: {
        r: 0,
      },
    }),
    [
      haveMedium,
      makeTooltip,
      xAxisMinMax,
      toggleModal,
      timeSpan,
      levelOfDetail,
      legendContent,
      currentLocale
    ]
  );

  const hasSources = sources?.length > 0;
  const previousAction = usePrevious(latestAction, undefined);

  const allowAction = (latest, previous) =>
    latest &&
    previous &&
    (latest.action !== previous.action || latest.action === "remove");

  useLayoutEffect(() => {
    if (!graphRef.current) {
      selection();
      line();
      bar();

      graphRef.current = bb.generate({
        ...opts,
        bindto: bbRef.current,
      });
    }

    const chart = graphRef.current;
    const axisSorter = yAxisSorter();

    if (allowAction(latestAction, previousAction)) {
      actionHandler(latestAction, chart, axisSorter);

      !hasSources && chart.unzoom();
    }
    if (xAxisMinMax) {
      chart.config("axis.x.max", xAxisMinMax.max);
      chart.config("axis.x.min", xAxisMinMax.min);
    }
    if (hasSources && haveMedium) {
      chart.config("tooltip.contents", makeTooltip);
    }

    chart.config("legend.contents.template", legendContent);
  }, [
    previousAction,
    haveMedium,
    latestAction,
    makeTooltip,
    opts,
    hasSources,
    xAxisMinMax,
    timeSpan,
    legendContent,
  ]);

  const customStyles = useMemo(
    () => ({
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      content: {
        top: coordinates[1] + "px",
        left: coordinates[0] + "px",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: coordinates[2]
          ? "translate(-100%, -0%) scale(0.9)"
          : "scale(0.9)",
      },
    }),
    [coordinates]
  );

  return (
    <>
      <div
        style={{
          marginTop: ".2rem",
          marginBottom: ".2rem",
          marginRight: "-1.2rem",
          marginLeft: "-1.6rem",
        }}
        ref={bbRef}
      />

      <div id="legend" className="flex flex-row flex-wrap justify-center" />
      <Modal style={customStyles} className="modal-content w-auto">
        <MakeBigTooltip
          values={allData}
          sources={sources}
          tempUnit={tempUnit}
        />
      </Modal>
    </>
  );
}
