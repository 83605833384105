import * as d3 from "d3-array";

export const groupsHaveDevice = (groups, protocol) => 
    groups.length > 0 && groups.some(group => group.devices_protocol_versions.includes(protocol));

export const scoutsHaveDevice = (scouts, protocol) => 
    scouts.length > 0 && scouts.some(scout => scout.protocol_version === protocol);

export const allGroupsHaveOnlyDevice = (groups, protocol) =>
    groups.length > 0 && groups.every(group => group.devices_protocol_versions.length === 1 && group.devices_protocol_versions.includes(protocol));

export const allScoutsHaveOnlyDevice = (scouts, protocol) =>
    scouts.length > 0 && scouts.every(scout => scout.protocol_version === protocol);

//Calculate the min and max values for the scouts. This is used for gauges and graphs.
export const calculateScoutMinMaxValues = (scouts) => {
    if (!scouts) return undefined;

    return Object.assign(
      {},
      ...["moisture", "temperature", "salinity"].map((name) => {
        const measList = scouts.map((scout) =>
          scout.last_measurement ? scout.last_measurement[name] : null
        );
        return {
          [name]: { min: d3.min(measList), max: d3.max(measList) },
        };
      })
    );
  }



// eslint-disable-next-line import/no-anonymous-default-export
export default {
    groupsHaveDevice,
    scoutsHaveDevice,
    allGroupsHaveOnlyDevice,
    allScoutsHaveOnlyDevice,
    calculateScoutMinMaxValues,
}

