import React, { useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AlertsContext, { AlertsProvider } from "../state/AlertsContext";
import { DeviceContext, DeviceProvider } from "../state/DeviceContext";
import { ErrorContext, ErrorProvider } from "../state/ErrorContext";
import DeviceGroupContext, {
  DeviceGroupProvider,
} from "../state/DeviceGroupContext";
import NotificationsContext, {
  NotificationsProvider,
} from "../state/NotificationsContext";
import { SiteContext } from "../state/SiteContext";
import AlertsView from "./AlertsView";
import DeviceView from "./DeviceView";
import Loader from "./Loader";
import Navigation from "./Navigation";
import ScoutView from "./ScoutView";
import SiteDetails from "./SiteDetails";
import SiteMap from "./SiteMap";
import { FormattedMessage } from "react-intl";
import { DashProvider } from "../state/DashContext";
import AnalysisView from "./analysis-studio/AnalysisView";
import { AnalysisProvider } from "../state/AnalysisContext";

const ErrorNotification = ({ error }) => {
  return (
    <div className="text-red-700 border-red-400 bg-red-100 border rounded p-6 text-md mt-8">
      <FormattedMessage id={`error.${error.message}`} values={{ br: <br /> }} />
    </div>
  );
};

const MainLoader = ({ children }) => {
  const { notifications } = useContext(NotificationsContext);
  const { rules } = useContext(AlertsContext);
  const { devices } = useContext(DeviceContext);
  const { groups } = useContext(DeviceGroupContext);
  const { error } = useContext(ErrorContext);

  const initialized = ![notifications, rules, devices, groups].some(
    (entities) => entities === undefined
  );

  return initialized ? (
    <> {children}</>
  ) : (
    <div className="col-span-2 row-span-2 m-auto">
      <div className="flex-col">
        <Loader />
        {error && <ErrorNotification error={error} />}
      </div>
    </div>
  );
};

export default function Dashboard() {
  const { url, path } = useRouteMatch();
  const { sites, currentSite } = useContext(SiteContext);

  return (
    <>
      {sites === undefined ? null : currentSite === undefined ? (
        <Redirect to="/sites" />
      ) : (
        <AlertsProvider site={currentSite}>
          <NotificationsProvider site={currentSite}>
            <ErrorProvider>
              <DeviceProvider>
                <DeviceGroupProvider>
                  <DashProvider>
                    <MainLoader>
                      <AnalysisProvider>
                        <Navigation />
                        <Switch>
                          <Route path={`${path}/scouts`}>
                            <ScoutView site={currentSite} />
                          </Route>

                          <Route path={`${path}/analyses`}>
                            <AnalysisView site={currentSite} />
                          </Route>
                          <Route path={`${path}/devices`}>
                            <DeviceView site={currentSite} />
                          </Route>
                          <Route path={`${path}/settings`}>
                            <SiteDetails />
                          </Route>
                          <Route path={`${path}/map`}>
                            <SiteMap />
                          </Route>
                          <Route path={`${path}/alerts`}>
                            <AlertsView site={currentSite} />
                          </Route>
                          <Route>
                            <Redirect to={`${url}/scouts`} />
                          </Route>
                        </Switch>
                      </AnalysisProvider>
                    </MainLoader>
                  </DashProvider>
                </DeviceGroupProvider>
              </DeviceProvider>
            </ErrorProvider>
          </NotificationsProvider>
        </AlertsProvider>
      )}
    </>
  );
}
