import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "../state/AuthContext";
import State from "../state/State";
import LoginView from "./LoginView";
import SingleSignOnView from "./SingleSignOnView";
import Main from "./Main";
import ReactGA from "react-ga4";
import { useClearCache } from "react-clear-cache";

// Initialize Google Analytics
const trackingId = "G-QER9BZ0LNX";
const isDebugMode = process?.env?.NODE_ENV === "development";
ReactGA.initialize(trackingId, { testMode: isDebugMode });

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  if (!isLatestVersion) {
    emptyCacheStorage();
  }

  return (
    <>
      <State>
        <ToastContainer closeButton={false} position="bottom-right" />
        <Switch>
          <Route path="/sso">
            <SingleSignOnView />
          </Route>
          <Route path="/login">
            <LoginView />
          </Route>
          <Route>
            <AuthContext.Consumer>
              {({ isAuthenticated }) =>
                isAuthenticated ? <Main /> : <Redirect to="/login" />
              }
            </AuthContext.Consumer>
          </Route>
        </Switch>
      </State>
    </>
  );
};

export default App;
