import React, { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { mergeObjects } from "../utility/common";
import { UserContext } from "./UserContext";

const DashContext = React.createContext();

const DashProvider = ({ children }) => {
  const { currentUser, crud } = useContext(UserContext);

  const handleAsyncError = (err) => {
    console.error("Dashboard settings error", err);
  };

  const validationSchema = useCallback(
    () =>
      yup.object().shape({
        ui_settings: yup.object().shape({
          order: yup.array().of(yup.string().required()).required(),
          cards: yup.object().shape({
            overview: yup
              .object()
              .shape({
                visible: yup.bool(),
              })
              .required(),
            water_balance: yup
              .object()
              .shape({
                visible: yup.bool(),
              })
              .required(),
            moisture: yup
              .object()
              .shape({
                visible: yup.bool(),
              })
              .required(),
            temperature: yup
              .object()
              .shape({
                visible: yup.bool(),
              })
              .required(),
            salinity: yup
              .object()
              .shape({
                visible: yup.bool(),
              })
              .required(),
          }),
        }),
      }),
    []
  );

  const updateSettings = useCallback(
    (obj) => {
      let oldUiSettings = currentUser.ui_settings ?? {};
      const newUiSettings = {
        ui_settings: mergeObjects(oldUiSettings, obj),
      };
      validationSchema()
        .validate(newUiSettings)
        .catch((error) => {
          toast.error(`${error.name}: ${error.errors}. Please contact support.`)
          return error
        })
        .then((result) => {
          if (result?.name === "ValidationError") {
            return
          }
          crud
            .update(Object.assign(currentUser, newUiSettings))
            .then()
            .catch(handleAsyncError)
        })
        .catch(handleAsyncError);
    },
    [crud, currentUser, validationSchema]
  );

  return (
    <DashContext.Provider
      value={{
        updateSettings,
        currentUser,
        uiSettings: currentUser.ui_settings,
      }}
    >
      {children}
    </DashContext.Provider>
  );
};

export { DashContext, DashProvider };
