import classNames from "classnames";
import { loadModules, setDefaultOptions } from "esri-loader";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiLayers } from "react-icons/fi";
import { FormattedMessage, useIntl, IntlProvider } from "react-intl";
import base_OK from "../assets/icons/map-pin-base-blue.png";
import base_NOT_CONNECTED from "../assets/icons/map-pin-base-red.png";
import base_WAITING from "../assets/icons/map-pin-base-yellow.png";
import echo_OK from "../assets/icons/map-pin-echo-blue.png";
import echo_NOT_CONNECTED from "../assets/icons/map-pin-echo-red.png";
import echo_WAITING from "../assets/icons/map-pin-echo-yellow.png";
import hydra_OK from "../assets/icons/map-pin-scout-blue.png";
import hydra_NOT_CONNECTED from "../assets/icons/map-pin-scout-red.png";
import hydra_WAITING from "../assets/icons/map-pin-scout-yellow.png";
import useLocalStorage from "../hooks/useLocalStorage";
import { celciusToFahrenheit } from "../localization";
import { DeviceContext } from "../state/DeviceContext";
import { SiteContext } from "../state/SiteContext";
import { UserContext } from "../state/UserContext";
import DashContent from "./DashContent";
import DashHeader from "./DashHeader";
import NDMIControl from "./NDMIWidget";
import { SectionHeading } from "./Headings";
import { IconCheck } from "./Icons";
import Loader from "./Loader";
import Popup, { PopupSection } from "./Popup";
import ReactDOM from 'react-dom';


const deviceSchema = [
  { name: "id", type: "integer" },
  { name: "name", type: "string" },
  { name: "last_seen", type: "string" },
  { name: "latitude", type: "string" },
  { name: "longitude", type: "string" },
  { name: "device_status", type: "string" },
  { name: "device_type", type: "string" },
  { name: "voltage_battery", type: "string" },
  { name: "voltage_external", type: "string" },
  { name: "localized_status", type: "string" },
];

const scoutSchema = deviceSchema.concat([
  { name: "moisture", type: "double" },
  { name: "temperature", type: "double" },
  { name: "salinity", type: "double" },
  { name: "water_balance", type: "double" },
]);

const batteryContent = (intl) => "<b>" + intl.formatMessage({id:"map.tooltip.battery_voltage"}) + " (V)</b>: {voltage_battery}<br><br>"

/*[
  {
    type: "fields",
    fieldInfos: [
      {
        fieldName: "voltage_battery",
        label: "Battery voltage (V)",
      },
    ],
  },
];*/

const externalContent = "<b>External voltage (V)</b>: {voltage_external}<br>"

/*[
  {
    type: "fields",
    fieldInfos: [
      {
        fieldName: "voltage_external",
        label: "External voltage (V)",
      },
    ],
  },
];*/

const externalAndBatteryContent = externalContent.concat(batteryContent);
/*[
  {
    ...batteryContent[0],
    fieldInfos: batteryContent[0].fieldInfos.concat(
      externalContent[0].fieldInfos[0]
    ),
  },
];*/
/*
const deviceContent = "Last seen on {last_seen}<br><br>" +
  //'<a href="http://localhost:3000/sites/270/scouts/view?from=2021-10-07T08%3A02%3A34.321Z&groups=&scouts={id}_{id}&sort=name&to=2021-10-14T08%3A02%3A34.321Z" target="_self" style="color: blue;">Show in device list</a><br><br>' +
  "<b>Latitude</b>: {latitude}<br>" +
  "<b>Longitude</b>: {longitude}<br><br>";
*/

const deviceContent = (intl) =>
  intl.formatMessage({id:"map.tooltip.last_seen"}) + " on {last_seen}<br><br>" +
  "<b>" + intl.formatMessage({id:"map.tooltip.latitude"}) + "</b>: {latitude}<br>" +
  "<b>" + intl.formatMessage({id:"map.tooltip.longitude"}) + "</b>: {longitude}<br><br>";

/*[
  {
    type: "text",
    text: "Last seen on {last_seen}.",
  },
  {
    type: "fields",
    fieldInfos: [
      {
        fieldName: "latitude",
        label: "Latitude",
      },
      { fieldName: "longitude", label: "Longitude" },
    ],
  },
];*/

const scoutContent = (fahrenheit, intl) => 
  "<b>" + intl.formatMessage({id:"map.tooltip.moisture"}) + " (%)</b>: {moisture}<br>" +
  "<b>" + intl.formatMessage({id:"map.tooltip.temperature"}) + "</b> " + (fahrenheit ? "(°F)" : "(°C)") + ": {temperature}<br>" +
  "<b>" + intl.formatMessage({id:"map.tooltip.salinity"}) + " (dS/m)</b>: {salinity}<br><br>"

/*[
  {
    type: "fields",
    fieldInfos: [
      {
        fieldName: "moisture",
        label: "Moisture (%)",
      },
      {
        fieldName: "temperature",
        label: "Temperature " + (fahrenheit ? "(°F)" : "(°C)"),
      },
      {
        fieldName: "salinity",
        label: "Salinity (dS/m)",
      },
    ],
  },
];*/

const symbolInfo = {
  type: "picture-marker",
  height: "64px",
  width: "64px",
  yoffset: "32px",
};

/*const symbolInfo_variables = {
  type: "picture-marker",
  height: "55px",
  width: "39px",
  yoffset: "32px",
};*/

const path_marker = {
  type: "simple-marker",
  style: "path",
  path: "M76.51,5.13c39.77-.19,70.56,30.69,70.77,70.38.11,20.23-7.09,37.1-21.28,51.36-14.62,14.7-27.53,30.76-36.9,49.42A83.17,83.17,0,0,0,81,200.36c-.42,2.57-1.59,4.62-4.68,4.64s-4.28-2-4.76-4.55c-4-21.54-15.51-39.18-29-55.67-7.7-9.37-16.74-17.68-24-27.36C-13.31,74.83,14.32,11.32,67.15,5.17A81.15,81.15,0,0,1,76.51,5.13Z",
  angle: -360,
  yoffset: "32px",
  size: 39
};



const connectivity_renderer = {
  type: "unique-value",
  field: "device_type",
  field2: "device_status",
  fieldDelimiter: ", ",
  visualVariables: [
    {
      type: "size",
      valueExpression: "$view.scale",
      stops: [
        {
          size: 64,
          value: 0,
        },
        {
          size: 32,
          value: 3000,
        },
        {
          size: 24,
          value: 5000,
        },
      ],
    },
  ],
  uniqueValueInfos: [
    {
      value: "hydra, NOT_CONNECTED",
      label: "Hydra",
      symbol: {
        ...symbolInfo,
        url: hydra_NOT_CONNECTED,
      },
    },
    {
      value: "base, NOT_CONNECTED",
      label: "Base",
      symbol: {
        ...symbolInfo,
        url: base_NOT_CONNECTED,
      },
    },
    {
      value: "echo, NOT_CONNECTED",
      label: "Echo",
      symbol: {
        ...symbolInfo,
        url: echo_NOT_CONNECTED,
      },
    },
    {
      value: "hydra, WAITING",
      label: "Hydra",
      symbol: {
        ...symbolInfo,
        url: hydra_WAITING,
      },
    },
    {
      value: "base, WAITING",
      label: "Base",
      symbol: {
        ...symbolInfo,
        url: base_WAITING,
      },
    },
    {
      value: "echo, WAITING",
      label: "Echo",
      symbol: {
        ...symbolInfo,
        url: echo_WAITING,
      },
    },
    {
      value: "hydra, OK",
      label: "Hydra",
      symbol: {
        ...symbolInfo,
        url: hydra_OK,
      },
    },
    {
      value: "base, OK",
      label: "Base",
      symbol: {
        ...symbolInfo,
        url: base_OK,
      },
    },
    {
      value: "echo, OK",
      label: "Echo",
      symbol: {
        ...symbolInfo,
        url: echo_OK,
      },
    },
  ],
}

const moisture_renderer = {
  type: "class-breaks",  // autocasts as new ClassBreaksRenderer()
  //field: "water_balance",
  valueExpression: "$feature.water_balance/100",
  defaultSymbol: {
    ...path_marker,
    color: [238, 238, 238, 1],
  },
  visualVariables: [
    {
      type: "size",
      valueExpression: "$view.scale",
      stops: [
        {
          size: 64,
          value: 0,
        },
        {
          size: 32,
          value: 3000,
        },
        {
          size: 24,
          value: 5000,
        },
      ],
    },
  ],
  classBreakInfos: [
    {
      minValue: -5,  // 0 acres
      maxValue: 0.25,  // 200,000 acres
      symbol: {
        ...path_marker,
        color: [255, 0, 0, 1],
      },  // will be assigned sym1
      label: "< 33"
    }, {
      minValue: 0.25,  // 200,001 acres
      maxValue: 0.50,  // 500,000 acres
      symbol: {
        ...path_marker,
        color: [241, 194, 50, 1],
      },  // will be assigned sym2
      label: "33 - 66"
    }, {
      minValue: 0.50,  // 500,001 acres
      maxValue: 5,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: [66, 133, 244, 1],
      },  // will be assigned sym2
      label: "> 66"
    }
  ]
};

// #9e0142|#d53e4f|#f46d43|#fdae61|#fee08b|#e6f598|#abdda4|#66c2a5|#3288bd|#5e4fa2
// rgba(158, 1, 66, 1),rgba(213, 62, 79, 1),rgba(244, 109, 67, 1),rgba(253, 174, 97, 1),rgba(254, 224, 139, 1),rgba(230, 245, 152, 1),rgba(171, 221, 164, 1),rgba(102, 194, 165, 1),rgba(50, 136, 189, 1),rgba(94, 79, 162, 1)
const temperature_colors = ["rgba(158, 1, 66, 1)", "rgba(213, 62, 79, 1)", "rgba(244, 109, 67, 1)", "rgba(253, 174, 97, 1)", "rgba(254, 224, 139, 1)", "rgba(230, 245, 152, 1)", "rgba(171, 221, 164, 1)", "rgba(102, 194, 165, 1)", "rgba(50, 136, 189, 1)", "rgba(94, 79, 162, 1)"];

const temperature_renderer = {
  type: "class-breaks",  // autocasts as new ClassBreaksRenderer()
  field: "temperature",
  defaultSymbol: {
    ...path_marker,
    color: [238, 238, 238, 1],
  },
  visualVariables: [
    {
      type: "size",
      valueExpression: "$view.scale",
      stops: [
        {
          size: 64,
          value: 0,
        },
        {
          size: 32,
          value: 3000,
        },
        {
          size: 24,
          value: 5000,
        },
      ],
    },
  ],
  classBreakInfos: [
    {
      minValue: -50,  // 0 acres
      maxValue: 0,  // 200,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[10],
      },  // will be assigned sym1
      label: "< 33"
    }, {
      minValue: 0,  // 200,001 acres
      maxValue: 5,  // 500,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[9],
      },  // will be assigned sym2
      label: "33 - 66"
    }, {
      minValue: 5,  // 500,001 acres
      maxValue: 10,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[8],
      },  // will be assigned sym2
      label: "> 66"
    }, {
      minValue: 10,  // 200,001 acres
      maxValue: 15,  // 500,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[7],
      },  // will be assigned sym2
      label: "33 - 66"
    }, {
      minValue: 15,  // 500,001 acres
      maxValue: 20,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[6],
      },  // will be assigned sym2
      label: "> 66"
    }, {
      minValue: 20,  // 200,001 acres
      maxValue: 25,  // 500,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[5],
      },  // will be assigned sym2
      label: "33 - 66"
    }, {
      minValue: 25,  // 500,001 acres
      maxValue: 30,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[4],
      },  // will be assigned sym2
      label: "> 66"
    }, {
      minValue: 30,  // 200,001 acres
      maxValue: 35,  // 500,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[3],
      },  // will be assigned sym2
      label: "33 - 66"
    }, {
      minValue: 35,  // 500,001 acres
      maxValue: 40,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[2],
      },  // will be assigned sym2
      label: "> 66"
    }, {
      minValue: 40,  // 200,001 acres
      maxValue: 45,  // 500,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[1],
      },  // will be assigned sym2
      label: "33 - 66"
    }, {
      minValue: 45,  // 500,001 acres
      maxValue: 50,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: temperature_colors[0],
      },  // will be assigned sym2
      label: "> 66"
    }
  ]
};

// #300073|#43048d|#5608a8|#690cc2|#7c10dd|#8f14f7|#9f29ee|#b63df4|#ce52f9|#e566ff
const salinity_colors = ["#300073", "#43048d", "#5608a8", "#690cc2", "#7c10dd", "#8f14f7", "#9f29ee", "#b63df4", "#ce52f9", "#e566ff"];

const salinity_renderer = {
  type: "class-breaks",  // autocasts as new ClassBreaksRenderer()
  field: "salinity",
  defaultSymbol: {
    ...path_marker,
    color: [238, 238, 238, 1],
  },
  visualVariables: [
    {
      type: "size",
      valueExpression: "$view.scale",
      stops: [
        {
          size: 64,
          value: 0,
        },
        {
          size: 32,
          value: 3000,
        },
        {
          size: 24,
          value: 5000,
        },
      ],
    },
  ],
  classBreakInfos: [
    {
      minValue: 0,  // 500,001 acres
      maxValue: 1,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: salinity_colors[9],
      },  // will be assigned sym2
      label: "> 66"
    }, {
      minValue: 1,  // 500,001 acres
      maxValue: 2,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: salinity_colors[5],
      },  // will be assigned sym2
      label: "> 66"
    }, {
      minValue: 2,  // 500,001 acres
      maxValue: 5,  // 750,000 acres
      symbol: {
        ...path_marker,
        color: salinity_colors[0],
      },  // will be assigned sym2
      label: "> 66"
    }
  ]
};



const chooseContent = (feature, fahrenheit, intl) => {
  const { device_type } = feature.graphic.attributes;
  if (device_type === "hydra") {
    //deviceContent
    //.concat(scoutContent(fahrenheit))
    // .concat(batteryContent);
    //console.log(deviceContent);
    //var temp_content = deviceContent.concat(scoutContent(fahrenheit)).concat(batteryContent);
    return deviceContent(intl).concat(scoutContent(fahrenheit, intl)).concat(batteryContent(intl));
  } else if (device_type === "echo") {
    return deviceContent.concat(externalAndBatteryContent);
  } else {
    return deviceContent.concat(externalContent);
  }
};

const maybeNum = (intl, num) =>
  num ? intl.formatNumber(num, { maximumFractionDigits: 2 }) : null;

const getDeviceAttrs = (device, intl) => ({
  id: device.id,
  name: device.name,
  last_seen: intl.formatDate(new Date(device.last_seen), {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }),
  latitude: device.location.latitude.toString(),
  longitude: device.location.longitude.toString(),
  device_type: device.device_type,
  device_status: device.device_status,
  voltage_battery: maybeNum(intl, device.voltage_battery),
  voltage_external: maybeNum(intl, device.voltage_external),
  localized_status: intl.formatMessage({ id: `device.status.${device.device_status.toString().toLowerCase()}` })
});

const getScoutAttrs = (scout, intl, fahrenheit) => {
  return {
    moisture: maybeNum(intl, scout.last_measurement?.moisture * 100),
    water_balance: maybeNum(intl, scout.last_measurement?.water_balance*100),
    
    temperature: maybeNum(
      intl,
      fahrenheit
        ? celciusToFahrenheit(scout.last_measurement?.temperature)
        : scout.last_measurement?.temperature
    ),
    salinity: maybeNum(intl, scout.last_measurement?.salinity),
    localized_status: intl.formatMessage({ id: `device.status.${scout.device_status.toString().toLowerCase()}` })
  };
};

const createDeviceLayer = (scouts, devices, FeatureLayer, intl, fahrenheit) => {
  const scoutFeatures = scouts
    .filter((scout) => scout.location.latitude && scout.location.longitude)
    .map((scout) => ({
      geometry: {
        type: "point",
        latitude: scout.location.latitude,
        longitude: scout.location.longitude,
      },
      attributes: {
        ...getDeviceAttrs(scout, intl),
        ...getScoutAttrs(scout, intl, fahrenheit),
      },
    }));

  const deviceFeatures = devices
    .filter((device) => device.location.latitude && device.location.longitude)
    .map((device) => ({
      geometry: {
        type: "point",
        latitude: device.location.latitude,
        longitude: device.location.longitude,
      },
      attributes: {
        ...getDeviceAttrs(device, intl)
      },
    }));

  const source = scoutFeatures
    .concat(deviceFeatures)
    .sort((a, b) =>
      Number(a.geometry.latitude) < Number(b.geometry.latitude) ? -1 : 1
    )
    .map((obj, idx) => {
      return {
        ...obj,
        attributes: { ...obj.attributes, objectID: idx},
      };
    })
    .reverse();




  const layer = new FeatureLayer({
    source,
    id: "scoutLayer",
    objectIdField: "objectID",
    fields: scoutSchema,
    popupTemplate: {
      title: "<b>{Name}</b>",
      content: (feature) => chooseContent(feature, fahrenheit, intl),
      //content:deviceContent,
      outFields: ["*"],
    },
    labelingInfo: [
      {
        labelExpressionInfo: {
          expression: "'" + intl.formatMessage({id:'map.label.status'}) + ": ' + $feature.localized_status"//"'" + intl.formatMessage({id:'map.label.status'}) + ": ' + '" + intl.formatMessage({id:'device.status.' + "$feature.device_status"}) + "'"
        },
        labelPlacement: "below-center",

        symbol: {
          type: "text", // autocasts as new TextSymbol()
          font: {
            size: 9,
            family: "Noto Sans"
          },
          horizontalAlignment: "left",
          color: "#ffffff"
        }
      }
    ],
    renderer: connectivity_renderer
  });
  return layer;
};

const SiteMap = (props) => {
  const [mapFunctions, setMapFunctions] = useLocalStorage("map-widgets", {
    measurementWidget: false,
    areaMeasurementWidget: false,
    searchWidget: false,
    galleryWidget: false,
    //elevationProfileWidget: false,
  });
  const [mapWidgets, setMapWidgets] = useState();
  const { currentUser } = useContext(UserContext);
  const { currentSite } = useContext(SiteContext);
  const { scouts, networkDevices: devices, loading } = useContext(
    DeviceContext
  );
  const [view, setView] = useState(null);
  const intl = useIntl();
  const mapRef = useRef();
  const ndmi_node = document.createElement("div");
  ndmi_node.setAttribute("id", "ndmiDiv");

  const [ndmiHidden, setNDMI] = useState(false);
  
  const toggleExternalLayer = (visibilityBoolean, layerId) => {
    setNDMI(visibilityBoolean);
    var ndviLayer = view.map.findLayerById(layerId); 
    
    if (ndmiHidden) {
      ndviLayer.visible = false;
      var ndmiElement = document.getElementById('ndmiDiv');
      ndmiElement.remove();
      view.ui.remove(ndmi_node);
    } else {
      ndviLayer.visible = true;
      view.ui.add(ndmi_node, "bottom-right");
      ReactDOM.render(
        <IntlProvider intl={intl}>
          <NDMIControl view={view}/>
        </IntlProvider>
       , ndmi_node);
    
    }
  }

  


  const fahrenheit = currentUser.pref_unit_temp === "fahrenheit";

  const changeRenderer = (renderKeyword,fahrenheit) => {
    var temperatureLabel = "";
    if (fahrenheit){
      temperatureLabel = "°F";
    }
    else{
      temperatureLabel = "°C";
    }

    var scoutLayer = view.map.findLayerById('hydraLayer');
    if (renderKeyword === 'moisture') {
      scoutLayer.renderer = moisture_renderer;
      scoutLayer.labelingInfo[0].labelExpressionInfo.expression = "'" + intl.formatMessage({id:'map.tooltip.moisture'}) + "' +  $feature.moisture + '%'";
    }
    if (renderKeyword === 'connectivity') {
      scoutLayer.renderer = connectivity_renderer;
      scoutLayer.labelingInfo[0].labelExpressionInfo.expression = "'" + intl.formatMessage({id:'map.label.status'}) + ": ' +  $feature.localized_status";
    }
    if (renderKeyword === 'temperature') {
      scoutLayer.renderer = temperature_renderer;
      scoutLayer.labelingInfo[0].labelExpressionInfo.expression = "'" + intl.formatMessage({id:'map.tooltip.temperature'}) + "' +  $feature.temperature + '" + temperatureLabel + "'";
    }
    if (renderKeyword === 'salinity') {
      scoutLayer.renderer = salinity_renderer;
      scoutLayer.labelingInfo[0].labelExpressionInfo.expression = "'" + intl.formatMessage({id:'map.tooltip.salinity'}) + "' +  $feature.salinity + 'dS/m'";
    }

  }

  const functionToggle = ({ widget, titleId }) => (
    <button
      key={widget + "-toggle"}
      className={classNames(
        "px-4 flex justify-between" +
        (mapFunctions[widget] ? " text-scout-blue font-medium" : "")
      )}
      onClick={() => {
        setMapFunctions((funs) => ({
          ...funs,
          [widget]: !funs[widget],
        }));
      }}
    >
      <FormattedMessage id={titleId} />
      {mapFunctions[widget] && <IconCheck />}
    </button>
  );

  setDefaultOptions({
    css:'https://js.arcgis.com/4.19/esri/themes/light/main.css',
    version: "4.22",
    dojoConfig: {
      locale: "en",
      parseOnLoad: true,
    },
  });

  useEffect(() => {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    if (!loading) {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/layers/ImageryLayer",
        "esri/layers/support/RasterFunction",
        "esri/layers/support/MosaicRule",
        "esri/layers/TileLayer",
        "esri/widgets/DistanceMeasurement2D",
        "esri/widgets/AreaMeasurement2D",
        "esri/widgets/Legend",
        "esri/widgets/Search",
        "esri/widgets/BasemapGallery",
        "esri/widgets/ElevationProfile",
        "esri/config"
      ]).then(
        ([
          Map,
          MapView,
          FeatureLayer,
          ImageryLayer, RasterFunction, MosaicRule,
          TileLayer,
          DistanceMeasurement2D,
          AreaMeasurement2D,
          Legend,
          Search,
          BasemapGallery,
          ElevationProfile,
          esriConfig,
        ]) => {
          esriConfig.apiKey = "AAPK0e0f154d83ab48f79cd03712aea1eb5drMeIGKpe05kc4N_pwUdRN4afrkTyuCjGYDhZgivAfqaVAJUDrfdB8Y30GQ0kYrpi";
          if (view === null) {
            const map = new Map({
              basemap: "hybrid",
              ground: "world-elevation"
            });

            const view = new MapView({
              container: mapRef.current,
              map: map,
              center: [currentSite.longitude, currentSite.latitude],
              zoom: 17,
            });

            const deviceLayer = createDeviceLayer(
              scouts,
              devices,
              FeatureLayer,
              intl,
              fahrenheit
            );

            deviceLayer.definitionExpression = "device_type NOT LIKE '%hydra%'"

            const hydraLayer = createDeviceLayer(
              scouts,
              devices,
              FeatureLayer,
              intl,
              fahrenheit
            );
            hydraLayer.id='hydraLayer';
            hydraLayer.definitionExpression = "device_type LIKE '%hydra%'";
            hydraLayer.renderer = moisture_renderer;
            hydraLayer.labelingInfo[0].labelExpressionInfo.expression =   "'" + intl.formatMessage({id:'map.tooltip.moisture'}) + "' +  $feature.moisture + '%'"; 


            var serviceRFT = new RasterFunction({
              functionName: "NDVI Colormap",
              variableName: "Raster"
            });

            var mrule = new MosaicRule({

              method: "esriMosaicAttribute",
              where: "(datatype_format = 'Cloned') OR (datatype_format IS NULL)",
              sortField: "acquisitiondate",
              sortValue: "2021/05/24, 12:00 AM",
              ascending: true,
              operation: "MT_FIRST"
            });

            var ndvi_layer = new ImageryLayer({
              url: "https://sentinel.arcgis.com/arcgis/rest/services/Sentinel2/ImageServer",
              renderingRule: serviceRFT,
              opacity: 0.5,
              mosaicRule: mrule,
              id: 'NDMI',
              //definitionExpression: "acquisitiondate BETWEEN timestamp '2021-05-10 21:00:00' AND timestamp '2021-05-17 20:59:59'",
              format: "jpgpng", // server exports in either jpg or png format
              visible: false
            });

            

            map.add(ndvi_layer);
            map.add(deviceLayer);
            map.add(hydraLayer);

            let measurementWidget = new DistanceMeasurement2D({
              view: view,
              unit: "meters",
              
            });

            let searchWidget = new Search({
              view: view,
              includeDefaultSources: false,
              
              sources: [
                {
                  layer: deviceLayer,
                  searchFields: ["name"],
                  displayField: "name",
                  exactMatch: false,
                  outFields: ["*"],
                  name: "Devices",
                  placeholder: "Find device",
                  maxResults: 6,
                  maxSuggestions: 6,
                  suggestionsEnabled: true,
                  minSuggestCharacters: 0,
                },
              ],
              locationEnabled: false,
            });

            let galleryWidget = new BasemapGallery({
              view: view,
            });

            let areaMeasurementWidget = new AreaMeasurement2D({
              view,
              unit: currentUser.units === "si" ? "metric" : "imperial",
              
            });


            let elevationProfileWidget = new ElevationProfile({
              view: view,
              // configure widget with desired profile lines
              profiles: [
                {
                  // displays elevation values from Map.ground
                  type: "ground", //autocasts as new ElevationProfileLineGround()
                  color: "#61d4a4",
                  title: "Ground elevation"
                }
              ],
              // hide the select button
              // this button can be displayed when there are polylines in the
              // scene to select and display the elevation profile for
              visibleElements: {
                selectButton: false
              }
            });

            setMapWidgets({
              measurementWidget,
              searchWidget,
              galleryWidget,
              areaMeasurementWidget,
              elevationProfileWidget
            });

            setView(view);
            
          }

          return () => {
            if (view) {
              view.container = null;
            }
          };
        }
      );
    }
  }, [
    scouts,
    currentSite.latitude,
    currentSite.longitude,
    currentUser.units,
    devices,
    intl,
    view,
    loading,
    fahrenheit,
  ]);

  useEffect(() => {
    if (view) {
      Object.entries(mapFunctions).forEach(([name, display]) => {
        const widget = mapWidgets[name];
        if (display) {
          view.ui.add(widget, {
            position: "top-right",
            index: 2,
          });
        } else {
          if(name === "measurementWidget" || name === "areaMeasurementWidget"){
            widget.viewModel.clear();
          }
          view.ui.remove(widget);
        }
      });
    }
  }, [mapFunctions, mapWidgets, view]);

  return (
    <>
      <DashHeader span>
        <SectionHeading>
          <FormattedMessage id="view.title.map" />
        </SectionHeading>
        <div className="flex flex-row">
          <Popup className="mr-2"
            trigger={
              <button className="btn bg-white shadow mr-2">
                <FormattedMessage id="map.button.visualize" />
              </button>
            }>
            <PopupSection titleId={intl.formatMessage({id:'map.options.visual_variable'})}>
              <button
                key={"connectivity"}
                className={classNames(
                  "px-4 flex justify-betwee text-scout-black font-medium"
                )}
                onClick={() => {
                  changeRenderer("moisture",fahrenheit);
                }}
              >
                <FormattedMessage id="map.options.moisture" />
              </button>
              <button
                key={"connectivity"}
                className={classNames(
                  "px-4 flex justify-betwee text-scout-black font-medium"
                )}
                onClick={() => {
                  changeRenderer("temperature",fahrenheit);
                }}
              >
                <FormattedMessage id="map.options.temperature" />
              </button>
              <button
                key={"connectivity"}
                className={classNames(
                  "px-4 flex justify-betwee text-scout-black font-medium"
                )}
                onClick={() => {
                  changeRenderer("salinity",fahrenheit);
                }}
              >
                <FormattedMessage id="map.options.salinity" />
              </button>
              <button
                key={"connectivity"}
                className={classNames(
                  "px-4 flex justify-betwee text-scout-black font-medium"
                )}
                onClick={() => {
                  changeRenderer("connectivity",fahrenheit);
                }}
              >
                <FormattedMessage id="map.options.connectivity" />
              </button>



            </PopupSection>
          </Popup>

          <Popup className="w-56"
            trigger={
              <button className="btn bg-white shadow mr-2">
                <FormattedMessage id="map.button.external_info" />
              </button>
            }>
            <PopupSection titleId={intl.formatMessage({id:'map.options.layer'})}>
              {ndmiHidden ? (
              <button key={"NDVI"} 
              className={classNames(
                "px-4 flex justify-betwee text-scout-black font-medium"
              )}
              onClick={() => toggleExternalLayer(false, 'NDMI')}> <FormattedMessage id="map.options.ndvi" /> <IconCheck /></button>
              
            ) : (
              <button key={"NDVI"} 
              className={classNames(
                "px-4 flex justify-betwee text-scout-black font-medium"
              )}
              onClick={() => toggleExternalLayer(true, 'NDMI')}> <FormattedMessage id="map.options.ndvi" /> </button>
            )}

              
                
              
            </PopupSection>
          </Popup>

          <Popup
            className="w-56"
            trigger={
              <button className="btn bg-white shadow">
                <FiLayers />
              </button>
            }
          >
            <PopupSection titleId="map.functions">
              {[
                functionToggle({
                  widget: "searchWidget",
                  titleId: "map.functions.search",
                }),
                functionToggle({
                  widget: "measurementWidget",
                  titleId: "map.functions.measure",
                }),
                functionToggle({
                  widget: "areaMeasurementWidget",
                  titleId: "map.functions.areaMeasurement",
                }),
                functionToggle({
                  widget: "galleryWidget",
                  titleId: "map.functions.gallery",
                }),
                functionToggle({
                  widget: "elevationProfileWidget",
                  titleId: "map.functions.elevationProfile",
                }),
              ]}
            </PopupSection>
          </Popup>
        </div>
      </DashHeader>
      <DashContent noScroll span>
        {loading ? <Loader /> : <div ref={mapRef} className="w-full h-full " />}
              
      </DashContent>
    </>
  );
};

export default SiteMap;
