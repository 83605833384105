import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Table from "./../Table";
import { IconAnalyticsStudio } from "../Icons";
import AnalysisContext from "../../state/AnalysisContext";
import { format } from "date-fns";

const AnalysisList = ({ ...rest }) => {
  const { entities: analyses } = useContext(AnalysisContext);

  const columns = useMemo(
    () => [
      {
        id: "icon",
        className: "w-2/12 h-12",
        Header: null,
        Cell: () => {
          return (
            <IconAnalyticsStudio className="block m-auto w-6 text-scout-blue" />
          );
        },
      },
      {
        Header: <FormattedMessage id="table.header.name" />,
        accessor: "name",
        className: "w-6/12 pl-0 pr-2 h-12",
        sortIndicator: "alpha",
        Cell: (cell) => <p>{cell.value}</p>,
      },
      {
        Header: <FormattedMessage id="analysis.last_saved" />,
        accessor: "updated",
        className: "w-4/12 pl-0 pr-2 h-12",
        sortIndicator: "alpha",
        Cell: (cell) => {
          const date = new Date(cell.value);
          return <p>{format(date, "yyyy-MM-dd kk:mm:ss")}</p>;
        },
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={analyses || []}
      sortBy={useMemo(() => [{ id: "name", desc: false }], [])}
      {...rest}
    />
  );
};

export default AnalysisList;
