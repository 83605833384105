import React, { useMemo } from "react";
import * as yup from "yup";
import schemaFields from "../state/schema";
import { UserContext } from "../state/UserContext";
import Form from "./Form";
import { Fieldset, Input, LabeledWidget, UnitsFieldset, PlainSelect } from "./FormWidgets";
//import {useIntl } from "react-intl";

const ProfileForm = ({ user, creating }) => {
  const defaultValues = useMemo(
    () =>
      user
        ? { ...user }
        : {
            pref_unit_temp: "celsius",
            pref_unit_length: "metric",
          },
    [user]
  );
  //const intl = useIntl();

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "fi", label: "Suomi" },
    { value: "sv", label: "Svenska" },
    { value: "fr", label: "Français" },
    { value: "it", label: "Italiano" },
    { value: "es", label: "Español" }
  ];

  return (
    <Form
      defaultValues={defaultValues}
      context={UserContext}
      overrideValidationSchema={() =>
        yup.object().shape({
          first_name: yup
            .string()
            .required("validation.required")
            .meta({ label: "First name" }),
          last_name: yup
            .string()
            .required("validation.required")
            .meta({ label: "Last name" }),
          email: yup
            .string()
            .email("validation.email")
            .required("validation.required")
            .meta({ label: "E-mail", type: "email" }),
          pref_graph_smoothing: yup.boolean(),
          pref_unit_length: yup.string().oneOf(["metric", "imperial"]),
          pref_unit_temp: yup.string().oneOf(["celsius", "fahrenheit"]),
          password: schemaFields.nullableString,
          password_dacapo: schemaFields.nullableString.oneOf(
            [yup.ref("password")],
            "validation.passwords_no_match"
          ),
        })
      }
      transform={(formValues) => {
        if (!formValues.password) {
          delete formValues.password;
        }
        return {
          ...user,
          ...formValues,
        };
      }}
    >
      <LabeledWidget
        name="username"
        labelId="form.label.username"
        widget={<Input />}
        disabled
      />
      <Fieldset legendId="form.legend.name">
        <LabeledWidget
          name="first_name"
          labelId="form.label.first_name"
          widget={<Input />}
        />
        <LabeledWidget
          name="last_name"
          labelId="form.label.last_name"
          widget={<Input />}
        />
      </Fieldset>
      <LabeledWidget
        name="email"
        labelId="form.label.email"
        type="email"
        widget={<Input />}
      />
      <UnitsFieldset nameLength="pref_unit_length" nameTemp="pref_unit_temp" />
      <LabeledWidget
        name="language"
        labelId="form.label.language"
        widget={
          <PlainSelect>
            {languageOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </PlainSelect>
        }/>
      <Fieldset legendId="form.legend.change_password">
        <LabeledWidget
          widget={<Input />}
          name="password"
          labelId="form.label.new_password"
          required={user?.id === undefined}
          type="password"
          autoComplete="disabled"
        />
        <LabeledWidget
          widget={<Input />}
          name="password_dacapo"
          labelId="form.label.password_dacapo"
          required={user?.id === undefined}
          type="password"
          autoComplete="disabled"
        />
      </Fieldset>
    </Form>
  );
};

export default ProfileForm;
