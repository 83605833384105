import { getUnixTime } from "date-fns";
import jwt_decode from "jwt-decode";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { buildRequest } from "../api";

const jwtExpiresSec = (jwt) => jwt_decode(jwt).exp - getUnixTime(Date.now());
const jwtExpired = (jwt) => jwtExpiresSec(jwt) < 60;
const malformedToken = (token) => {
  if (token) {
    try {
      jwt_decode(token.access);
      jwt_decode(token.refresh);
      return false;
    } catch (err) {}
  }
  return true;
};

const sessionExpired = () =>
  toast.warning(<FormattedMessage id="logged_out.reason.refresh_expired" />);

var refreshPromise = undefined;

const getToken = () => {
  const stored = window.localStorage.getItem("auth");
  try {
    return stored ? JSON.parse(stored) : undefined;
  } catch (err) {
    return undefined;
  }
};

const setToken = (data) => {
  if (data) {
    window.localStorage.setItem("auth", JSON.stringify(data));
  } else {
    window.localStorage.removeItem("auth");
  }
};

const refreshToken = (token) => {
  if (!refreshPromise) {
    const newPromise = buildRequest("POST")
      .withPath("/auth/token/refresh/")
      .withBody({ refresh: token.refresh })
      .fetch()
      .then((token) => {
        setToken(token);
        return token;
      })
      .finally(() => (refreshPromise = undefined));
    refreshPromise = newPromise;
  }
  return refreshPromise;
};

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [authenticated, setAuthenticated] = useState();
  const [loading, setLoading] = useState(true);

  const login = (username, password) =>
    buildRequest("POST")
      .withPath("/auth/login/")
      .withBody({ username, password })
      .fetch()
      .then((token) => {
        setAuthenticated(() => {
          setToken(token);
          return true;
        });
      });

  const loginSSO = (token) =>
    buildRequest("POST")
      .withPath("/auth/token/sso/")
      .withBody({ sso_token: token })
      .fetch()
      .then((token) => {
        setAuthenticated(() => {
          setToken(token);
          return true;
        });
      });

  const logout = () => {
    setAuthenticated(() => {
      setToken(undefined);
      return false;
    });
  };

  const checkToken = useCallback(() => {
    const token = getToken();
    if (!malformedToken(token)) {
      if (jwtExpired(token.refresh)) {
        // If the refresh token is expired, we cannot recover
        logout();
        sessionExpired();
      } else if (jwtExpired(token.access)) {
        return refreshToken(token).catch(logout);
      } else {
        return Promise.resolve(token);
      }
    }
    return Promise.resolve(undefined);
  }, []);

  const authenticatedFetch = (request) =>
    checkToken()
      .then((token) => {
        if (token) {
          return request.withToken(token).fetch();
        }
      })
      .catch((err) => {
        if (err.status === 403 || err.status === 401) {
          logout();
          sessionExpired();
        } else {
          return Promise.reject(err);
        }
      });

  const resetPassword = (email) =>
    buildRequest("POST")
      .withPath("/users/reset_password/")
      .withBody({ email })
      .fetch();

  const verifyToken = useCallback(
    () =>
      // Making sure the token has not been tampered with
      checkToken().then((token) =>
        token
          ? buildRequest("POST")
              .withPath("/auth/token/verify/")
              .withBody({ token: token.refresh })
              .fetch()
              .then(() => token)
              .catch(() => undefined)
          : undefined
      ),
    [checkToken]
  );

  useEffect(() => {
    if (!authenticated) {
      // Checking token on initial page load
      verifyToken().then((token) => {
        setLoading(() => {
          if (token) {
            setAuthenticated(true);
          }
          return false;
        });
      });
    }
  }, [authenticated, verifyToken]);

  const decodedToken = authenticated
    ? jwt_decode(getToken().refresh)
    : undefined;

  return (
    <AuthContext.Provider
      value={{
        decodedToken,
        login,
        loginSSO,
        logout,
        loading,
        isAuthenticated: authenticated,
        authenticatedFetch,
        resetPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
