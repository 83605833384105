import React, { createContext, useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import DetailsCol from "./DetailsCol";
import Loader from "./Loader";

const DetailsContext = createContext();

const DetailsView = ({
  param,
  context,
  getTitle,
  linkTo,
  children,
  consentPrompt = false,
  ...rest
}) => {
  const params = useParams();
  const resource = useContext(context);

  const entity = resource?.entities?.find(
    ({ id }) => id === Number(params[param])
  );

  const loading = resource?.entities === undefined;
  const notFound = param in params && entity === undefined;
  const creating = entity?.id === undefined;

  return notFound ? (
    <Redirect to={linkTo} />
  ) : (
    <DetailsContext.Provider value={{ resource, entity, creating, linkTo }}>
      <DetailsCol linkTo={linkTo} title={getTitle(creating, entity)} consentPrompt={consentPrompt} {...rest}>
        {loading ? <Loader /> : <>{children}</>}
      </DetailsCol>
    </DetailsContext.Provider>
  );
};

export { DetailsView, DetailsContext };
