import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import Modal from "../components/Modal";

export const ModalContext = createContext();

export default function useModal(startVisible) {
  const [visible, setVisible] = useState(startVisible ? true : false);
  const [originalStyle] = useState(
    () => window.getComputedStyle(document.body).overflow
  );

  // Preventing scroll when modal visible
  useLayoutEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }
  }, [visible, originalStyle]);

  function toggle() {
    setVisible(!visible);
  }

  const Component = useCallback(
    ({ children, ...props }) => (
      <>
        <Modal
          onRequestClose={() => setVisible(false)}
          isOpen={visible}
          {...props}
        >
          <ModalContext.Provider value={{ close: () => setVisible(false) }}>
            {children}
          </ModalContext.Provider>
        </Modal>
      </>
    ),
    [visible]
  );

  return [Component, toggle];
}
