import React from "react";
import DashContent from "./DashContent";
import DashHeader from "./DashHeader";
import { SectionHeading } from "./Headings";
import Card from "./Card";
import { FormattedMessage } from "react-intl";
import help from "../assets/help.json";

const latest = help.sort((a, b) => b.version - a.version)[0];

const Help = ({ site }) => {
  return (
    <>
      <DashHeader span>
        <SectionHeading>
          <FormattedMessage id="view.title.help" />
        </SectionHeading>
      </DashHeader>
      <DashContent noScroll span>
        <Card className="modal-content max-w-5xl">
            <iframe
              className="h-screen max-h-1/2-vh w-full"
              title={latest["version"]}
              src={latest["source"]}
            />
        </Card>
      </DashContent>
    </>
  );
};

export default Help;
