import { number, string } from "yup";

const trimString = (val) => {
  return val ? val.trim() : "";
};

const validNumber = number().typeError("validation.number");

const uniqueString = (arr, entity, field) =>
  string()
    .required("validation.required")
    .test(
      "is-unique",
      "validation.unique",
      (value) =>
        !arr.some(
          (obj) =>
            (entity ? obj.id !== entity.id : true) && obj[field] === value
        )
    );

const nullableString = string()
  .nullable()
  .transform((value, originalValue) =>
    trimString(originalValue) === "" ? null : value
  );

const latitude = validNumber
  .nullable()
  .min(-90, "validation.latitude")
  .max(90, "validation.latitude")
  .test("len", "validation.latlon.len", (val) => {
    if (val) {
      const arr = String(val).split(".");
      if (arr.length > 1) {
        return arr[1].length <= 6;
      }
    }
    return true;
  })
  .test("latlon-both", "validation.latlon.both", function (val) {
    return this.parent.longitude ? val : true;
  })
  .meta({ help: "form.help.latlon" });

const longitude = validNumber
  .nullable()
  .min(-180, "validation.longitude")
  .max(180, "validation.longitude")
  .test("len", "validation.latlon.len", (val) => {
    if (val) {
      const arr = String(val).split(".");
      if (arr.length > 1) {
        return arr[1].length <= 6;
      }
    }
    return true;
  })
  .test("latlon-both", "validation.latlon.both", function (val) {
    return this.parent.latitude ? val : true;
  })
  .meta({ help: "form.help.latlon" });

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  validNumber,
  latitude,
  longitude,
  uniqueString,
  nullableString,
  trimString,
};
