import { useRef, useLayoutEffect } from "react";

export const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useLayoutEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
