import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ModalContext } from "../hooks/useModal";
import Card from "./Card";

const Confirmation = ({ title, message, onDelete, consentPrompt }) => {
  const { close } = useContext(ModalContext);

  return (
    <Card className='mx-auto ' title={title} action={close}>
      <div className={consentPrompt ? "h-48" : "h-32"}>
        {message}
        {consentPrompt && consentPrompt[1]}
      </div>
      <div className='flex'>
        <button className='btn btn-blue w-1/2' onClick={close} autoFocus>
          <FormattedMessage id='dialog.cancel' />
        </button>
        <button
          disabled={consentPrompt && !consentPrompt[0]}
          className='btn btn-red ml-2 w-1/2'
          onClick={() => {
            onDelete();
            close();
          }}
        >
          <FormattedMessage id='dialog.delete' />
        </button>
      </div>
    </Card>
  );
};

export default Confirmation;
