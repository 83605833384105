import classNames from "classnames";
import { addDays, format, isSameMonth } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import {
  celciusToFahrenheit,
  fahrenheitToCelcius,
  FormattedDatetime,
  FormattedMeasurement,
} from "../localization";
import AlertsContext from "../state/AlertsContext";
import { DeviceContext } from "../state/DeviceContext";
import DeviceGroupContext from "../state/DeviceGroupContext";
import NotificationsContext from "../state/NotificationsContext";
import { UserContext } from "../state/UserContext";
import Card from "./Card";
import DashContent from "./DashContent";
import DashHeader, { IconLink, TitleContainer } from "./DashHeader";
import { DetailsContext, DetailsView } from "./DetailsView";
import Form from "./Form";
import {
  ActionsEditor,
  ConditionsEditor,
  Input,
  LabeledWidget,
  PlainSelect,
  SingleGroupSelect,
  ToggleSwitch,
  FormLabel
} from "./FormWidgets";
import { SectionHeading } from "./Headings";
import { IconBell, IconBellOff, IconEdit, IconLeft } from "./Icons";
import ResponsiveSwitchView from "./ResponsiveSwitchView";
import Table from "./Table";
import UnreadBadge from "./UnreadBadge";
import { useIntl } from "react-intl";
import { scoutsHaveDevice, groupsHaveDevice, } from "../utility/device-helpers";

const RuleForm = ({ rule, site_id, onSave, ...rest }) => {
  const intl = useIntl();
  const {
    crud: { list: fetchNotifications },
  } = useContext(NotificationsContext);
  const { currentUser } = useContext(UserContext);
  const temp_unit = currentUser.pref_unit_temp;
  const defaultValues = useMemo(
    () =>
      rule
        ? {
            ...rule,
            conditions: rule.conditions
              ? rule.conditions.map((cond) => ({
                  ...cond,
                  ...(cond.measurement_type === "MOISTURE"
                    ? {
                        low_limit: cond.low_limit * 100,
                        high_limit: cond.high_limit * 100,
                      }
                    : {}),
                  ...(cond.measurement_type === "TEMPERATURE" &&
                  temp_unit === "fahrenheit"
                    ? {
                        low_limit: celciusToFahrenheit(cond.low_limit).toFixed(
                          0
                        ),
                        high_limit: celciusToFahrenheit(
                          cond.high_limit
                        ).toFixed(0),
                      }
                    : {}),
                    ...(cond.measurement_type === "OXYGEN"
                    ? {
                        low_limit: cond.low_limit * 100,
                        high_limit: cond.high_limit * 100,
                      }
                    : {}),
                }))
              : [],
          }
        : { enabled: true },
    [rule, temp_unit]
  );
  return (
    <Form
      context={AlertsContext}
      defaultValues={defaultValues}
      readOnly={currentUser.read_only}
      onSave={(result) => {
        // These could have changed
        fetchNotifications();
        onSave && onSave(result);
      }}
      validateOnSubmit={true}
      transform={(formValues) => {
        const newValue = {
          ...rule,
          ...formValues,
          site: site_id,
          conditions: formValues.conditions
            ? formValues.conditions.map((cond) => ({
                ...cond,
                ...(cond.measurement_type === "MOISTURE"
                  ? {
                      low_limit: cond.low_limit * 0.01,
                      high_limit: cond.high_limit * 0.01,
                    }
                  : {}),
                ...(cond.measurement_type === "TEMPERATURE" &&
                temp_unit === "fahrenheit"
                  ? {
                      low_limit: fahrenheitToCelcius(cond.low_limit),
                      high_limit: fahrenheitToCelcius(cond.high_limit),
                    }
                  : {}),
                  ...(cond.measurement_type === "OXYGEN"
                  ? {
                      low_limit: cond.low_limit * 0.01,
                      high_limit: cond.high_limit * 0.01,
                    }
                  : {}),
              }))
            : [],
          actions: formValues.actions
            ? formValues.actions.filter(
                ({ email }, idx, arr) =>
                  // Filtering out empty strings
                  email !== "" &&
                  // and duplicates
                  arr.map(({ email }) => email).indexOf(email) === idx
              )
            : [],
        };
        return newValue;
      }}
      {...rest}
    >
      <LabeledWidget
        widget={<Input />}
        name="name"
        labelId="form.label.device_name"
        required
      />
      <LabeledWidget
        className="flex flex-row justify-end"
        name="enabled"
        labelId="form.label.enabled"
        widget={<ToggleSwitch />}
      />
      <SingleGroupSelect name="group_id" site_id={site_id} required />
      <ConditionsEditor name="conditions" required />
      <LabeledWidget
        className="flex flex-row justify-end"
        name="retrigger"
        labelId="form.label.retrigger"
        widget={
          <PlainSelect>
            <option value="false">
              {intl.formatMessage({ id: "alert_option.email_frequency_once" })}
            </option>
            <option value="true">
              {intl.formatMessage({ id: "alert_option.email_frequency_every" })}
            </option>
          </PlainSelect>
        }
      />

      <>
          <FormLabel
            htmlFor={`actions`}
            messageId='form.label.email_addresses'
            className='col-start-1'
          />
          {<ActionsEditor name="actions" visible="true" lassName='col-start-2'/>}
      </>
      
    </Form>
  );
};

const RuleDetails = ({ site_id, linkTo }) => {
  const intl = useIntl(); //translations inside returns
  const history = useHistory();
  const {
    crud: { list: fetchNotifications },
  } = useContext(NotificationsContext);
  return (
    <DetailsView
      param="rule_id"
      context={AlertsContext}
      getTitle={(creating) =>
        creating
          ? intl.formatMessage({ id: "alert.new_alert_rule" })
          : intl.formatMessage({ id: "alert.rule_properties" })
      }
      linkTo={linkTo}
      onDelete={fetchNotifications}
    >
      <DetailsContext.Consumer>
        {({ entity: rule, creating }) => (
          <RuleForm
            rule={rule}
            site_id={site_id}
            onSave={creating ? () => history.push(linkTo) : () => null}
          />
        )}
      </DetailsContext.Consumer>
    </DetailsView>
  );
};

const RulesList = ({ rules, editID, ...rest }) => {
  const intl = useIntl(); //translations inside returns
  const match = useRouteMatch("/sites/:site_id/alerts/:action/:rule_id");
  const { notifications } = useContext(NotificationsContext);

  const columns = useMemo(
    () => [
      {
        id: "icon",
        className: "pl-8",
        Header: null,
        Cell: (cell) =>
          cell.row.original.enabled ? (
            <IconBell
              title={
                cell.row.original.unresolved
                  ? intl.formatMessage({ id: "alert.ongoing_event" })
                  : null
              }
              className={classNames(
                {
                  "text-scout-blue": !cell.row.original.unresolved,
                },
                {
                  "text-scout-red": cell.row.original.unresolved,
                }
              )}
            />
          ) : (
            <IconBellOff className="text-scout-gray-dark" />
          ),
      },
      {
        Header: <FormattedMessage id="table.header.name" />,
        accessor: "name",
        className: "w-full",
        sortIndicator: "alpha",
        Cell: (cell) => <p className="py-2">{cell.value}</p>,
      },
      {
        accessor: "unread",
        Header: <FormattedMessage id="table.header.unread" />,
        sortType: "basic",
        sortIndicator: "numeric",
        Cell: (cell) =>
          cell.value ? (
            <UnreadBadge className="mx-auto" count={cell.value} />
          ) : null,
      },
    ], // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data = useMemo(
    () =>
      rules?.map((rule) => ({
        ...rule,
        unresolved: notifications?.some(
          ({ rule_id, resolved }) => rule_id === rule.id && !resolved
        ),
        unread: notifications?.filter(
          ({ rule_id, acknowledged }) => rule_id === rule.id && !acknowledged
        ).length,
      })),
    [rules, notifications]
  );

  return (
    <Table
      columns={columns}
      data={data || []}
      sortBy={useMemo(() => [{ id: "name", desc: false }], [])}
      highlightIdx={data?.findIndex(
        ({ id }) => id === Number(match?.params?.rule_id)
      )}
      {...rest}
    />
  );
};

export const AlertCard = ({ className, notification }) => {
  const intl = useIntl(); 
  const [problemMeasurements, setProblemMeasurements] = useState({});
  const [measurementsToShow, setMeasurementsToShow] = useState(["moisture", "temperature", "salinity", "water_balance"]);
  const { rule_id, measurements, start, resolved } = notification;
  const { acknowledgeNotification } = useContext(NotificationsContext);
  const { currentUser } = useContext(UserContext);
  const rule = useContext(AlertsContext).rules?.find(
    ({ id }) => id === rule_id
  );
  const group = useContext(DeviceGroupContext).groups?.find(
    ({ id }) => id === rule?.group_id
  );
  const { scouts, devices, getMeasurements } = useContext(DeviceContext);
  const { groups } = useContext(DeviceGroupContext);

  const ruleDescription = rule?.conditions.map((cond) => {
    const meas = cond.measurement_type.toLowerCase();
    const message = `${intl.formatMessage({ id: "conditions.legend." + meas })} 
    ${cond.inside_limits ? 
      intl.formatMessage({ id: "alert_card.rule.value_inside" }) : intl.formatMessage({ id: "alert_card.rule.value_outside" })
    }`;
    
    return (
      <p key={meas}>
        {message.slice(0, 1).toUpperCase() + message.slice(1)}{" "}
        <FormattedMeasurement variable={meas} value={cond.low_limit} /> {" — "}
        <FormattedMeasurement
          variable={meas}
          value={cond.high_limit}
          withUnit
        />
        {"."}
      </p>
    );
  });

  useEffect(() => {
    measurements.forEach((meas) => {
      getMeasurements(
        { id: meas.device_id },
        {
          since: meas.measurement_timestamp,
          until: meas.measurement_timestamp,
        }
      ).then((data) => {
        setProblemMeasurements((ms) => ({
          ...ms,
          [meas.device_id]: data[0],
        }));
      });
    });
  }, [measurements, getMeasurements]);

  useEffect(() => {
    if (groupsHaveDevice(groups, "209") || scoutsHaveDevice(scouts, "209")) {
      setMeasurementsToShow(["moisture", "temperature", "salinity", "water_balance", "oxygen"]);
    } else {
      setMeasurementsToShow(["moisture", "temperature", "salinity", "water_balance"]);
    }
  }, [groups, scouts]);

  const startDate = new Date(start);

  return rule ? (
    <Card
      className={classNames(className, "max-w-2xl pb-10")}
      title={rule.name}
      cornerWidget={
        <span className="text-gray-600">
          <FormattedDate value={start} /> <FormattedTime value={start} />
        </span>
      }
    >
      {ruleDescription}
      <Link
        className="inline-flex font-medium text-scout-blue mt-4 hover:underline"
        to={`/sites/${group.site}/scouts/view?groups=${group.id}&from=${format(
          startDate,
          "yyy-MM-dd",
          new Date()
        )}&to=${format(addDays(startDate, 1), "yyy-MM-dd", new Date())}`}
      >
        {group.name}
      </Link>
      <ul className="my-2">
        {measurements.map((meas) => {
          const device = devices.find(({ id }) => meas.device_id === id);

          if (!device) {
            console.error(`Undefined device ${meas.device_id} for site`);
            return null;
          }

          let measurementSlot = null;
          if (problemMeasurements[device.id]) {
            measurementSlot = (
              <div>
                {measurementsToShow.map((variable) => {
                  const condition = rule.conditions.find(
                    ({ measurement_type }) =>
                      measurement_type.toLowerCase() === variable
                  );
                  const value = problemMeasurements[device.id][variable];
                  const haveProblem = condition
                    ? value >= condition.low_limit &&
                      value <= condition.high_limit
                      ? !condition.inside_limits
                      : condition.inside_limits
                    : false;
                  return (
                    <span
                      style={{
                        textDecorationColor: "red",
                        textDecorationStyle: "dotted",
                        textDecorationThickness: "0.15rem",
                      }}
                      key={variable}
                      className={classNames("mx-1 ", {
                        underline: haveProblem,
                      })}
                    >
                      <FormattedMeasurement
                        variable={variable}
                        value={value}
                        withUnit
                      />
                    </span>
                  );
                })}
              </div>
            );
          }

          const meas_date = new Date(meas.measurement_timestamp);

          const params = new URLSearchParams();
          params.append("from", format(meas_date, "yyy-MM-dd", new Date()));
          params.append(
            "to",
            format(addDays(meas_date, 1), "yyy-MM-dd", new Date())
          );
          params.append("scouts", device.id);

          return (
            <li key={device.id} className="my-2">
              <Link
                className="flex justify-between hover:underline"
                to={{
                  pathname: `/sites/${group.site}/scouts/view`,
                  search: params.toString(),
                }}
              >
                {device.name}
                {measurementSlot}
                <span>
                  <FormattedDatetime value={meas.measurement_timestamp} />
                </span>
              </Link>
            </li>
          );
        })}
      </ul>

      <div className="mt-8 flex justify-between items-center">
        {!resolved ? (
          <p className="text-red-500">
            <FormattedMessage
              id="alert.active"
              values={{
                device_count: measurements.filter(
                  ({ valid_timestamp }) => valid_timestamp === null
                ).length,
              }}
            />
          </p>
        ) : (
          <p
            className={
              notification.acknowledged ? "text-gray-600" : "text-green-600"
            }
          >
            <FormattedMessage id="alert.resolved" />{" "}
            <FormattedDatetime value={resolved} />
          </p>
        )}
        {!notification.acknowledged && !currentUser.read_only && (
          <button
            className="btn btn-blue"
            onClick={() => acknowledgeNotification(notification)}
          >
            <FormattedMessage id="alerts.button.mark_as_read" />
          </button>
        )}
      </div>
    </Card>
  ) : null;
};

const AlertCards = ({ notifications }) => {
  return (
    <div className="w-full flex flex-wrap justify-center p-4">
      {notifications.map((notification) => (
        <AlertCard
          className="m-2"
          key={notification.id}
          notification={notification}
        />
      ))}
    </div>
  );
};

export default function AlertsView({ site }) {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { rules } = useContext(AlertsContext);
  const { notifications, unresolved, acknowledgeNotification } = useContext(
    NotificationsContext
  );
  const { currentUser } = useContext(UserContext);

  const sidebar = (
    <>
      <DashHeader shadow solid>
        <SectionHeading>
          <FormattedMessage id="views.alerts_view.heading" />
        </SectionHeading>
        {!currentUser.read_only && (
          <Link className="btn btn-green" to={`${url}/edit/`}>
            <FormattedMessage id="button.action.new_rule" />
          </Link>
        )}
      </DashHeader>
      <DashContent shadow solid>
        <RulesList
          rules={rules}
          editRow={(obj) => history.push(`${url}/edit/${obj.id}`)}
          linkTo={(obj) => `${url}/view/${obj.id}`}
        />
      </DashContent>
    </>
  );

  const initial = (
    <>
      <DashHeader>
        <SectionHeading>
          <FormattedMessage id="alerts.title.ongoing_events" />
        </SectionHeading>
      </DashHeader>
      <DashContent>
        {unresolved.length > 0 ? (
          <AlertCards notifications={unresolved} />
        ) : (
          <div className="w-full py-8 rounded text-scout-gray-dark flex flex-col items-center">
            <IconBell className="h-48 w-48 " />
            <p className="font-bold my-8">
              {rules?.length > 0
                ? "Everything seems to be in order!"
                : "Start by adding a rule!"}
            </p>
          </div>
        )}
      </DashContent>
    </>
  );

  return (
    <ResponsiveSwitchView sidebar={sidebar} initial={initial}>
      <Route path={[`${path}/edit/:rule_id/`, `${path}/edit/`]}>
        <RuleDetails site_id={site?.id} linkTo={url} />
      </Route>
      <Route path={`${path}/view/:rule_id`}>
        {({ match }) => {
          const currentRule = rules?.find(
            ({ id }) => Number(match.params?.rule_id) === id
          );
          const currentNotifs = notifications?.filter(
            (notification) => notification.rule_id === currentRule.id
          );

          const currentUnread = currentNotifs?.filter(
            ({ acknowledged }) => !acknowledged
          );

          return !rules ? null : currentRule ? (
            <>
              <Switch>
                <Route path={`${match.path}/history`}>
                  <DashHeader>
                    <TitleContainer>
                      <IconLink to={match.url} icon={<IconLeft />} />
                      <SectionHeading>
                        Notification history (last month)
                      </SectionHeading>
                    </TitleContainer>
                  </DashHeader>
                  <DashContent>
                    <AlertCards
                      notifications={currentNotifs.filter(
                        (notification) =>
                          notification.acknowledged &&
                          notification.resolved &&
                          isSameMonth(
                            new Date(notification.acknowledged),
                            new Date()
                          )
                      )}
                    />
                  </DashContent>
                </Route>
                <Route>
                  <DashHeader>
                    <TitleContainer>
                      <IconLink to={url} icon={<IconLeft />} />
                      <SectionHeading>{currentRule.name}</SectionHeading>
                      {!currentUser.read_only && (
                        <IconLink
                          to={`${url}/edit/${currentRule.id}`}
                          icon={<IconEdit />}
                        />
                      )}
                    </TitleContainer>
                    <div className="flex">
                      {!currentUser.read_only && (
                        <button
                          className="hidden sm:block btn bg-white shadow-indigo mr-2"
                          onClick={() => {
                            document.body.style.cursor = "wait";
                            Promise.all(
                              currentUnread.map(acknowledgeNotification)
                            ).finally(
                              () => (document.body.style.cursor = "default")
                            );
                          }}
                          disabled={currentUnread.length === 0}
                        >
                          <FormattedMessage id="alerts.button.mark_all_as_read" />
                        </button>
                      )}
                      <Link
                        to={`${match.url}/history`}
                        className="btn bg-white shadow-indigo"
                      >
                        <FormattedMessage id="alerts.button.notification_history" />
                      </Link>
                    </div>
                  </DashHeader>
                  <DashContent>
                    <AlertCards
                      notifications={currentNotifs.filter(
                        ({ resolved, acknowledged }) =>
                          !resolved || !acknowledged
                      )}
                    />
                  </DashContent>
                </Route>
              </Switch>
            </>
          ) : (
            <Redirect to={url} />
          );
        }}
      </Route>
    </ResponsiveSwitchView>
  ); //all text in this return should be formatted messages!
}
