import classNames from "classnames";
import React from "react";
import { FiLoader } from "react-icons/fi";

export default function Spinner({ className, size = 18 }) {
  return (
    <span
      className={classNames(
        className,
        "animate-spin-slow flex justify-center align-middle"
      )}
    >
      <FiLoader size={size} />
    </span>
  );
}
