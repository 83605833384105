import classNames from "classnames";
import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {
  useMountedLayoutEffect,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { UserContext } from "../state/UserContext";
import { Checkbox } from "./FormWidgets";
import {
  IconChevron,
  IconEdit,
  IconInfo,
  SortAlphaAsc,
  SortAlphaDesc,
  SortAmountAsc,
  SortAmountDesc,
  SortNumericAsc,
  SortNumericDesc,
} from "./Icons";
import Loader from "./Loader";
import Popup, { PopupSection } from "./Popup";

export const commonColumns = {
  name: {
    Header: <FormattedMessage id="table.header.name" />,
    accessor: "name",
    className: "w-full",
    sortIndicator: "alpha",
    Cell: (cell) => <p className="py-2">{cell.value}</p>,
  },
};

const sortIcon = (sortIndicator) =>
  sortIndicator === "alpha"
    ? [SortAlphaAsc, SortAlphaDesc]
    : sortIndicator === "numeric"
    ? [SortNumericAsc, SortNumericDesc]
    : [SortAmountAsc, SortAmountDesc];

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, children, selected, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          className={
            "group-hover:visible w-6 h-6 " + (selected ? "" : " invisible")
          }
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const HeaderPopup = ({ column, children }) => {
  const { isSorted, isSortedDesc, popupLeft, noChevron } = column;
  const [AscIcon, AscDesc] = sortIcon(column?.sortIndicator);
  return (
    <Popup
      className={"w-48 " + (popupLeft ? "right-0" : "left-0")}
      trigger={
        <button className="flex items-center font-bold">
          <>{column.render("Header")}</>
          {isSorted ? (
            isSortedDesc ? (
              <AscDesc className="ml-1" size={18} />
            ) : (
              <AscIcon className="ml-1" size={18} />
            )
          ) : noChevron ? null : (
            <IconChevron className="ml-1" size={18} />
          )}
        </button>
      }
    >
      {children}
    </Popup>
  );
};

const SortWidget = ({ column, children }) => {
  const { clearSortBy, toggleSortBy } = column.controlled || column;
  const { isSorted, isSortedDesc } = column;
  const [AscIcon, AscDesc] = sortIcon(column?.sortIndicator);
  return (
    <HeaderPopup column={column}>
      <PopupSection titleId="table.action.sort">
        <button
          className={
            "px-4 flex justify-between" +
            (isSorted && !isSortedDesc ? " text-scout-blue font-medium" : "")
          }
          onClick={() => {
            if (isSorted && !isSortedDesc) {
              clearSortBy();
            } else {
              toggleSortBy(false);
            }
          }}
        >
          <FormattedMessage id="table.sort.ascending" />
          <AscIcon className="mr-1" size={18} />
        </button>
        <button
          className={
            "px-4 flex justify-between" +
            (isSorted && isSortedDesc ? " text-scout-blue font-medium" : "")
          }
          onClick={() => {
            if (isSorted && isSortedDesc) {
              clearSortBy();
            } else {
              toggleSortBy(true);
            }
          }}
        >
          <FormattedMessage id="table.sort.descending" />
          <AscDesc className="mr-1" size={18} />
        </button>
      </PopupSection>
      {children}
    </HeaderPopup>
  );
};

export default function Table({
  columns,
  data,
  controlled,
  sortBy,
  onSortChange,
  rowSelect,
  pickRows,
  editRow,
  hideEdit = true,
  selectedRowIds,
  linkTo,
  setSelectedRow,
  highlightIdx,
  doSelectRow,
  loading,
  noEditSelect,
  noRowHeaders,
  toggleRow,
}) {
  const { currentUser } = useContext(UserContext);
  let args = [
    {
      columns: useMemo(
        () =>
          controlled
            ? columns.map((col) => ({
                ...col,
                controlled: {
                  toggleSortBy: (value) =>
                    controlled.toggleSortBy(col.id, value),
                  clearSortBy: controlled.clearSortBy,
                },
              }))
            : columns,
        [columns, controlled]
      ),
      autoResetSortBy: false,
      data,
      ...(controlled
        ? {
            useControlledState: (state) => ({
              ...state,
              selectedRowIds,
              sortBy,
            }),
          }
        : {
            ...(sortBy ? { initialState: { sortBy, selectedRowIds } } : {}),
          }),
    },
  ];

  if (sortBy) {
    args.push(useSortBy);
  }

  if (pickRows || rowSelect) {
    if (!noEditSelect) {
      args.push(useRowSelect);
      args.push((hooks) => {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            noHeaderPopup: true,
            Cell: ({ row }) => (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                selected={row.isSelected}
                {...(controlled
                  ? {
                      onChange: (evt) =>
                        controlled.toggleRowSelected(
                          row.original,
                          evt.target.checked
                        ),
                    }
                  : {})}
              />
            ),
          },
          ...columns,
        ]);
      });
    }
  }

  if (editRow) {
    args.push((hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "edit",
          Header: null,
          Cell: ({ cell }) => (
            <button
              onClick={(evt) => {
                evt.stopPropagation();
                editRow(cell.row.original);
              }}
              className={classNames(
                "text-gray-400 hover:text-scout-gray-dark",
                { "invisible group-hover:visible duration-300": hideEdit }
              )}
            >
              {currentUser.read_only ? <IconInfo /> : <IconEdit />}
            </button>
          ),
        },
      ]);
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { sortBy: sortedBy },
  } = useTable(...args);

  useMountedLayoutEffect(() => {
    pickRows && pickRows(selectedFlatRows.map((row) => row.original));
  }, [selectedFlatRows]);

  useMountedLayoutEffect(() => {
    onSortChange && onSortChange(sortedBy);
  }, [sortedBy]);

  return data.length < 1 && loading ? (
    <Loader />
  ) : (
    <>
      <table className="table-auto w-full" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="border-b border-scout-gray"
              {...headerGroup.getHeaderGroupProps()}
            >
              {!noRowHeaders &&
                headerGroup.headers.map((column) => (
                  <th
                    align="left"
                    className={
                      "first:pl-4 sm:first:pl-2 last:pr-4 select-none font-bold h-12 pl-2" +
                      (column.className ? column.className : "")
                    }
                    {...column.getHeaderProps()}
                  >
                    <div className={"flex items-center justify-start"}>
                      {column.Header ? (
                        sortBy && !column.noHeaderPopup ? (
                          column.canSort ? (
                            <SortWidget column={column}>
                              {column.headerMenu}
                            </SortWidget>
                          ) : (
                            <HeaderPopup column={column}>
                              {column.headerMenu}
                            </HeaderPopup>
                          )
                        ) : (
                          column.render("Header")
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const Wrapper = linkTo
              ? ({ children }) => (
                  <Link
                    className="block h-full flex items-center outline-none "
                    to={linkTo(row.original)}
                  >
                    {children}
                  </Link>
                )
              : ({ children }) => <>{children}</>;
            return (
              <tr
                className={classNames("group border-b border-scout-gray", {
                  "bg-scout-gray-light": row.index === highlightIdx,
                })}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  const cellProps = {
                    className:
                      "first:pl-4 sm:first:pl-2 px-2 last:pr-4 h-12" +
                      (cell.column.className
                        ? " " + cell.column.className
                        : ""),
                  };
                  return cell.column.id === "selection" ? (
                    <td
                      {...cell.getCellProps()}
                      className={classNames(
                        cellProps.className,
                        "group-hover:bg-gray-100",
                        {
                          "inline-flex items-center border-r-2 border-scout-blue":
                            row.isSelected,
                        }
                      )}
                    >
                      {cell.render("Cell")}
                    </td>
                  ) : (
                    <td
                      className={classNames(
                        cellProps.className,
                        "group-hover:bg-gray-100",
                        { "cursor-pointer": pickRows || rowSelect },
                        {
                          "bg-scout-gray-light": row.isSelected,
                        }
                      )}
                      onClick={
                        pickRows || rowSelect || toggleRow
                          ? () => {
                              if (controlled) {
                                controlled.toggleRowSelected(
                                  row.original,
                                  !row.isSelected,
                                  true,
                                  row.index
                                );
                              } else {
                                toggleAllRowsSelected(false);
                                row.toggleRowSelected(true);
                              }
                            }
                          : null
                      }
                      {...cell.getCellProps()}
                    >
                      {cell.column.id !== "edit" || cell.column.noLink ? (
                        <Wrapper>{cell.render("Cell")}</Wrapper>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length < 1 && (
        <p className="mx-auto text-center p-8 w-4/5 mt-8 bg-gray-200 rounded-md text-gray-700 font-medium">
          <FormattedMessage id="table.notice.no_data" />
        </p>
      )}
    </>
  );
}
