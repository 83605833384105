import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { IconCheck } from "./Icons";
import { BsMoisture } from "react-icons/bs";

const ToggleButton = (sensor, initialState, callback) => {
  const [toggled, setToggled] = useState(initialState);
  const button = useMemo(
    () => (
      <button
        onClick={() => {
          setToggled((toggled) => {
            callback && callback(sensor, !toggled);
            return !toggled;
          });
        }}
        className={classNames("flex flex-row w-full px-2", {
          "text-scout-blue font-medium focus:shadow-none focus:bg-scout-gray-light hover:bg-scout-gray-light": toggled,
        })}
      >
        {toggled ? (
          <IconCheck className="self-center text-scout-blue" />
        ) : (
          <BsMoisture className="self-center text-scout-blue" size={20} />
        )}
        <p
          className="w-full p-2 text-left overflow-hidden truncate"
          id={`${sensor.name}`}
        >
          {sensor.name}
        </p>
      </button>
    ),
    [toggled, sensor, callback]
  );
  return button;
};

function CreateSensorElement(sensor, isSelected, onSelect) {
  return ToggleButton(sensor, isSelected, onSelect);
}

export default function RainSensorList({ selectedSensors, sensors, onSelect }) {
  const rainSensorList = [];
  for (var key in sensors) {
    const sensor = sensors[key];
    const isSelected = selectedSensors.includes(sensor);
    rainSensorList.push(CreateSensorElement(sensor, isSelected, onSelect));
  }

  return <div>{rainSensorList}</div>;
}
