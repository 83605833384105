import React, { createContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Switch } from "react-router-dom";
import config from "../tailwindConfig";

export const MediaContext = createContext();

export default function ResponsiveSwitchView({ sidebar, initial, children }) {
  const haveLarge = useMediaQuery({
    query: `(min-width: ${config.theme.screens.lg})`,
  });

  return (
    <MediaContext.Provider value={{ large: haveLarge }}>
      {haveLarge ? (
        <>
          {sidebar}
          <Switch>
            {children}
            <Route>{initial}</Route>
          </Switch>
        </>
      ) : (
        <>
          <Switch>
            {children}
            <Route>{sidebar}</Route>
          </Switch>
        </>
      )}
    </MediaContext.Provider>
  );
}
