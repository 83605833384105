import { useState } from "react";

export default function useLocalStorage(key, defaultValue) {
  const [data, setData] = useState(() => {
    const stored = window.localStorage.getItem(key);
    return stored ? JSON.parse(stored) : defaultValue;
  });

  if (data) {
    const stored = window.localStorage.getItem(key);
    if (stored !== data) {
      window.localStorage.setItem(key, JSON.stringify(data));
    }
  } else {
    window.localStorage.removeItem(key);
  }

  return [data, setData];
}
