const chroma = require("chroma-js");

const red = chroma("#DB0000");
const green = chroma("#00BC8A");
const blue = chroma("#365DF5");
const yellow = chroma("#FEBD4E");
const orange = chroma("#eb7610");

module.exports = {
  red,
  green,
  blue,
  yellow,
  orange
};
