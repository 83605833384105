import classNames from "classnames";
import React, { cloneElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormattedDatetime } from "../localization";
import { IconAlertTriangle, IconCheck, IconHourglass } from "./Icons";

const ConnectionStatus = ({
  className,
  device: { device_status, last_seen },
  withText
}) => {
  const intl = useIntl();
  return(
    <FormattedMessage id={`connection_status.${device_status}`}>
      {(device_status_translation) => (
        <FormattedDatetime value={last_seen}>
          {(last_seen_translation) => {
            const iconConf = {
              NOT_CONNECTED: [<IconAlertTriangle />, "text-scout-red-light"],
              WAITING: [<IconHourglass />, "text-scout-yellow"],
              OK: [<IconCheck />, "text-scout-green-light"],
            };

            const [icon, textColor] = iconConf[device_status];

            return withText ? (
              <div
                className={classNames(
                  className,
                  "inline-flex items-center",
                  textColor
                )}
              >
                {icon}
                <span className="ml-2">{device_status_translation}</span>
              </div>
            ) : (
              cloneElement(icon, {
                className: classNames(className, textColor),
                title: `${device_status_translation}\n${intl.formatMessage({ id: "device.connection_status.last_update" })} ${
                  last_seen ? last_seen_translation : intl.formatMessage({ id: "device.connection_status.never" })
                }`,
              })
            );
          }}
        </FormattedDatetime>
      )}
    </FormattedMessage>
  );
};

export default ConnectionStatus;
